import 'assets/styles/compliance.scss';

import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useStore } from 'hooks';
import * as config from "../../config/constants";
import {patchData} from "../../services/api-utils";
import * as api from "../../config/endpoints";
import UserOwner from "../../shared/DialogBox/userOwner";

const Businesses = () => {
  const [businessData, setBusinessData] = useState();
  const [businessesNext, setBusinessesNext] = useState('');
  const [businessesCount, setBusinessesCount] = useState(0);
  const [businessesDisplayCount, setBusinessesDisplayCount] = useState(0);
  const [businessesUnderControlCount, setBusinessesUnderControlCount] = useState({});
  const [businessesLoading, setBusinessesLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const store = useStore();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const numRecordPage = config.BusinessPageSize;
  const [open, setOpen] = useState(false);
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [newOwner, setNewOwner] = useState('');
  const [owners, setOwners] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchOwners = async () => {
      const { data } = await store.api.getUsers();
      setOwners(data.results);
    };

    fetchOwners();
  }, [store.api]);

  const handleClickOpen = async (business) => {
    debugger;
    setSelectedBusiness(business);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedBusiness(null);
  };

  const handleOwnerChange = (event) => {
    setNewOwner(event.target.value);
  };

  const handleSave = async () => {
    setLoading(true);
    const patchRuleEndpoint = `${api.BUSINESSES_OWNER}${selectedBusiness.id}/`;
    const data  = { owner: newOwner}
    await patchData(patchRuleEndpoint, data);
    handleClose();
    getBusinesses();
    setLoading(false);
  };

  const getBusinessPercentage = async (activeBusinessId) => {
    try {
      const { data } = await store.api.getBusinessPercentage(activeBusinessId);
      return data;
    } catch (error) {
      if (error.response && error?.response?.status === 403) {
        enqueueSnackbar('You do not have permission to view this data', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('An error occurred, please contact support', {
          variant: 'error',
        });
      }
    }
  };

  const getBusinesses = async () => {
    try {
      setIsLoading(true);
      const { data } = await store.api.getBusinesses(numRecordPage);
      setBusinessData(data.results);
      setBusinessesNext(data.next);
      setBusinessesCount(data.count);
      data.count > numRecordPage ? setBusinessesDisplayCount(numRecordPage) : setBusinessesDisplayCount(data.count);
      var dict ={};
      const promises = data.results.map(async (companyBusiness, i) => {
         dict[companyBusiness.id] =  await getBusinessPercentage(companyBusiness.id);
      });
      await Promise.all(promises);
      setBusinessesUnderControlCount(dict);
    } catch (error) {
      if (error.response && error?.response?.status === 403) {
        enqueueSnackbar('You do not have permission to view this data', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('An error occurred, please contact support', {
          variant: 'error',
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getNextBusinesses = async () => {
    try {
      setBusinessesLoading(true);
      const { data } = await store.api.getNextDataWithURL(businessesNext);
      let addNewDomains = businessData;
      setBusinessesNext(data.next);
      setBusinessData(undefined);
      const arrayLength = data.results.length;
      data.results.map((process, i) => {
        addNewDomains.push(process);
        if (arrayLength == i + 1) {
          setBusinessData(addNewDomains);

          setBusinessesDisplayCount(businessesDisplayCount + arrayLength);
        }
      });
      var dict = businessesUnderControlCount;
      const promises = data.results.map(async (business, i) => {
         dict[business.id] =  await getBusinessPercentage(business.id);
      });
      await Promise.all(promises);
      setBusinessesUnderControlCount(dict);
      setBusinessesLoading(false);
    } catch (error) {
      setBusinessesLoading(false);
      if (error.response && error?.response?.status === 403) {
        enqueueSnackbar('You do not have permission to view this data', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('An error occurred, please contact support', {
          variant: 'error',
        });
      }
    } finally {
      setBusinessesLoading(false);
    }
  };

  useEffect(() => {
    getBusinesses();
  }, []);

  const navigateToBusiness = (businessId) => {
    const path = '/business?business=' + businessId;
    history.push(path);
  };

  return (
    <section className={'compliance-container'}>
      <div className="back-nav" onClick={() => history.push('/companies-dashboard')}>
        Home
      </div>
      <div className="header-icon"></div>
      <h4>Businesses</h4>

      <ul className="categories-list">
        <li key="count" style={{ backgroundColor: '#f4f5f7' }}>
          <span>
            Displaying {businessesDisplayCount} of {businessesCount} Businesses
          </span>
        </li>

        {businessData !== undefined &&
          businessData.map((companyBusiness, i) => {
            const businessId = companyBusiness.id;
            const percentage = businessesUnderControlCount[companyBusiness.id] ? businessesUnderControlCount[companyBusiness.id].percentage : 0;
            const total = businessesUnderControlCount[companyBusiness.id] ? businessesUnderControlCount[companyBusiness.id].total: 0;
            const under_control = businessesUnderControlCount[companyBusiness.id] ? businessesUnderControlCount[companyBusiness.id].under_control: 0;
            const evidence_passed = businessesUnderControlCount[companyBusiness.id] ? businessesUnderControlCount[companyBusiness.id].evidence_passed: 0;
            const zIndexgreen = evidence_passed > under_control ? 2 : 1;
            const zIndexlight = evidence_passed > under_control ? 1 : 2 ;
            const percentageEvidence = (Math.round(evidence_passed * 100) / total) * 1.10;
            return (
              <li key={i} onClick={() => navigateToBusiness(businessId)}>
                <span>{businessId}</span>
                <span className="title-spacing">{companyBusiness.business.name}</span>
                <div className="status-text">
                  <span
                    className="owner"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent triggering navigateToBusiness
                      handleClickOpen(companyBusiness);
                    }}>
                  Owner: {companyBusiness.owner.first_name} {companyBusiness.owner.last_name}
                </span>
                </div>

                <div className="applicable-percentage">   {under_control}/{evidence_passed}/{total}   </div>
                <div className="status red-percentage">
                   <div
                  className="green"
                  style={{ width: `${percentage}%`, zIndex:`${zIndexgreen}`}}>
                  </div>
                  <div
                  className="lightgreen"
                  style={{ width: `${percentageEvidence}%`, zIndex:`${zIndexlight}` }}>
                  </div>

                </div>
              </li>
            );
          })}
         {selectedBusiness &&
             <UserOwner owners={owners} newOwner={newOwner} handleOwnerChange={handleOwnerChange} handleSave={handleSave} loading={loading}/>
          // <Dialog open={open} onClose={handleClose}>
          //   <DialogTitle>Change Owner</DialogTitle>
          //   <DialogContent>
          //     <Select value={newOwner} onChange={handleOwnerChange} displayEmpty fullWidth>
          //       <MenuItem value="" disabled>Select new owner</MenuItem>
          //       {owners.map((owner) => (
          //         <MenuItem key={owner.username} value={owner.username}>
          //           {owner.first_name} {owner.last_name}
          //         </MenuItem>
          //       ))}
          //     </Select>
          //   </DialogContent>
          //   <DialogActions>
          //     <Button onClick={handleClose} color="primary">Cancel</Button>
          //     <Button onClick={handleSave} color="primary">Save</Button>
          //   </DialogActions>
          // </Dialog>
        }
        {businessesCount > businessesDisplayCount && (
          <li className="get-next" key="next" onClick={() => getNextBusinesses()}>
            <span>{businessesLoading ? 'Loading...' : 'Load '+ numRecordPage.toString() +' more'}</span>
          </li>
        )}
      </ul>
    </section>
  );
};

Businesses.defaultProps = {};

export default Businesses;
