import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import React, {useCallback, useEffect, useState} from 'react';

import DvrIcon from '@material-ui/icons/Dvr';
import TimelineIcon from '@material-ui/icons/Timeline';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import HomeIcon from '@material-ui/icons/Home';
import ReplyIcon from '@material-ui/icons/Reply';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
import CreateIcon from '@material-ui/icons/Create';
import PublicIcon from '@material-ui/icons/Public';
import LowPriorityIcon from '@material-ui/icons/LowPriority';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ViewListIcon from '@material-ui/icons/ViewList';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AlarmIcon from '@material-ui/icons/Alarm';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import CommentIcon from '@material-ui/icons/Comment';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';

import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useStore } from 'hooks';
import { useSnackbar } from 'notistack';
import { dashboardLinks } from  "../../../config/constants";


const frameworkMenuItems = [
        {
    label: 'Real-Time Board Report',
    icon: <TimelineIcon style={{ color: '#fff' }} />,
    path: '/dashboard-management',
  },
    {
    label: 'Dashboard',
    icon: <DvrIcon style={{ color: '#fff' }} />,
    path: '/dashboard',
  },
    {
    label: 'Horizon Scanning',
    icon: <PublicIcon style={{ color: '#fff' }} />,
    path: '/horizon-scanning',
  },
  {
    label: 'Impact Assessment',
    icon: <AssignmentReturnIcon style={{ color: '#fff' }} />,
    path: '/impact-assessment',
  },
  {
    label: 'Compliance Document Creation',
    icon: <CreateIcon style={{ color: '#fff' }} />,
    path: '/businesses',
  },
  {
    label: 'Regulation Management Dashboard',
    icon: <LowPriorityIcon style={{ color: '#fff' }} />,
    path: '/reg-change-management',
  },
  {
    label: 'Compliance Task/Issue Management Dashboard',
    icon: <ReportProblemIcon style={{ color: '#fff' }} />,
    path: '/issue-management',
  },
  {
    label: 'Compliance Monitoring Programme',
    icon: <ListAltIcon style={{ color: '#fff' }} />,
    path: '/user-micro-regulatory-list',
  },
  {
    label: 'Real-time Ops Controls Framework',
    icon: <ViewListIcon style={{ color: '#fff' }} />,
    path: '/regulation-documents-operational',
  },
  {
    label: 'Real-time Reg Compliance Framework',
    icon: <FormatListNumberedIcon style={{ color: '#fff' }} />,
    path: '/regulation-documents-regulation',
  },
  {
    label: 'Real-time Reg Compliance Report',
    icon: <FormatListNumberedIcon style={{ color: '#fff' }} />,
    path: '/report-documents-regulation',
  },
  {
    label: 'Advising the Business',
    icon: <SpeakerNotesIcon style={{ color: '#fff' }} />,
    path: '/businesses',
  },
  {
    label: 'Training',
    icon: <PeopleAltIcon style={{ color: '#fff' }} />,
    path: null,
  },
  {
    label: 'Compliance Reporting',
    icon: <AssessmentIcon style={{ color: '#fff' }} />,
    path: null,
  },
  {
    label: 'Regulatory Reporting',
    icon: <AlarmIcon style={{ color: '#fff' }} />,
    path: null,
  },
  {
    label: 'Audit',
    icon: <FindInPageIcon style={{ color: '#fff' }} />,
    path: null,
  },
  {
    label: 'Communication with the Regulator',
    icon: <CommentIcon style={{ color: '#fff' }} />,
    path: '',
  },
  {
    label: 'Micro Process Library',
    icon: <LibraryBooksIcon style={{ color: '#fff' }} />,
    path: '/evident-micro-regulatory-list',
  },

];

function Menu() {
  const history = useHistory();
  const [menuList, setMenuList] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const store = useStore();
  const classes = useStyles();
  const [frameworkSubNavOpen, setFrameworkSubNavOpen] = React.useState(true);

  const redirectTo = useCallback(
    (path,label, menu) => () => {
      const domain = localStorage.getItem('domain');
      const menuPath = path ? path : menu[domain][label];
      history.push(menuPath);

    },
    [],
  );
  const getCompanyMenuList = async () => {
    try {
      const { data } = await store.api.getMenus();
      const menuNames = data.results.map(item => item.name);
      setMenuList(menuNames);
    } catch (error) {
      if (error.response && error?.response?.status === 403) {
        enqueueSnackbar('You do not have permission to view this data', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('An error occurred, please contact support', {
          variant: 'error',
        });
      }
    }
  };

  useEffect(() => {
    getCompanyMenuList();
  }, []);

  const handleLogout = useCallback(() => {
    store.user.logout();
  }, []);

  const handleFrameworkSubNavClick = () => {
    // setFrameworkSubNavOpen(!frameworkSubNavOpen);
    setFrameworkSubNavOpen(true);
  };

  return (
    <>
      <List component="nav">
        <ListItem button onClick={redirectTo('/companies-dashboard')}>
          <ListItemIcon>
            <HomeIcon style={{ color: '#fff' }} />
          </ListItemIcon>
          <ListItemText primary="Control Centre" />
        </ListItem>
      </List>

      <Divider className={classes.divider} />

      <List component="nav">
        <Collapse in={frameworkSubNavOpen} timeout="auto" unmountOnExit>
          <List>
            {menuList !== undefined && frameworkMenuItems.map((item, index) => {
              if (menuList.includes(item.label)) {
                return (
                    <ListItem key={item.label} button
                              onClick={redirectTo(item.path, item.label, dashboardLinks)}>
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText primary={String(index+1).padStart(2, '0') + ' ' + item.label}/>
                    </ListItem>)
              }
            })}
            <ListItem button onClick={handleLogout}>
              <ListItemIcon>
                <ReplyIcon style={{ color: '#fff' }} />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </Collapse>
      </List>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  divider: {
    backgroundColor: '#ffffff',
  },
}));

export default Menu;
