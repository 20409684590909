import 'assets/styles/compliance.scss';

import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useStore } from 'hooks';
import * as config from "../../../config/constants";
import * as api from "../../../config/endpoints";
import {patchData} from "../../../services/api-utils";
import UserOwner from "../../../shared/DialogBox/userOwner";

const BusinessDetail = () => {
  const [activeBusinessId, setActiveBusinessId] = useState('');
  const [businessData, setBusinessData] = useState();
  const [businessFunctionNext, setBusinessFunctionNext] = useState('');
  const [businessFunctionData, setBusinessFunctionData] = useState();
  const [businessFunctionCount, setBusinessFunctionCount] = useState(0);
  const [businessFunctionDisplayCount, setBusinessFunctionDisplayCount] = useState(0);
  const [businessesUnderControlCount, setBusinessesUnderControlCount] = useState({});
  const [businessFunctionsLoading, setBusinessFunctionsLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const store = useStore();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const numRecordPage = config.BusinessPageSize;
  const [open, setOpen] = useState(false);
  const [selectedBusinessFunction, setSelectedBusinessFunction] = useState(null);
  const [newOwner, setNewOwner] = useState('');
  const [owners, setOwners] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchOwners = async () => {
      const { data } = await store.api.getUsers();
      setOwners(data.results);
    };

    fetchOwners();
  }, [store.api]);

  const handleClickOpen = async (businessFunction) => {
    debugger;
    setSelectedBusinessFunction(businessFunction);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedBusinessFunction(null);
  };

  const handleOwnerChange = (event) => {
    setNewOwner(event.target.value);
  };

  const handleSave = async () => {
    setLoading(true);
    const patchRuleEndpoint = `${api.BUSINESS_FUNCTIONS_OWNER}${selectedBusinessFunction.id}/`;
    const data  = { owner: newOwner}
    await patchData(patchRuleEndpoint, data);
    handleClose();
    const params = window.location.href;
    var url = new URL(params);
    var activeBusinessId = url.searchParams.get('business');
    setActiveBusinessId(activeBusinessId);
    getBusinessFunctionList(activeBusinessId);
    setLoading(false);
  };

  const getBusinessDetail = async (activeBusinessId) => {
    try {
      setIsLoading(true);
      const { data } = await store.api.getBusiness(activeBusinessId);
      setBusinessData(data);
    } catch (error) {
      if (error.response && error?.response?.status === 403) {
        enqueueSnackbar('You do not have permission to view this data', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('An error occurred, please contact support', {
          variant: 'error',
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getBusinessFunctionPercentage = async (activeBusinessId) => {
    try {
      const {data} = await store.api.getBusinessFunctionPercentage(activeBusinessId);
      return data;
    } catch (error) {
      if (error.response && error?.response?.status === 403) {
        enqueueSnackbar('You do not have permission to view this data', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('An error occurred, please contact support', {
          variant: 'error',
        });
      }
    }
  }

  const getBusinessFunctionList = async (activeBusinessId) => {
    try {
      setIsLoading(true);
      const { data } = await store.api.getBusinessFunctionsForBusiness(activeBusinessId, config.BusinessPageSize);
      setBusinessFunctionData(data.results);
      setBusinessFunctionNext(data.next);
      setBusinessFunctionCount(data.count);
      data.count > numRecordPage
        ? setBusinessFunctionDisplayCount(numRecordPage)
        : setBusinessFunctionDisplayCount(data.count);
      var dict ={};
      const promises = data.results.map(async (companyBusinessFunction, i) => {
         dict[companyBusinessFunction.id] =  await getBusinessFunctionPercentage(companyBusinessFunction.id);
      });
      await Promise.all(promises);
      setBusinessesUnderControlCount(dict);
    } catch (error) {
      if (error.response && error?.response?.status === 403) {
        enqueueSnackbar('You do not have permission to view this data', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('An error occurred, please contact support', {
          variant: 'error',
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getNextBusinessFunctions = async () => {
    try {
      setBusinessFunctionsLoading(true);
      const { data } = await store.api.getNextDataWithURL(businessFunctionNext);
      let addNewDomains = businessFunctionData;
      setBusinessFunctionNext(data.next);
      setBusinessFunctionData(undefined);
      const arrayLength = data.results.length;
      data.results.map((process, i) => {
        addNewDomains.push(process);
        if (arrayLength == i + 1) {
          setBusinessFunctionData(addNewDomains);
          setBusinessFunctionDisplayCount(businessFunctionDisplayCount + arrayLength);
        }
      });

      var tmpObject =  businessesUnderControlCount;
      const promises = data.results.map(async (companyBusinessFunction, i) => {
         tmpObject[companyBusinessFunction.id] =  await getBusinessFunctionPercentage(companyBusinessFunction.id);
      });
      await Promise.all(promises);
      setBusinessesUnderControlCount(tmpObject);
      setBusinessFunctionsLoading(false);
    } catch (error) {
      setBusinessFunctionsLoading(false);
      console.log(error);
      if (error.response && error?.response?.status === 403) {
        enqueueSnackbar('You do not have permission to view this data', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('An error occurred, please contact support', {
          variant: 'error',
        });
      }
    } finally {
      setBusinessFunctionsLoading(false);
    }
  };

  useEffect(() => {
    const params = window.location.href;
    var url = new URL(params);
    var activeBusinessId = url.searchParams.get('business');
    setActiveBusinessId(activeBusinessId);
    getBusinessDetail(activeBusinessId);
    getBusinessFunctionList(activeBusinessId);
  }, []);

  const navigateToBusinessFunction = (activeBusinessId, companyBusinessFunctionId) => {
    const path =
      '/business-function' +
      '?business=' +
      activeBusinessId +
      '&business_function=' +
      companyBusinessFunctionId;
    history.push(path);
  };

  return (
    <section className={'compliance-container'}>
      <div className="back-nav" onClick={() => history.push('/businesses')}>
        Back to Businesses
      </div>
      <div className="header-icon"></div>
      {businessData === undefined && (
        <div className="loading-icon-container">
          <div className="loading-icon"></div>
        </div>
      )}
      <h4>Business - {businessData !== undefined && businessData.business.name}</h4>

      <ul className="categories-list">
        <li key="count" style={{ backgroundColor: '#f4f5f7' }}>
          <span>
            Displaying {businessFunctionDisplayCount} of {businessFunctionCount} Business Functions
          </span>
        </li>
        {businessFunctionData !== undefined &&
          businessFunctionData.map((companyBusinessFunction, i) => {
            const companyBusinessFunctionId = companyBusinessFunction.id;
            const percentage = businessesUnderControlCount[companyBusinessFunction.id] ? businessesUnderControlCount[companyBusinessFunction.id].percentage : 0;
            const total = businessesUnderControlCount[companyBusinessFunction.id] ? businessesUnderControlCount[companyBusinessFunction.id].total: 0;
            const under_control = businessesUnderControlCount[companyBusinessFunction.id] ? businessesUnderControlCount[companyBusinessFunction.id].under_control: 0;
            const evidence_passed = businessesUnderControlCount[companyBusinessFunction.id] ? businessesUnderControlCount[companyBusinessFunction.id].evidence_passed: 0;
            const zIndexgreen = evidence_passed > under_control ? 2 : 1;
            const zIndexlight = evidence_passed > under_control ? 1 : 2 ;
            const percentageEvidence = (Math.round(evidence_passed * 100) / total) * 1.10;

            return (
              <li
                key={i}
                onClick={() =>
                  navigateToBusinessFunction(activeBusinessId, companyBusinessFunctionId)
                }
              >
                <span>{companyBusinessFunctionId}</span>
                <span className="title-spacing">
                  {companyBusinessFunction.business_function.name}
                </span>
                <div className="status-text">
                  {/*<span className="last-updated">*/}
                  {/*  LAST UPDATED BY {companyBusinessFunction.last_updated_by.username} AT{' '}*/}
                  {/*  {companyBusinessFunction.updated_on}*/}
                  {/*</span>*/}
                  {/* <span className="owner">Owner: {companyBusinessFunction.owner.username}</span>*/}
                  <span
                    className="owner"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClickOpen(companyBusinessFunction);
                    }}>
                  Owner: {companyBusinessFunction.owner.first_name} {companyBusinessFunction.owner.last_name}
                </span>
                </div>
                {/*<span*/}
                {/*  className={`applicable-status ${*/}
                {/*    companyBusinessFunction.applicable === 'Applicable' ? '' : 'not-applicable'*/}
                {/*  }`}*/}
                {/*>*/}
                {/*  {companyBusinessFunction.applicable}*/}
                {/*</span>*/}
                <div className="applicable-percentage">   {under_control}/{evidence_passed}/{total}   </div>
                <div className="status red-percentage">
                  <div
                  className="green"
                  style={{ width: `${percentage}%`, zIndex:`${zIndexgreen}`}}>
                  </div>
                  <div
                  className="lightgreen"
                  style={{ width: `${percentageEvidence}%`, zIndex:`${zIndexlight}` }}>
                  </div>

                </div>
              </li>
            );
          })}
          {selectedBusinessFunction &&
            <UserOwner owners={owners} newOwner={newOwner} handleOwnerChange={handleOwnerChange} handleSave={handleSave} loading={loading}/>
        }
        {businessFunctionCount > businessFunctionDisplayCount && (
          <li className="get-next" key="next" onClick={() => getNextBusinessFunctions()}>
            <span>{businessFunctionsLoading ? 'Loading...' : 'Load '+ numRecordPage.toString() +' more'}</span>
          </li>
        )}
      </ul>
    </section>
  );
};

BusinessDetail.defaultProps = {};

export default BusinessDetail;
