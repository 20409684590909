import React, { useState, useEffect, useRef } from 'react';

import { useSnackbar } from 'notistack';
import { useStore } from 'hooks';
import {
  AreaChart,
  ComposedChart,
  Line,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip
} from "recharts";
import {useHistory} from "react-router-dom";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Grid from "@material-ui/core/Grid";
import {Button, Container} from "@material-ui/core";
import PdfViewerDialog from "../../shared/ProcessDetail/PdfViewerDialog";
const pdfCapital = require('../../assets/pdfs/Capital And Financial Management Report Summary.pdf');
const pdfExecutive = require('../../assets/pdfs/Executive Summary_221123.pdf');
const pdfFundOne = require('../../assets/pdfs/Fund One Board Meeting Minutes.pdf');
const pdfUrlIrishFund = require('../../assets/pdfs/Irish Fund Board Meeting Agenda.pdf');
const pdfUrlOrganization = require('../../assets/pdfs/Organisational Chart_Fund One_221123.pdf');
const pdfUrlRiskManagementReport = require('../../assets/pdfs/Risk Management Report_221123.pdf');
const pdfUrlRiskManagementSummary = require('../../assets/pdfs/Risk Management Report Summary.pdf');
const data = [
  {
    name: "1/1/2023",
    uv: 250000000,
    pv: (250000000*0.6),
    amt: 2400
  },
  {
    name: "3/5",
    uv: (250000000*0.5),
    pv: 250000000,
    amt: 2210
  },
  {
    name: "3/10",
    uv: 250000000,
    pv: (250000000*0.6),
    amt: 2290
  },
  {
    name: "3/15",
    uv: (250000000*0.4),
    pv: 250000000,
    amt: 2000
  },
  {
    name: "3/20",
    uv: 250000000,
    pv: (250000000*0.6),
    amt: 2181
  },
  {
    name: "3/25",
    uv: (250000000*0.6),
    pv: 250000000,
    amt: 2500
  },
  {
    name: "3/31/2023",
    uv: 261009465,
    pv: 250000000,
    amt: 2100
  }
];


const boadingSection = [
    {id: 1, name: 'boading' ,processName: 'OPS_AIFM_OPS_010_CREATION OF BOARD REPORT', dataElement: 'Agenda', startDataValue:'7/1/2023',  endDataValue:'9/30/2023', pdfFile: pdfUrlIrishFund},
    {id: 2, name: 'boading' ,processName: 'OPS_AIFM_OPS_011_EXECUTE BOARD MEETING', dataElement: 'Minutes', startDataValue:'7/1/2023',  endDataValue:'9/30/2023', pdfFile: pdfFundOne},
    {id: 3, name: 'boading' ,processName: 'OPS_AIFM_OPS_010_CREATION OF BOARD REPORT', dataElement: 'Org Summary', startDataValue:'7/1/2023',  endDataValue:'9/30/2023', pdfFile: pdfUrlOrganization},
    {id: 4, name: 'boading' ,processName: 'OPS_AIFM_OPS_010_CREATION OF BOARD REPORT', dataElement: 'Executive Summary', startDataValue:'7/1/2023',  endDataValue:'9/30/2023', pdfFile: pdfExecutive}
];

const investmentSection = [
  {id: 1, name: 'investment' ,processName: 'OPS_AIFM_OPS_105_VALUE INVESTMENT FUND', dataElement: 'NAV (EUR)', startDataValue:'251,988,012', endDataValue:'257,792,379', section:'Investment Fund Valuation', pdfFile: pdfUrlRiskManagementSummary},
  {id: 2, name: 'investment' ,processName: 'OPS_AIFM_OPS_106_VALUE INVESTMENTS END OF DAY', dataElement: 'Aphabet', startDataValue:'78,787,682', endDataValue:'79,287,682', section:'Investment Valuation', pdfFile: pdfUrlRiskManagementSummary},
  {id: 3, name: 'investment' ,processName: 'OPS_AIFM_OPS_106_VALUE INVESTMENTS END OF DAY', dataElement: 'Ryaniar', startDataValue:'23,981,792', endDataValue:'26,981,792', section:'Investment Valuation', pdfFile: pdfUrlRiskManagementSummary},
  {id: 4, name: 'investment' ,processName: 'OPS_AIFM_OPS_106_VALUE INVESTMENTS END OF DAY', dataElement: 'Ryaniar', startDataValue:'12,223,234',  endDataValue:'9/30/2023', section:'Investment Valuation', pdfFile: pdfUrlRiskManagementSummary},
  {id: 5, name: 'investment' ,processName: 'OPS_AIFM_OPS_104_VALUE FUND PERFORMANCE', dataElement: 'Eurekahedge Equity Long Bias Hedge Fund Index', startDataValue:'3.50%',  endDataValue:'3.90%', section:'Benchmark', pdfFile: pdfUrlRiskManagementSummary},
   {id: 6, name: 'investment' ,processName: 'OPS_AIFM_OPS_104_VALUE FUND PERFORMANCE', dataElement: 'Performance +/- Benchmark', startDataValue:'0.02%',  endDataValue:'-0.01%', section:'Benchmark', pdfFile: pdfUrlRiskManagementSummary},
  {id: 7, name: 'investment' ,processName: 'OPS_AIFM_OPS_054_EXECUTE FUND RISK  MANAGEMENT COMMITTEE REVIEW', dataElement: 'Executive Summary', startDataValue:'7/1/2023',  endDataValue:'9/30/2023', section:'Investment Management Summary', pdfFile: pdfUrlRiskManagementSummary},

];

const riskSection = [
    {id: 1, name: 'risk' ,processName: 'OPS_AIFM_OPS_056_MANAGE MARKET RISK', dataElement: 'Market Risk', startDataValue:'7/1/2023', endDataValue:'9/30/2023', section:'Fund Risk Management Summary', pdfFile:pdfUrlRiskManagementSummary},
    {id: 2, name: 'risk' ,processName: 'OPS_AIFM_OPS_054_EXECUTE FUND RISK  MANAGEMENT COMMITTEE REVIEW', dataElement: 'DP For Fund Risk Management', startDataValue:'7/1/2023', endDataValue:'9/30/2023', section:'Financial Statements', pdfFile:pdfUrlRiskManagementReport}
  ];

const capitalSection = [
    {id: 1, name: 'capital' ,processName: 'OPS_AIFM_OPS_028_CREATE FINANCIAL STATEMENTS', dataElement: 'Directors Report', startDataValue:'7/1/2023', endDataValue:'9/30/2023', section:'Financial Statements', pdfFile: pdfCapital},
    {id: 2, name: 'capital' ,processName: 'OPS_AIFM_OPS_025_CALCULATE TAX RETURN', dataElement: 'Revenue', startDataValue:'7/1/2023', endDataValue:'9/30/2023', section:'Tax', pdfFile: pdfCapital},
    {id: 3, name: 'capital' ,processName: 'OPS_AIFM_OPS_027_CREATE AND SUBMIT CAPITAL REPORTS', dataElement: 'Finanical Profile', startDataValue:'7/1/2023', endDataValue:'9/30/2023', section:'ICAAP', pdfFile: pdfCapital},
    {id: 4, name: 'capital' ,processName: 'OPS_AIFM_OPS_033_EXECUTE CAPITAL AND FINANCIAL MGT COMMITTEE REVIEW', dataElement: 'DP for Capital and Financial Managemet', startDataValue:'7/1/2023',  endDataValue:'9/30/2023', section:'Capital and Financial Management Report', pdfFile: pdfCapital},

];

const   DashboardManagement = (props) => {
  const store = useStore();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [selectedDateEnd, setSelectedDateEnd] = React.useState(new Date());
  const [open, setOpen] = useState(false);
  const [sectionName, setSectionName] = useState('');
  const pdfViewerDialogRef = useRef(null);

  const handlePdfClick = (name) => {
    setOpen(!open);
    setSectionName(name);
  };

  const handleDateChange = (date) => {
      setSelectedDate(date);
    };
  const handleDateChangeEnd = (date) => {
    setSelectedDateEnd(date);
  };
  const handleSearch = () => {
    // Your search logic here
    console.log('Search button clicked!');
};

  return (
    <section id={`home-container`}>
      <div className="back-nav" onClick={() => history.push('/companies-dashboard')}>
        Home
      </div>
      <div className="header-icon"></div>
      <h4>
       Real-Time Board Report
      </h4>
     <ul className="categories-list" style={{ backgroundColor: '#f4f5f7' }}>
          <div>
          <div>
          <div>&nbsp;</div>
          </div>
          </div>
          <p>&nbsp;</p>
     </ul>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container justifyContent="flex-start">
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            id="date-picker-inline"
            label="Start Date"
            value={selectedDate}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
          <div>&nbsp; &nbsp;</div>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            id="date-picker-inline"
            label="End Date"
            value={selectedDateEnd}
            onChange={handleDateChangeEnd}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
          <div>&nbsp; &nbsp;</div>
           <Button variant="contained" color="primary" onClick={handleSearch}>
            Search
          </Button>
        </Grid>
      </MuiPickersUtilsProvider>
      <div>
          <h3>Board</h3>
        <div style={{ backgroundColor: '#f4f5f7' }}>&nbsp;&nbsp;</div>
        <h5 style={{ 'fontSize': '18px' }}>Board Meeting</h5>
        {
          boadingSection.map((stoke, index) => {
           return (
                <div key={index} className="table-container">
                  <div className="card-holder-horizontal">

                    <div className="card-text">
                    <span>Process Name</span>
                    <span className="text"> {stoke.processName}</span>
                  </div>
                      <div className="card-data-element">
                      <span>Data Element</span>
                      <span className="text">{stoke.dataElement}</span>
                    </div>
                    <div className="card-number">
                      <span>Start Date Value</span>
                      <span className="text">{stoke.startDataValue}</span>
                    </div>
                      <div className="chart-right" style={{ position: 'relative' }}>
                         {stoke.pdfFile !== undefined   &&  (
                        <React.Fragment>
                          <object data={stoke.pdfFile} type='application/pdf' width='100%' height='100%'>
                            <p>PDF cannot be displayed</p>
                          </object>
                          <div
                            style={{
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              width: '100%',
                              height: '100%',
                              cursor: 'pointer',
                            }}
                            onClick={() =>handlePdfClick(stoke.pdfFile)}
                          />
                        </React.Fragment>
                      )}
                      {open === true &&  stoke.pdfFile === sectionName &&(
                        <PdfViewerDialog pdfUrl={stoke.pdfFile} closePopup={() => props.closePopup()} closeDialog={() =>handlePdfClick()}/>
                      )}
                     </div>
                     <div className="card-number">
                      <span>End Date Value</span>
                      <span className="text">{stoke.endDataValue}</span>
                    </div>
                  </div>
              </div>
            );
          })}

            <h3>Investment Management</h3>
        <div style={{ backgroundColor: '#f4f5f7' }}>&nbsp;&nbsp;</div>
          {investmentSection.map((stoke, index) => {
           return (
                <div key={index} className="table-container">
                    <h5 style={{ 'font-size': '18px' }}>{stoke.section}</h5>
                  <div className="card-holder-horizontal">

                    <div className="card-text">
                    <span>Process Name</span>
                    <span className="text"> {stoke.processName}</span>
                  </div>
                      <div className="card-data-element">
                      <span>Data Element</span>
                      <span className="text">{stoke.dataElement}</span>
                    </div>
                    <div className="card-number">
                      <span>Start Date Value</span>
                      <span className="text">{stoke.startDataValue}</span>
                    </div>
                   {stoke.id !==7 && <div className="chart-right">

                    <AreaChart
                      width={500}
                      height={120}
                      data={data}
                      margin={{
                        top: 10,
                        right: 40,
                        left: 20,
                        bottom: 0
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" fill="#f0f0f0" />
                      <Tooltip />
                      <Area type="monotone" dataKey="uv" stroke="#f80202" fill="#f80202" />
                    </AreaChart>
                     </div>}{stoke.id ===7 && <div className="chart-right">
                        <ComposedChart width={500} height={125} data={data}
                          margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                          <defs>
                            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1" margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                              <stop offset="5%" stopColor="#f70000" stopOpacity={0.8}/>
                              <stop offset="95%" stopColor="#f70000" stopOpacity={0}/>
                            </linearGradient>
                            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1" margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                              <stop offset="5%" stopColor="#c5c5c5" stopOpacity={0.8}/>
                              <stop offset="95%" stopColor="##8b8989" stopOpacity={0}/>
                            </linearGradient>
                          </defs>
                            <CartesianGrid strokeDasharray="3 3"  margin={{ top: 0, right: 0, left: 0, bottom: 0 }}/>
                          <Tooltip />
                          <Area type="monotone" dataKey="uv" stroke="#8b8989" fillOpacity={1} fill="url(#colorUv)" margin={{ top: 0, right: 0, left: 0, bottom: 0 }}/>
                          <Area type="monotone" dataKey="pv" stroke="#c5c5c5" fillOpacity={1} fill="url(#colorPv)" margin={{ top: 0, right: 0, left: 0, bottom: 0 }}/>
                          <Line type="monotone" dataKey="uv" stroke="#8b8989" margin={{ top: 0, right: 0, left: 0, bottom: 0 }}/>
                          <Line type="monotone" dataKey="pv" stroke="#c5c5c5" margin={{ top: 0, right: 0, left: 0, bottom: 0 }}/>
                        </ComposedChart>
                      </div>}
                     <div className="card-number">
                      <span>End Date Value</span>
                      <span className="text">{stoke.endDataValue}</span>
                    </div>
                  </div>
              </div>
            );
          })}
        <div>&nbsp; &nbsp;</div>
        <h3>Fund Risk Management</h3>
           <div style={{ backgroundColor: '#f4f5f7' }}>&nbsp;&nbsp;</div>
        {
          riskSection.map((stoke, index) => {
           return (
                <div key={index} className="table-container">
                  <h5 style={{ 'font-size': '18px' }}>{stoke.section}</h5>
                  <div className="card-holder-horizontal">

                    <div className="card-text">
                    <span>Process Name</span>
                    <span className="text"> {stoke.processName}</span>
                  </div>
                      <div className="card-data-element">
                      <span>Data Element</span>
                      <span className="text">{stoke.dataElement}</span>
                    </div>
                    <div className="card-number">
                      <span>Start Date Value</span>
                      <span className="text">{stoke.startDataValue}</span>
                    </div>
                      <div className="chart-right" style={{ position: 'relative' }}>
                         {stoke.pdfFile !== undefined  && (
                        <React.Fragment>
                          <object data={stoke.pdfFile} type='application/pdf' width='100%' height='100%'>
                            <p>PDF cannot be displayed</p>
                          </object>
                          <div
                            style={{
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              width: '100%',
                              height: '100%',
                              cursor: 'pointer',
                            }}
                            onClick={() =>handlePdfClick(stoke.pdfFile)}
                          />
                        </React.Fragment>
                      )}
                      {open === true &&  stoke.pdfFile === sectionName &&(
                        <PdfViewerDialog pdfUrl={stoke.pdfFile} closePopup={() => props.closePopup()} closeDialog={() =>handlePdfClick()}/>
                      )}
                     </div>
                     <div className="card-number">
                      <span>End Date Value</span>
                      <span className="text">250.002.321</span>
                    </div>
                  </div>
              </div>
            );
          })}

       <div>&nbsp; &nbsp;</div>
        <h3>Capital and Financial Management</h3>
           <div style={{ backgroundColor: '#f4f5f7' }}>&nbsp;&nbsp;</div>
        {
          capitalSection.map((stoke, index) => {
           return (
                <div key={index} className="table-container">
                  <h5 style={{ 'font-size': '18px' }}>{stoke.section}</h5>
                  <div className="card-holder-horizontal">

                    <div className="card-text">
                    <span>Process Name</span>
                    <span className="text"> {stoke.name}</span>
                  </div>
                      <div className="card-data-element">
                      <span>Data Element</span>
                      <span className="text">{stoke.dataElement}</span>
                    </div>
                    <div className="card-number">
                      <span>Start Date Value</span>
                      <span className="text">{stoke.startDataValue}</span>
                    </div>
                      <div className="chart-right" style={{ position: 'relative' }}>
                         {stoke.pdfFile !== undefined  && (
                        <React.Fragment>
                          <object data={stoke.pdfFile} type='application/pdf' width='100%' height='100%'>
                            <p>PDF cannot be displayed</p>
                          </object>
                          <div
                            style={{
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              width: '100%',
                              height: '100%',
                              cursor: 'pointer',
                            }}
                            onClick={() =>handlePdfClick(stoke.pdfFile)}
                          />
                        </React.Fragment>
                      )}
                      {open === true &&  stoke.pdfFile === sectionName &&(
                        <PdfViewerDialog pdfUrl={stoke.pdfFile} closePopup={() => props.closePopup()} closeDialog={() =>handlePdfClick()}/>
                      )}
                     </div>
                     <div className="card-number">
                      <span>End Date Value</span>
                      <span className="text">250.002.321</span>
                    </div>
                  </div>
              </div>
            );
          })}

      </div>
    </section>
  );
};

export default DashboardManagement;
