import 'assets/styles/category-container.scss';

import React, { useEffect, useState } from 'react';

import Moment from 'moment';
import MonitoringTable from './MonitoringTable';
import SidePanelCardItem from 'shared/SidePanelCardItem';
import StatusCard from 'shared/StatusCard';
import DialogLogs from "../../../shared/DialogBox/dialogLogs";

const Monitoring = (props) => {
  const [monitoringProcesses, setMonitoringProcesses] = useState([]);

  useEffect(() => {
    setMonitoringProcesses(props.sectionData.monitoring_rules);
  }, [props.microRegulatoryProcessData, props.sectionData]);

  return (
    <div className="category-layout-standard monitoring-container">
      <div className="document-body-container">
        <div className="document-side-panel row-alignment-setting">
          <SidePanelCardItem>
            <span>Monitoring Validation Status</span>
            <StatusCard sectionData={props.sectionData} hideMetaData={true} />
          </SidePanelCardItem>
          <SidePanelCardItem canScroll={true}>
           <span>Compliance with the ESMA Compliance Guidelines 2020 (ESMA35-36-1946)</span>
            <ul>
              <li>
                <span>
                  <b>The document below details how this Compliance Document set and associated Compliance Monitoring Framework addresses every Rule of the ESMA Compliance Guidelines 2020</b>
                  <br />
                  <br />
                  <ol>
					<li>
					"General guideline 1 - Compliance risk assessment
					-  18.    In  accordance  with  article  22(2),  second  paragraph,   of  the  MiFID  II   Delegated Regulation, the compliance function shall, amongst others, conduct a risk assessment to  ensure  that  compliance  risks  are  comprehensively  monitored. The  compliance function  shall  establish  a  risk-based  monitoring  programme  on  the  basis  of  this compliance risk assessment to determine its priorities and the focus of the monitoring, advisory and assistance activities.   - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) All Regulatory Micro-processes have a Risk Assessment Document"
					</li>
					<li>
					General guideline 1 - Compliance risk assessment
  -  19.    The  findings  of  the  compliance  risk  assessment  should  be  used  to  set  the  work programme of the compliance function and to allocate the functions resources efficiently. The compliance risk assessment should be updated to ensure that the objectives, focus and the scope of compliance monitoring and advisory activities remain valid. - (HOW MIURA ADDRESSES GUIDELINE) The Scheduled Review of Micro-processes are reviewed on a schedule driven from the Risk Assessment
					</li>
                     <li>
General guideline 1 - Compliance risk assessment
  -  21.    The  compliance  risk  assessment  should  take  into  account  the  applicable  obligations under MiFID II, national implementing regulation and the policies, procedures, systems and controls implemented within the firm in the area of investment services and activities. The assessment should also take into account the results of any monitoring activities and of any relevant internal or external audit findings. - (HOW MIURA ADDRESSES GUIDELINE) Each Miura Compliance Framework addresses ALL applicable Regulatory Rules for each Applicable Regulation
					</li>
					<li>
General guideline 1 - Compliance risk assessment
  -  22.    The identified risks should be reviewed on a regular basis and when necessary also on an  ad-hoc-basis  to  ensure  that  any  emerging  risks  are  taken  into  consideration  (for example,  resulting from new business  fields,  other  changes  in the firm’s  structure or
relevant changes in the regulatory framework). - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) Each Regulatory Micro-process is reviewed on a regular basis, driven by the Risk Assessment
					</li>
                     <li>
General guideline 2 - Monitoring obligations of the compliance function  -  23.    The aim of the risk based monitoring programme should be to evaluate whether the firm’s business  is  conducted  in  compliance  with  its  obligations  under  MiFID  II,  its  related delegated  acts  and/or  any  national  implementing  provisions  thereof  and  whether  its internal guidelines, organisation and control measures remain effective and appropriate. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) Each Miura Compliance Framework addresses ALL applicable Regulatory Rules for each Applicable Regulation
					</li>
					<li>
General guideline 2 - Monitoring obligations of the compliance function  -  24.    Where a firm is part of a group, responsibility for the compliance function rests with each firm in that group. A firm should therefore ensure that its compliance function remains responsible for monitoring its own compliance risk. This includes where a firm outsources compliance tasks to another firm within the group. The compliance function within each firm should, however, take into account the group of which it is a part - for example, by working closely with audit, legal, regulatory and compliance staff in other parts of the
group. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) Each Miura Compliance Framework has an Owner and is configured at a Legal Entity Level, so Groups would require multiple instances.
					</li>
					<li>
General guideline 2 - Monitoring obligations of the compliance function  -  25.    The  risk-based  approach  to  compliance  should  form  the  basis  for  determining  the appropriate tools and methodologies used by the compliance function, as well as the extent of the monitoring programme and the frequency of monitoring activities performed by the compliance function (which may be recurring,  ad-hoc and/or continuous). The compliance function should also ensure that its monitoring activities are not only desk- based, but that it also verifies how policies and procedures are implemented in practice, for example through on-site inspections at the operative business units. The compliance function should also consider the scope of reviews to be performed. - (HOW MIURA ADDRESSES GUIDELINE) Policies and Procedures are reviewed and signed off and evidence which has been scanned and validated in evidence that the policies and procedures are being adhered to. Screen recording procedure testing is schedule to be introduced.
					</li>
					<li>
General guideline 2 - Monitoring obligations of the compliance function  -  26.    Suitable tools and methodologies for monitoring activities are, inter alia, the mandatory compliance reports according to articles 22(2)(c), 22(3)(c), and 25(2) and (3) of the MiFID II Delegated Regulation which should be used to warrant the necessary management attention. Additional tools that could be used by the compliance function include (but are not limited to):
(a)     the use of aggregated risk measurements (for example, risk indicators);
(b)     the  use  of  (additional)  reports  warranting  management  attention  documenting material  deviations  between  actual  occurrences  and  expectations  (exceptions report) or situations requiring resolution (issues log);
(c)     targeted  trade  surveillance,  observation  of  procedures,  desk  reviews  and/or interviewing relevant staff as well as the firm’s clients - (HOW MIURA ADDRESSES GUIDELINE) Miura's Dashboards provide real-time view of whether a Regulatory Micro-process does not address it's requirements, escalating to management in real-time that there is an issue which needs to be resolved, and recording the reason why is the logs.
					</li>
					<li>
General guideline 2 - Monitoring obligations of the compliance function  -  27.    The monitoring programme should reflect changes to the firm’s risk profile, which may arise,  for  example, from  significant  events  such as  corporate acquisitions,  IT  system changes,   or   reorganisation.   It   should   also   extend   to   the   implementation   and effectiveness of any remedial measures taken by the firm in response to breaches of MiFID II, related delegated acts and/or any national implementing provisions thereof. - (HOW MIURA ADDRESSES GUIDELINE) Any change to the Firms Risk Profile can be reflected in a Rule-wide Risk Assessment Upgrade (eg +1 across all Risk Ratings), meaning Regulatory Micro-processes will have to be reviewed more often.
					</li>
					<li>
General guideline 2 - Monitoring obligations of the compliance function  -  28.    Monitoring activities performed by the compliance function should also take into account:
(a)     the business area’s obligation to comply with regulatory requirements;
(b)     the first level of controls in the firm’s business areas (i.e. controls by the operative units, as opposed to second level controls performed by compliance); and
(c)     reviews by the risk management function, internal audit function or other control functions in the area of investment services and activities.
 - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) (a) Each business area has regulatory obligations linked
(b) 1st and 2nd lines of defence have their own sign-off Dashboard
(c) 2nd and 3rd lines of defence have their own sign-off Dashboard

					</li>
					<li>
General guideline 2 - Monitoring obligations of the compliance function  -  29.    Reviews  by  control  functions  should  be  coordinated  with  the  monitoring  activities performed   by   the   compliance   function   while   respecting   the   different   functions’ independence and mandate. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) (b) 1st ,2nd & 3rd lines of defence have their own sign-off Dashboard
					</li>
					<li>
General guideline 2 - Monitoring obligations of the compliance function  -  30.    The compliance function should have a role in overseeing the operation of the complaints process  and it  should consider  complaints as a source of  relevant  information  in the context  of  its  general  monitoring  responsibilities.  This  does  not  require  compliance functions to have a role in determining the outcome of complaints. In this regard, firms should grant the compliance function access to all customer complaints received by the firm. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) Complaints are logged and tracked under the Relevant set of Compliance Micro-Processes
					</li>
					<li>
General guideline 3  - Reporting obligations of the compliance function  -  31.    The written compliance report to senior management should cover  all business  units involved in the provision of investment services, activities and ancillary services. Where the report does not cover  all of these activities of the firm, it should clearly state the
reasons. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) The Miura Real-time Compliance Dashboard covers Business and Regulatory Processes within the Org
					</li>
					<li>
General guideline 3  - Reporting obligations of the compliance function  -  32.    In addition to the topics listed in Article 22(2) of the MiFID II Delegated Regulation, the following  matters  should  be  addressed  in  these  written  compliance  reports,  where relevant:
(a)     General information
        information  on  the  adequacy  and  effectiveness  of  the  firm’s  policies  and procedures designed to ensure that the firm  and its staff  comply with the obligations under Directive 2014/65/EU or rather its European and national implementing acts;
        relevant  changes  and  developments  in  regulatory  requirements  over  the period covered by the report;
        a  summary  of  the  compliance  function’s  structure,  including  the  overall personnel employed, their qualifications and reporting lines;
(b)     Manner of monitoring and reviewing
        how the  compliance-function monitors the development and review of the obligations under Directive 2014/65/EU or rather its European and national implementing acts and how possible risks of failure by the firm or its staff to comply with these obligations are discovered at an early stage.
        a summary of on-site inspections or desk-based reviews performed by the compliance function
        a summary of the planned monitoring activities for the subsequent review;
(c)     Findings
        a  summary  of  major  findings  of  the  review  of  the  policies  and  procedure including risks identified in the scope of the compliance function’s monitoring activities;
        breaches and deficiencies in the firm’s organisation and compliance process;
        the number of complaints received in the period under review. Where, as a result of the review of clients’ complaints, specific compliance or risk issues are  identified  in  relation  to  the  procedures  adopted  by  the  firm  for  the provision  of  investment  services,  these  aspects  should  be  specifically reported;
(d)     Actions taken (including related timeline and organisational units involved)
        any action taken to address any risk of failure by the firm or its staff to comply with the obligations under Directive 2014/65/EU or rather its European and national implementing acts;
        measures  taken  and  to  be  taken  to  ensure  compliance  with  changed regulatory requirements;
        reaction  to  complaints  received  and  any  payout  performed  based  on  the complaint. Actions regarding specific compliance or risk issues identified in relation to the procedures adopted by the firm for the provision of investment services as a result of the reiew of clients’ complaints;
(e)     Others
        other significant compliance issues that have occurred since the last report;
        overview of material correspondence with competent authorities; and
        information as regards any deviation by senior management from important recommendations or assessments issued by the compliance function.
 - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) Miura's Real-time Dashboard Addresses the following points in the manner below:
(a) General information
> Policies and Procedures documents to address reach regulatory Micro-process are signed-off and scanned on a daily basis to ensure their continure to address the Sub-Rule and this is monitored on the Dashboard
> All new Applicbale Regulatory Rules can be tracked on the Horizon Scanning and Impact Assessment screens
> The Compliance Functions Structure can be tracked in the user group configuration
(b) Manner of Monitoring and Reviewing
> All new Applicable Regulatory Rules can be tracked on the Horizon Scanning and Impact Assessment screens
> Any on-site inspections can be recorded in the Notes Fields of the Compliance, Audit or Regulator Dashboards
> Planned Monitoring can be reviewed in the Business Process Screen using the Calendar view
(c) Findings
> Major findings from Policy and Procedure review can be found in the scanning logs where an error has been identified.
> Breaches and deficiences in the firms compliance framework can be found in the scanning errors and error logs
> The number of Complaints can be found in the Evidence Report of the Complaints Handling Micro-process
(d) Actions Taken
> Actions taken to address any Micro-process that do not pass scanning, will be logged in the Notes Field and the Audit Trail of each Micro-Process
> All new Applicable Regulatory Rules can be tracked on the Horizon Scanning and Impact Assessment screens
> Complaints are logged and tracked under the Relevant set of Compliance Micro-Processes
(e) Others
> All
					</li>
					<li>
General guideline 3  - Reporting obligations of the compliance function  -  33.    In the section of the report covering the firm’s product governance arrangements, the compliance function should also address, where relevant to the situation of the firm (e.g. taking  into  account  its  role  as  product  manufacturer  and/or  distributor)  and  without limitation:
(a)     the compliance function’s role in the elaboration, monitoring and review of the firm’s product governance requirements;
(b)     all  topics  required  under  Art.  22(2)  Delegated  Regulation  2017/565,  as  stated above  under  paragraph  32,  regarding  the  monitoring  of  the  firm’s  product governance by the compliance-function (for  example, the compliance function’s findings  relating  to  the  firm’s  product  governance  policies  and  procedures, breaches and deficiencies, actions taken or to be taken to remedy the latter).
(c)     systematically,         information         about         the         financial         instruments manufactured/distributed  by  the  firm,  including  information  on  the  distribution strategy  according  to  Art.  9  (6)  and  Art.  10  (8)  of  the  Delegated  Directive  EU 2017/593, namely at least:
        the  number  and  nature  of  the  products  manufactured  or  distributed  (as applicable) including their respective target markets and other information from  the  respective  product  approval  process  necessary  to  assess  the product’s  compliance-risk  (e.g.  complexity  of  the  product,  product  related conflicts of interests, particularly relevant data from the scenario analysis, the cost-return ratio, etc.)
        (in  case  of  manufacturers)  as  part  of  the  information  on  the  respective distribution strategy: the respective distributors;
        whether the products are distributed outside their (positive) target market and to which extent,
with the aim to assess whether the firm’s product governance arrangements function as intended. To do so, the compliance function may take a critical look at any work, reports or  methods  from  the  firm’s  function  or  personnel  working  on  product  governance arrangements.  The  compliance  report  is  subject  to  the  proportionality  principle  in accordance with  Article 22(1)  of the MiFID  II  Delegated  Regulation. Therefore,  when
reporting, for example, on the firm’s product governance arrangements, the information for   simpler,   more   common   products   may   be   less   in-depth,   whereas   products characterised  by  complexity/risk  features  or  by  other  relevant  features  (such  as,  for
example, illiquidity and innovation) should be described in more detail. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) Product Oversight is addresses in the Product oversight Micro-processes
					</li>
					<li>
General guideline 3  - Reporting obligations of the compliance function  -  34.    Where the firm’s compliance function also acts as its complaints management function, the compliance report should address any issue arising out of the implementation of the arrangements the firm has in place to assess, minimise and manage any conflicts of interest between the two functions, including notably, any failure identified as regards the firm’s compliance with its complaints handling obligations. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) Complaints Handling Process Review is handled under the Complaints Handling Review Micro-process (Typically as part of the Board Meeting)
					</li>
					<li>
General guideline 3  - Reporting obligations of the compliance function  -  35.    The compliance function should consider the need for additional reporting lines to any group compliance function. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) Access to the Miura Real-time Compliance Dashboard can be given group Compliance for Real-time Oversight
					</li>
					<li>
General guideline 3  - Reporting obligations of the compliance function  -  36.    ESMA  notes  that  some  competent  authorities  require  firms  to  provide  them  with compliance function reports on a regular or ad hoc basis. One competent authority also requires  senior  management  to  provide  it  with  an  annotated  version  of  the  report containing explanations of the compliance function’s findings. These practices provide competent authorities with first-hand insight into a firm’s compliance activities, as well as any breaches of regulatory provisions. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) Access to the Miura Real-time Compliance Dashboard can be given National Competent Authorities for Real-time Oversight & Review
					</li>
					<li>
General guideline 4 - Advisory and assistance obligations of the compliance function  -  37.    Firms  should  ensure  that  the  compliance  function  fulfils  its  advisory  responsibilities including: providing support for staff training; providing day-to-day assistance for staff and participating in the establishment of policies and procedures within the firm (e.g. the firm’s remuneration policy or the firm’s product governance policies and procedures). - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) Compliance are involved in tailoring all the Policies and Procedures in conjunction with the business (providing advice on each Sub-Rule) and Signing Off Each Policy and Procedure (and other Documentation)
					</li>
					<li>
General guideline 4 - Advisory and assistance obligations of the compliance function  -  38.    Firms should promote and enhance a ‘compliance culture’ throughout the firm, which should be supported by the senior management. The purpose of the compliance culture is not only to establish the overall environment in which compliance matters are treated,
but also to engage staff with the principle of improving investor protection. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) A Compliance Culture is established in the organisation through using the Miura Framework as each Regulatory and Business Micro-process is identified and tracked with compliance in mind (eg Full set of policies & procedures, Evidence Uploaded, Real-time monitoring)
					</li>
					<li>
General guideline 4 - Advisory and assistance obligations of the compliance function  -  39.    The  firm  needs  to  ensure  that  its  staff  is  adequately  trained  (see  Guidelines  for  the assessment of knowledge and competence; ESMA71-1154262120-153 EN (rev)). The compliance function should support the business units in the area of investment services and activities (i.e. all staff involved directly or indirectly in the provision of investment services  and  activities)  in  performing  any training.  Training  and other  support should focus particularly, but not exclusively, on:
(a)     the internal policies and procedures of the firm and its organisational structure in the area of investment services and activities; and
(b)     MiFID II, the relevant national laws, the applicable standards and guidelines set out  by  ESMA  and  competent  authorities,  and  other  supervisory  and  regulatory requirements that may be relevant, as well as any changes to these. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) The Miura Compliance Framework supports the assessment of knowledge and competence through these specific micro-processes. The Miura Framework supports the business by having a detailed policy and procedure of how to execute each Regulatory Micro-process
					</li>
                     <li>
General guideline 4 - Advisory and assistance obligations of the compliance function  -  40.    Training should be performed on a regular basis, and needs-based training should be performed where necessary. Training should be delivered as appropriate – for example, to the firm’s entire staff as a whole, to specific business units, or to a particular individual. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) Training for each micro-process is supported by ensuring each process owner reviews the policies and procedures on an annual basis (and supported by Compliance).
					</li>
					 <li>
General guideline 4 - Advisory and assistance obligations of the compliance function  -  41.    Training  should  be  developed  on  an  on-going  basis  so  that  it  takes  into  account  all relevant changes (for example, new legislation, standards or guidelines issued by ESMA and competent authorities, and changes in the firm’s business model). - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) Training for each micro-process is supported by ensuring each process owner reviews the policies and procedures on an annual basis (and supported by Compliance).
					</li>
					<li>
					 General guideline 4 - Advisory and assistance obligations of the compliance function  -  42.    The  compliance  function  should  periodically  assess  whether  staff  in  the  area  of investment services and activities hold the necessary level of awareness and correctly apply the firm’s policies and procedures. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) Assessment for each micro-process is supported by ensuring each process owner reviews the policies and procedures on an annual basis (and supported by Compliance).
					</li>
					<li>
General guideline 4 - Advisory and assistance obligations of the compliance function  -  43.    Compliance staff should also provide assistance to staff from the operative units in their day-to-day business and be available to answer questions arising out of daily business activity. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) Each micro-process is supported by ensuring each process owner reviews the policies and procedures on an annual basis (and supported by Compliance).
					</li>
					<li>
					 General guideline 4 - Advisory and assistance obligations of the compliance function  -  44.    Firms should ensure that the compliance function is involved in the development of the relevant  policies  and  procedures  within  the  firm  in  the  area  of  investment  services, activities  and  ancillary  services.  In  this  context,  the  compliance  function  should  be enabled,  for  example,  to  provide  compliance  expertise  and  advice  to  business  units about  all  strategic  decisions  or  new  business  models,  or  about  the  launch  of  a  new advertising strategy in the area of investment services and activities. If the compliance function’s  advice  is  not  followed,  the  compliance  function  should  document  this
accordingly and present it in its compliance reports. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) Policies and Procedures for each Business and Regulatory micro-process are created in conjunction with compliance and the business (they can tailor the generic documents with firm specific information. This is cemented by Compliance signing off on the policies and procedures in conjunction with the business and each process owner reviews the policies and procedures on an annual basis (and supported by Compliance).
					</li>
					<li>
					General guideline 4 - Advisory and assistance obligations of the compliance function  -  45.    Firms   should   ensure   that   the   compliance   function   is   involved   in   all   significant modifications of the organisation of the firm in the area of investment services, activities and ancillary services. This includes the decision-making process when new business lines  or  new  financial  products  are  being  approved  as  well  as  the  definition  of  staff remuneration policies. In this context, the compliance function should be given the right to  participate  in  the  product  approval  process  for  manufacturers  and  distributors  as applicable. Senior management should therefore encourage business units to consult with the compliance function regarding their operations. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) Compliance are involved in tailoring all the Policies and Procedures in conjunction with the business (providing advice on each Sub-Rule) and Signing Off Each Policy and Procedure (and other Documentation)
					</li>
					<li>
General guideline 4 - Advisory and assistance obligations of the compliance function  -  46.    Firms should ensure that the compliance function is involved in all material non-routine correspondence  with  competent  authorities  in  the  area  of  investment  services  and activities. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) Compliance as system owners will receive escalation of any issue raised by the scanners as a result of a Rule not been signed off by the Regulator through the Regulatory Review Dashboard
					</li>
					<li>
General guideline 5 - Effectiveness of the compliance function  -  47.    When  ensuring  that  appropriate  human  and  other  resources  are  allocated  to  the compliance function, firms should take into account the scale and types of investment services, activities and ancillary services undertaken by the firm. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) Miura's Compliance Framework should be supported by a compliance team driven by the number of Business and Regulatory Obligations (if using scanning) and also taking into account the size of the business if not.
					</li>
					<li>
General guideline 5 - Effectiveness of the compliance function  -  48.    The number of staff required for the tasks of the compliance function depends to a large extent on the nature of the investment services, activities and ancillary services and other services provided by the firm. Where a firm’s business  unit activities are significantly extended, the firm should ensure that the compliance function is similarly extended as necessary in view of changes to the firm’s compliance risk. Senior management should monitor regularly whether the number of staff and their expertise is still adequate for the fulfilment of the duties of the compliance function. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) Miura's Compliance Framework should be supported by a compliance team driven by the number of Business and Regulatory Obligations (if using scanning) and also taking into account the size of the business if not.
					</li>
					<li>
General guideline 5 - Effectiveness of the compliance function  -  49.    In  addition  to  human  resources,  sufficient  IT  resources  should  be  allocated  to  the
compliance function. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) Miura's Compliance Framework should be supported by a compliance team, with IT staff driven by the number of Business and Regulatory Obligations (if using scanning) and also taking into account the size of the business if not.
					</li>
					<li>
General guideline 5 - Effectiveness of the compliance function  -  50.    Where  the  firm  establishes  budgets  for  specific  functions  or  units,  the  compliance function should be allocated a budget that is consistent with the level of compliance risk the firm is exposed to. The compliance officer should be consulted before the budget is determined.  All  decisions for  significant  cuts  in the  budget  should be  documented in writing and contain detailed explanations. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) Miura's Compliance Framework should be supported by a compliance budget driven by the number of Business and Regulatory Obligations (if using scanning) and also taking into account the size of the business if not.
					</li>
					<li>
General guideline 5 - Effectiveness of the compliance function  -  51.    In ensuring compliance staff have access to the relevant information for their tasks at all times,  firms  should  provide  access  to  all  relevant  databases  and  records  (such  as recordings of telephone conversations and electronic communications referred to in Art. 76 of MiFID II Delegated Regulation). In order to have a permanent overview of the areas of the firm where sensitive or relevant information might arise, the compliance officer should have access to all relevant information systems within the firm as well as any internal  or  external  audit  reports  or  other  reporting  to  senior  management  or  the supervisory function, if any. Where relevant, the compliance officer should also be able to attend meetings of senior management or the supervisory function. Where this right is  not  granted,  this  should be  documented and explained  in  writing.  The compliance officer should have in-depth knowledge of the firm’s organisation, corporate culture and decision-making processes in order to be able to identify which meetings are important
to attend. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) Miura's Compliance Framework should be supported by a compliance team with access to all the system required by the number of Business and Regulatory Obligations in order to extract the appropriate evidence documents from them
					</li>
					<li>
General guideline 5 - Effectiveness of the compliance function  -  52.    In particular, it is important that the firm puts in place necessary arrangements to ensure an effective exchange of information between the compliance function and other control functions  (e.g.  internal  audit  and  risk  management)  as  well  as  with  any  internal  or external auditors. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) 1st Line, 2nd Line, 3rd Line and 4th Line control functions will have access to all control information to faciliate them executing their Role
					</li>
					<li>
General guideline 6 - Skills, knowledge, expertise and authority of the compliance function  -  53.    Firm’s  compliance  staff  shall  have  the  necessary  skills,  knowledge,  expertise  and authority to discharge their obligations. This requirement should in particular be taken into  account  by  firms  when  appointing  the  compliance  officer.  Having  regard  to  the function and tasks assigned to the compliance officer, he or she should demonstrate high professional ethical standards and personal integrity. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) Policies and Procedures for each Business and Regulatory micro-process are created in conjunction with compliance and the business (they can tailor the generic documents with firm specific information. This is cemented by Compliance signing off on the policies and procedures in conjunction with the business and each process owner reviews the policies and procedures on an annual basis (and supported by Compliance).
					</li>
					<li>
General guideline 6 - Skills, knowledge, expertise and authority of the compliance function  -  54.    In order to ensure that compliance staff have the authority required for their duties, the senior  management  of  the  firm  should  support  them  in  the  exercise  of  these  duties. Authority implies possessing adequate expertise and relevant personal skills (such as, for instance, judgment), and may be enhanced by the firm’s compliance policy explicitly acknowledging the specific authority of the compliance staff. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) Miura's Policy Documents detail how the Miura Framework works and detail the authority of the Compliance Officer in supporting the Compliance Framework
					</li>
					<li>
General guideline 6 - Skills, knowledge, expertise and authority of the compliance function  -  55.    Within the compliance function there should at least be knowledge of MiFID II and all related delegated acts and of the respective national laws and regulations as well as of all applicable standards and guidelines issued by ESMA and competent authorities on these  provisions,  as  far  as  these  are  relevant  for  the  performance  of  their  tasks. Compliance  staff  should be  regularly  trained  in order  to maintain  their  knowledge.  A higher level of expertise is necessary for the designated compliance officer. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) Each Miura Compliance Framework addresses ALL applicable Regulatory Rules for each Applicable Regulation, with the Operational and Regulatory Policies and Procedures providing the necessary information to ensure the Micro-process can be executed correctly
					</li>
					<li>
General guideline 6 - Skills, knowledge, expertise and authority of the compliance function  -  56.    The compliance officer should have sufficiently broad knowledge and experience and a sufficiently  high  level  of  expertise  so  as  to  be  able  to  assume  responsibility  for  the compliance function as a whole and ensure that it is effective. In order to demonstrate the  necessary  level  of  knowledge  and/or  of  experience,  different  options  may  be foreseen at national level in the Member State concerned. For instance, some competent authorities license or approve the nominated compliance officer following an assessment of the qualifications of the compliance officer. This assessment may include an analysis of the compliance officer’s curriculum vitae, as well as an interview with the nominated person and/or an exam to be passed. This sort of process may help to strengthen the position of the compliance function within the firm and in relation to third parties. Other regulatory approaches impose the responsibility for the assessment of the compliance officer’s qualification solely on the senior management of the firm. Senior management assesses   the   prospective   compliance   officer’s   qualifications   before   appointment. Whether the firm properly complies with this requirement is then assessed within the
general review of the firm’s compliance with the relevant MiFID II requirements. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) Each Miura Compliance Framework addresses ALL applicable Regulatory Rules for each Applicable Regulation, with the Operational and Regulatory Policies and Procedures providing the necessary information to ensure the Micro-process can be executed correctly
					</li>
					<li>
General guideline 6 - Skills, knowledge, expertise and authority of the compliance function  -  57.    The  compliance  officer  should  demonstrate  sufficient  professional  experience  as  is necessary to be able to assess the compliance risks and conflicts of interest inherent in the firm’s business activities. The required professional experience may have, amongst others, been acquired in operational positions, in other control functions or in regulatory functions.   In   some   jurisdictions,   the   professional   experience   is   only   taken   into consideration if it has been acquired during a minimum period of time and provided it is
not outdated. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) Each Miura Compliance Framework addresses ALL applicable Regulatory Rules for each Applicable Regulation, with the Operational and Regulatory Policies and Procedures providing the necessary information to ensure the Micro-process can be executed correctly
					</li>
					<li>
General guideline 6 - Skills, knowledge, expertise and authority of the compliance function  -  58.    The compliance officer should have specific knowledge of the different business activities provided by the firm. The relevant expertise required may differ from one firm to another, as the nature of the main compliance risks that firms face will differ. In respect of Article 21(1)(d) of the MiFID II Delegated Regulation, a newly employed compliance officer may therefore need additional specialised knowledge focused on the specific business model of the firm even if the person has previously been the compliance officer for another firm. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) Each Miura Compliance Framework addresses ALL applicable Regulatory Rules for each Applicable Regulation, with the Operational and Regulatory Policies and Procedures providing the necessary information to ensure the Micro-process can be executed correctly
					</li>
					<li>
General guideline 7 - Permanence of the compliance function  -  59.    MiFID II requires firms to ensure that the compliance function performs its tasks and responsibilities  on  a  permanent  basis.  Firms  should  therefore  establish  adequate arrangements for ensuring that the responsibilities of the compliance officer are fulfilled when the compliance officer is absent, and adequate arrangements to ensure that the responsibilities of the compliance function are performed on an ongoing basis. These arrangements should be in writing. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) The Miura Portal and Framework executes a complete compliance monitoring programme on every sub-rule across all compliance documents on a daily basis. This is the permanent record of a firms compliance with their obligations. This will allow firms flexbility in the support they provide to their framework in the event a Compliance Officer is absent
					</li>
					<li>
General guideline 7 - Permanence of the compliance function  -  60.    The firm should ensure, e.g. through internal procedures and stand-in arrangements,
that the responsibilities of the compliance function are fulfilled adequately during any absence of the compliance officer. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) The Miura Portal and Framework executes a complete compliance monitoring programme on every sub-rule across all compliance documents on a daily basis. This is the permanent record of a firms compliance with their obligations. This will allow firms flexbility in the support they provide to their framework in the event a Compliance Officer is absent
					</li>
					<li>
General guideline 7 - Permanence of the compliance function  -  61.    The responsibilities and competences as well as the authority of the compliance staff should be set out in a ‘compliance policy’ or other general policies or internal rules that take account of the scope and nature of the firm’s investment services and activities. This should include information on the monitoring programme and the reporting duties of the compliance function as well as information on the compliance function’s risk-based approach to monitoring activities. Relevant amendments to regulatory provisions should
be reflected promptly by adapting these policies/rules. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) Miura's Policy Documents detail how the Miura Framework works and detail the authority of the Compliance Officer in supporting the Compliance Framework
					</li>
					<li>
General guideline 7 - Permanence of the compliance function  -  62.    The compliance function should perform its activities on a permanent basis and not only in specific circumstances. This requires regular monitoring on the basis of a monitoring schedule. The monitoring activities should regularly cover all key areas of investment
services  and  activities  taking  into  account  the  compliance  risk  associated  with  the business  areas.  The  compliance  function  should  be  able  to  respond  rapidly  to unforeseen events, thereby changing the focus of its activities within a short timeframe
if necessary. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) The Miura Portal and Framework executes a complete compliance monitoring programme on every sub-rule across all compliance documents on a daily basis. This is the permanent record of a firms compliance with their obligations.
					</li>
					<li>
General guideline 8 - Independence of the compliance function  -  63.    Firms should ensure that the compliance function holds a position in the organisational structure  that  ensures  that  the  compliance  officer  and  other  compliance  staff  act independently when performing their tasks. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) The Miura Portal and Framework executes a complete compliance monitoring programme on every sub-rule across all compliance documents on a daily basis. This is the permanent record of a firms compliance with their obligations. This Automated Framework provides a completely independent picture of the compliance obligations of the firm
					</li>
					<li>
General guideline 8 - Independence of the compliance function  -  64.    While  senior  management  is  responsible  for  establishing  an  appropriate  compliance organisation  and  for  monitoring  the  effectiveness  of  the  organisation  that  has  been implemented,  the  tasks  performed  by  the  compliance  function  should  be  carried  out independently  from  senior  management and  other  units of  the  firm. In particular, the firm’s organisation should ensure that other business units may not issue instructions or otherwise influence compliance staff and their activities. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) The Miura Portal and Framework executes a complete compliance monitoring programme on every sub-rule across all compliance documents on a daily basis. This is the permanent record of a firms compliance with their obligations. This Automated Framework provides a completely independent picture of the compliance obligations of the firm
					</li>
					<li>
General guideline 8 - Independence of the compliance function  -  65.    Where senior management deviates from important recommendations or assessments issued  by  the  compliance  function,  the  compliance  officer  should  document  this accordingly and present it in the compliance reports. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) All documentation of decisions the impact the successful completion of a Micro-process will be documented in the Notes of the Sign-off Dashboards of the business, compliance or Audit
					</li>
					<li>
General guideline 9 - Proportionality with regard to the effectiveness of the compliance function  -  66.    Firms should decide which measures, including organisational measures and the level of resources, are best suited to ensuring the effectiveness of the compliance function in the firm’s particular circumstances. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) Miura's Compliance Framework should be supported by a compliance team driven by the number of Business and Regulatory Obligations (if using scanning) and also taking into account the size of the business if not.
					</li>
					<li>
General guideline 9 - Proportionality with regard to the effectiveness of the compliance function  -  67.    In deciding whether the requirements under Art. 22 (3) point (d) or (e) of the MiFID II Delegated Regulation are proportionate and whether its compliance function continues to be effective, firms should take the following criteria (inter alia) into account:
(a)     the  types  of  investment  services,  activities  and  ancillary  services  and  other business activities provided by the firm (including those not related to investment services, activities and ancillary services);
(b)     the  interaction  between  the  investment  services  and  activities  and  ancillary services and other business activities carried out by the firm;
(c)     the scope and volume of the investment services, activities and ancillary services carried out (absolute and relative to other business activities), balance sheet total and income of the firm from commissions and fees and other income in the context of the provision of investment services, activities and ancillary services;
(d)     the types of financial instruments offered to clients;
(e)     the types of clients targeted by the firm (professional, retail, eligible counterparties);
(f)      staff headcount;
(g)     whether the firm is part of a group within the meaning of Article 2(11) of Directive 2013/34/EU of the European Parliament and of the Council of 26 June 2013 on the annual financial statements, consolidated financial statements and related reports of certain types of undertakings, amending Directive 2006/43/EC of the European Parliament and of the Council and repealing Council Directives 78/660/EEC and 83/349/EEC;
(h)     services provided through a commercial network, such as tied agents, or branches;
(i)      cross-border activities provided by the firm; and
(j)      organisation and sophistication of the IT systems. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) The Miura Portal and Framework executes a complete compliance monitoring programme on every sub-rule across all compliance documents on a daily basis. This will result in a finite number of business and regulatory micro-processes to review and upload evidence and sign-off documents on an annual basis. Based on the complexity of the business, an accurate time to execute each process can be calculated and thus amount of compliance time required. This should result in an accurate staffing requirement
					</li>
					<li>
General guideline 9 - Proportionality with regard to the effectiveness of the compliance function  -  68.    Competent authorities may also find these criteria useful in determining which types of firms may benefit from the proportionality exemption under Article 22(4) of the MiFID II Delegated Regulation. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) The Miura Portal and Framework executes a complete compliance monitoring programme on every sub-rule across all compliance documents on a daily basis. This will result in a finite number of business and regulatory micro-processes to review and upload evidence and sign-off documents on an annual basis. Based on the complexity of the business, an accurate time to execute each process can be calculated and thus amount of compliance time required. This should result in an accurate staffing requirement
					</li>
					<li>
General guideline 9 - Proportionality with regard to the effectiveness of the compliance function  -  69.    A firm may fall, for example, under the proportionality exemption if the performance of the necessary compliance tasks does not require a full-time position due to the nature, scale and complexity of the firm’s business, and the nature and range of the investment services, activities and ancillary services offered. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) The Miura Portal and Framework executes a complete compliance monitoring programme on every sub-rule across all compliance documents on a daily basis. This will result in a finite number of business and regulatory micro-processes to review and upload evidence and sign-off documents on an annual basis. Based on the complexity of the business, an accurate time to execute each process can be calculated and thus amount of compliance time required. This should result in an accurate staffing requirement
					</li>
					<li>
General guideline 9 - Proportionality with regard to the effectiveness of the compliance function  -  70.    While a compliance officer must always be appointed, it may be disproportionate for a smaller firm with a very narrow field of activities to appoint a separate compliance officer (i.e.  one  that  does  not  perform  any  other  function).  Where  a  firm  makes  use  of  the exemption, conflicts of interest between the tasks performed by the relevant persons should be minimised as much as possible. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) The Miura Portal and Framework executes a complete compliance monitoring programme on every sub-rule across all compliance documents on a daily basis. This will result in a finite number of business and regulatory micro-processes to review and upload evidence and sign-off documents on an annual basis. Based on the complexity of the business, an accurate time to execute each process can be calculated and thus amount of compliance time required. This should result in an accurate staffing requirement
					</li>
					<li>
General guideline 9 - Proportionality with regard to the effectiveness of the compliance function  -  71.    A firm that does not need to comply with all the requirements set out in Article 22(3) of the MiFID II Delegated Regulation under the proportionality principle, may combine the legal and compliance function. However, a firm with more complex activities or greater size  should  avoid  such  combination  if  it  could  undermine  the  compliance  function’s independence. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) The Miura Portal and Framework executes a complete compliance monitoring programme on every sub-rule across all compliance documents on a daily basis. This will result in a finite number of business and regulatory micro-processes to review and upload evidence and sign-off documents on an annual basis. Based on the complexity of the business, an accurate time to execute each process can be calculated and thus amount of compliance time required. This should result in an accurate staffing requirement
					</li>
					<li>
General guideline 9 - Proportionality with regard to the effectiveness of the compliance function  -  72.    Where a firm makes use of the proportionality exemption, it should record how this is justified, so that the competent authority is able to assess this. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) The Miura Portal and Framework executes a complete compliance monitoring programme on every sub-rule across all compliance documents on a daily basis. This will result in a finite number of business and regulatory micro-processes to review and upload evidence and sign-off documents on an annual basis. Based on the complexity of the business, an accurate time to execute each process can be calculated and thus amount of compliance time required. This should result in an accurate staffing requirement
					</li>
					<li>
General guideline 10 - Combining the compliance function with other internal control functions  -  73.    A  firm  should  generally  not  combine  the  compliance  function  with  the  internal  audit function. The combination of the compliance function with other control functions may be acceptable  if  this  does  not  compromise  the  effectiveness  and  independence  of  the compliance  function.  Any  such  combination  should  be  documented,  including  the reasons for the combination so that competent authorities are able to assess whether the combination of functions is appropriate in the circumstances. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) The Miura Portal and Framework executes a complete compliance monitoring programme on every sub-rule across all compliance documents on a daily basis. This is the permanent record of a firms compliance with their obligations. This Automated Framework provides a completely independent picture of the compliance obligations of the firm. Audit have a separate sign-off process and dashboard also.
					</li>
					<li>
General guideline 10 - Combining the compliance function with other internal control functions  -  74.    Compliance staff should generally not be involved in the activities they monitor. However, a combination of the compliance function with other control units at the same level (such as money laundering prevention) may be acceptable if this does not generate conflicts of interests or compromise the effectiveness of the compliance function. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) The Miura Portal and Framework executes a complete compliance monitoring programme on every sub-rule across all compliance documents on a daily basis. This is the permanent record of a firms compliance with their obligations. This Automated Framework provides a completely independent picture of the compliance obligations of the firm.
					</li>
					<li>
General guideline 10 - Combining the compliance function with other internal control functions  -  75.    Combining the compliance function with the internal audit function should generally be avoided  as  this  is  likely  to  undermine  the  independence  of  the  compliance  function because  the  internal  audit  function  is  charged  with  the  oversight  of  the  compliance function. However, for practical reasons (for example, decision making), and in certain circumstances (for example, in firms of only two persons), it may be more appropriate to have one person responsible for both functions. In this regard, firms should consider discussing the combination with the relevant supervisory authority. In addition, where this combination occurs, the firm must, of course, ensure that the responsibilities of each function are discharged properly (i.e. soundly, honestly and professionally). - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) The Miura Portal and Framework executes a complete compliance monitoring programme on every sub-rule across all compliance documents on a daily basis. This is the permanent record of a firms compliance with their obligations. This Automated Framework provides a completely independent picture of the compliance obligations of the firm. Audit have a separate sign-off process and dashboard also.
					</li>
					<li>
General guideline 10 - Combining the compliance function with other internal control functions  -  76.    Whether staff from other control functions also perform compliance tasks, should also be a relevant consideration in the determination of the relevant number of staff necessary for the compliance function. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) Miura's Compliance Framework should be supported by a compliance team driven by the number of Business and Regulatory Obligations (if using scanning) and also taking into account the size of the business if not.
					</li>
					<li>
General guideline 10 - Combining the compliance function with other internal control functions  -  77.    Whether  or not the compliance function  is combined with other control functions, the compliance  function  should  coordinate  its  activities  with  the  second-level  control activities performed by other units. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) The Miura Portal and Framework executes a complete compliance monitoring programme on every sub-rule across all compliance documents on a daily basis. This is the permanent record of a firms compliance with their obligations. This Automated Framework provides a completely independent picture of the compliance obligations of the firm. Audit have a separate sign-off process and dashboard also.
					</li>
					<li>
General guideline 10 - Combining the compliance function with other internal control functions  -  78.    If appropriate to the nature, scale and complexity of the business, and taking into account the nature and range of investment services and activities undertaken by a firm in the course of that business, the firm should consider establishing and maintaining a core team within the compliance function staff members whose sole area of responsibility is MiFID compliance. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) Miura's Compliance Framework should be supported by a compliance team driven by the number of Business and Regulatory Obligations (if using scanning) and also taking into account the size of the business if not.
					</li>
					<li>
General guideline 11 - Outsourcing of the compliance function  -  79.    Firms should ensure that all applicable compliance function requirements are fulfilled where all or part of the compliance function is outsourced. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) The Miura Portal and Framework executes a complete compliance monitoring programme on every sub-rule across all compliance documents on a daily basis. This is the permanent record of a firms compliance with their obligations. This Automated Framework provides a completely independent picture of the compliance obligations of the firm, and thus is perfect to support an outsourcing model as it is completely thorough.
					</li>
					<li>
General guideline 11 - Outsourcing of the compliance function  -  80.    The MiFID II outsourcing requirements for critical or important functions apply in full to the outsourcing of the compliance function. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) The Miura Portal and Framework executes a complete compliance monitoring programme on every sub-rule across all compliance documents on a daily basis. This is the permanent record of a firms compliance with their obligations. This Automated Framework provides a completely independent picture of the compliance obligations of the firm, and thus is perfect to support an outsourcing model as it is completely thorough for critical functions
					</li>
					<li>
General guideline 11 - Outsourcing of the compliance function  -  81.    The requirements that apply to the compliance function are the same whether or not any or  all  of  the  compliance  function  is  outsourced;  Firms  can  only  outsource  tasks  or functions, but not responsibilities: firms wishing to engage in outsourcing remain fully responsible for the tasks that are outsourced. In other words, the ability to direct and control outsourced tasks must always be retained by the firm initiating the outsourcing. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) The Miura Portal and Framework executes a complete compliance monitoring programme on every sub-rule across all compliance documents on a daily basis. This is the permanent record of a firms compliance with their obligations. This Automated Framework provides a completely independent picture of the compliance obligations of the firm, and thus is perfect to support an outsourcing model. Management can oversee the operational framework they have oursourced, while retaining responsibility
					</li>
					<li>
General guideline 11 - Outsourcing of the compliance function  -  82.    The firm should perform a due diligence assessment before choosing a service provider in order to ensure that the criteria set out in Articles 22 and 31 of the MiFID II Delegated Regulation are met. The firm should ensure that the service provider has the necessary authority, resources, expertise and access to all relevant information in order to perform the outsourced compliance function tasks effectively. The extent of the due diligence assessment  is  dependent  on  the  nature,  scale,  complexity and  risk  of  the  tasks and
processes that are outsourced. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) The Miura Portal and Framework executes a complete compliance monitoring programme on every sub-rule across all compliance documents on a daily basis. This is the permanent record of a firms compliance with their obligations. This Automated Framework provides a completely independent picture of the compliance obligations of the firm, and thus is perfect to support an outsourcing model as it is completely thorough
					</li>
					<li>
General guideline 11 - Outsourcing of the compliance function  -  83.    Firms should also ensure that when outsourced partially or fully, the compliance function remains permanent in  nature,  i.e. the service  provider  should be able to perform the function on an ongoing basis and not only in specific circumstances. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) The Miura Portal and Framework executes a complete compliance monitoring programme on every sub-rule across all compliance documents on a daily basis. This is the permanent record of a firms compliance with their obligations. This Automated Framework provides a completely independent picture of the compliance obligations of the firm, and thus is perfect to support an outsourcing model as it is completely thorough
					</li>
					<li>
General guideline 11 - Outsourcing of the compliance function  -  84.    Firms should monitor whether the service provider performs its duties adequately, which includes  monitoring  the  quality  and  the  quantity  of  the  services  provided.  Senior management is responsible for supervising and monitoring the outsourced function on an ongoing basis, and should have the necessary resources and expertise to be able to fulfil this responsibility. Senior management may appoint a specific person to supervise
and monitor the outsourced function on their behalf. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) The Miura Portal and Framework executes a complete compliance monitoring programme on every sub-rule across all compliance documents on a daily basis. This is the permanent record of a firms compliance with their obligations. This Automated Framework provides a completely independent picture of the compliance obligations of the firm, and thus is perfect to support an outsourcing model as it is completely thorough
					</li>
					<li>
General guideline 11 - Outsourcing of the compliance function  -  85.    Outsourcing of the compliance function within a group does not lead to a lower level of responsibility  for  the  senior  management  of  the  individual  firms  within  the  group. However,  a  centralised  group  compliance  function  may,  in  some  cases,  provide  the compliance officer with better access to information, and lead to greater efficiency of the function, especially if the entities share the same premises. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) The Miura Portal and Framework executes a complete compliance monitoring programme on every sub-rule across all compliance documents on a daily basis. This Automated Framework provides a completely independent picture of the compliance obligations of the firm. Access can be given to Group Compliance to provide an integrated Group Compliance Framework
					</li>
					<li>
General guideline 11 - Outsourcing of the compliance function  -  86.    If a firm, due to the nature, size and scope of its business activities, is unable to employ compliance staff who are independent of the performance of services they monitor, then outsourcing of the compliance function is likely to be an appropriate approach to take. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) The Miura Portal and Framework executes a complete compliance monitoring programme on every sub-rule across all compliance documents on a daily basis. This is the permanent record of a firms compliance with their obligations. This Automated Framework provides a completely independent picture of the compliance obligations of the firm
					</li>
					<li>
General guideline 12 - Review of the compliance function by competent authorities  -  87.    Competent authorities should review how firms plan to meet, implement and maintain the MiFID II compliance function requirements. This should apply in the context of the authorisation process, as well as, following a risk-based approach, in the course of on- going supervision. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) The Miura Framework has a purpose built dashboard for the regulator to faciliate it reviewing how the firm complies with all its regulatory obligations across each stage of the regulatory compliance lifecycle (eg Risk Assessment, Policy, Procedures, Evidence, 1st Line, 2nd Line, 3rd Line, etc..)
					</li>
					<li>
General guideline 12 - Review of the compliance function by competent authorities  -  88.    Article 7 of MiFID II states that a competent authority shall not grant authorisation to a firm unless and until such time as it is fully satisfied that the applicant complies with all requirements  under  the  provisions  adopted  pursuant  to  MiFID  II.  Accordingly,  the competent authority should assess whether a firm’s compliance function is adequately resourced and organised and whether adequate reporting lines have been established. It should require that any necessary amendments are made to the compliance function
as a condition for authorisation. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) The Miura Framework has a purpose built dashboard for the regulator to faciliate it reviewing how the firm complies with all its regulatory obligations across each stage of the regulatory compliance lifecycle (eg Risk Assessment, Policy, Procedures, Evidence, 1st Line, 2nd Line, 3rd Line, etc..)
					</li>
					<li>
General guideline 12 - Review of the compliance function by competent authorities  -  89.    Additionally, as part of the ongoing supervisory process, a competent authority should – following a risk-based approach – assess whether the measures implemented by the firm for the compliance function are adequate, and whether the compliance function fulfils its   responsibilities   appropriately.   Firms   are   responsible   for   determining   whether amendments to the resources and organisation of the compliance function are required due to changes in the business model of the firm. Competent authorities should also, as part  of  their  ongoing  supervision  and  following  a  risk  based  approach,  assess  and monitor - where and if appropriate - whether such amendments are necessary and have been implemented. The competent authority should provide a reasonable timeframe for the firm to make amendments. However, firms’ amendments are not necessarily subject
to approval by the competent authorities. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) The Miura Framework has a purpose built dashboard for the regulator to faciliate it reviewing how the firm complies with all its regulatory obligations across each stage of the regulatory compliance lifecycle (eg Risk Assessment, Policy, Procedures, Evidence, 1st Line, 2nd Line, 3rd Line, etc..)
					</li>
					<li>
General guideline 12 - Review of the compliance function by competent authorities  -  90.    As  mentioned  under  paragraph  56  above,  some  competent  authorities  license  or
approve the nominated compliance officer following an assessment of the qualifications of the compliance officer. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) Compliance with this Rule will be addressed through the appropriate Regulatory Micro-process
					</li>
					<li>
General guideline 12 - Review of the compliance function by competent authorities  -  91.    Other  regulatory  approaches  impose  the  responsibility  for  the  assessment  of  the compliance  officer’s qualification solely on the senior  management  of the firm. Some Member States require firms to notify the competent authorities of the appointment and replacement of the compliance officer. In some jurisdictions, this notification must also be accompanied by a detailed statement on the grounds for the replacement. This can help competent authorities gain insight into possible tensions between the compliance officer  and  senior management  which  could  be  an  indication  of  deficiencies  in  the compliance function’s independence. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) Compliance with this Rule will be addressed through the appropriate Regulatory Micro-process
					</li>
					<li>
General guideline 12 - Review of the compliance function by competent authorities  -  92.    Some Member States require the compliance officer to fulfil an annual questionnaire in order to gather information on compliance of the firm. The questionnaire is an evaluation grid on how the applicant’s business is going to be conducted and monitored by the firm. This evaluation grid includes questions related to all investment services (to be) provided by the firm. Questions are also related to the monitoring and control of the activity to be performed by the applicant. e.g. how the control functions are organized, who they report to,  whether  some functions  are outsourced, etc., as  well  as  a number  of  open fields asking the firm to describe any relevant changes and developments compared to the previous year. The answers could be validated by the firm’s senior management and then  sent  to  the  competent  authority.  This  questionnaire  could  be  a  standardised, machine-readable report to enable data extraction, incorporate qualitative indicators and flags  anomalies  in  a  resource-efficient  manner.  The  questionnaire  could  be  used  to monitor the firm and to require the firm to adopt an action plan to remediate to the issues, to determinate the priorities of the supervision of the competent authority and to calibrate
its risk based approach. - (HOW MIURA FRAMEWORK ADDRESSES GUIDELINE) The Miura Framework has a purpose built dashboard for the regulator to faciliate it reviewing how the firm complies with all its regulatory obligations across each stage of the regulatory compliance lifecycle (eg Risk Assessment, Policy, Procedures, Evidence, 1st Line, 2nd Line, 3rd Line, etc..)
					</li>
                  </ol>
                </span>
              </li>
            </ul>
          </SidePanelCardItem>
          <SidePanelCardItem canScroll={true} marginRight={0}>
            <span>Validation logs <DialogLogs data={props.validationLogsData || { results: [] }}/> </span>
            <ul>
              {props.validationLogsData !== undefined &&
                props.validationLogsData.results !== undefined &&
                props.validationLogsData.results.map((logItem, i) => {
                  const logStatus = logItem.compliance_document_validation_status;
                  return (
                    <li key={`log-${i}`}>
                      <span>
                        <span className="title">
                          <span
                            className={`log-status 
                            ${logStatus === 'Failed' ? 'failed' : 'passed'}
                          `}
                          >
                            {logStatus}
                          </span>
                          <span className="log-status-date">
                            {Moment(logItem.created_on).format('MMM Do YYYY, h:mm a')}
                          </span>
                        </span>
                        <br />
                        <span className="log-status-content">
                          {logItem.compliance_document_validation_info}
                        </span>
                      </span>
                    </li>
                  );
                })}
            </ul>
          </SidePanelCardItem>
        </div>
        <div className="document-content">
          <div className="document-body">
            <MonitoringTable
              monitoringProcesses={monitoringProcesses}
              microRegulatoryProcessData={props.microRegulatoryProcessData}
              triggerReloadContent={() => props.triggerReloadContent()}
              triggerReloadContentNoReset={() => props.triggerReloadContentNoReset()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

Monitoring.defaultProps = {};

export default Monitoring;
