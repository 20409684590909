import 'assets/styles/category-container.scss';

import React, { useEffect, useState } from 'react';

import DocumentIntro from 'shared/DocumentIntro';
import DocumentLifecycleProcess from 'shared/DocumentLifecycleProcess';
import Moment from 'moment';
import ProcessTable from 'shared/ProcessTable';
import SidePanelCardItem from 'shared/SidePanelCardItem';
import StatusCard from 'shared/StatusCard';
import DialogLogs from "../../../shared/DialogBox/dialogLogs";

const Risk = (props) => {
  const [lifecycleProcesses, setLifecycleProcesses] = useState([]);
  const [validationLogs, setValidationLogs] = useState();
  const [validationLogic, setValidationLogic] = useState([]);

  useEffect(() => {
    setLifecycleProcesses(props.sectionData.risk_rules);
    setValidationLogs(props.validationLogsData);
    setValidationLogic([]);
  }, [props.microRegulatoryProcessData, props.sectionData, props.validationLogsData]);

  return (
    <div className="category-layout-standard risk-container">
      <div className="document-body-container">
        <div className="document-content">
          <div className="document-title">{props.sectionData.name}</div>
          <div className="document-body">
            <DocumentIntro
              documentName={props.sectionData.name}
              businessProcessOwner={`${props.microRegulatoryProcessData.owner.first_name}" + " ${props.microRegulatoryProcessData.owner.last_name}`}
              businessProcess={props.microRegulatoryProcessData.micro_regulatory_process.name}
            />
            {lifecycleProcesses !== undefined &&
              lifecycleProcesses.map((lifecycleProcess, i) => {
                return <DocumentLifecycleProcess key={i} lifecycleProcess={lifecycleProcess} />;
              })}
          </div>
        </div>
        <div className="document-side-panel">
          <SidePanelCardItem height={200}>
            <span>Risk Rule Validation Status</span>
			  {lifecycleProcesses?.length > 0 && ( <StatusCard sectionData={props.sectionData}
			             rules={lifecycleProcesses}/>)}
          </SidePanelCardItem>
          <SidePanelCardItem height={420} canScroll={true}>
            <span> GUIDELINES On certain aspects of the MiFID II compliance function requirements (06/04/2021 - ESMA 35-36-1952) </span>
            <ul>
              <li>
                <span>
                  <b>The document below details how this Compliance Document set and associated Compliance Monitoring Framework addresses every Rule of the ESMA MIFID II Compliance Function Guidelines 2021</b>
                  <br />
                  <br />
                  <ol>
<li>EMSA Compliance Guideline 1  -  COMPLIANCE RISK ASSESSMENT  (HOW MIURA COMPLIES) Each Regulatory & Operational Process in the ZeroCRisk System has a Risk Assessment Document</li>
<li>EMSA Compliance Guideline 2 -  MONITORING OBLIGATIONS OF THE COMPLIANCE FUNCTION  (HOW MIURA COMPLIES) Each Regulatory & Operational Process in the ZeroCRisk System is part of a Real-Time Compliance Framework with a built-in Compliance Monitoring Programme</li>
<li>EMSA Compliance Guideline 3 -  REPORTING OBLIGATIONS OF THE COMPLIANCE FUNCTION  (HOW MIURA COMPLIES) The Real-time status of all the Compliance Ops Processes gives a Real-time Reporting Status of the Compliance Function
The Real-time status of all the Reg Rules links to Ops Processes gives a Real-time view of the business not complying with the Regs
The Horizon Scanning/Impact Assessment/Change Mgt Screens give a Real-time View of Regulation coming into the firm
The Issue Management Screen gives a Real-time view of Compliance Issues
</li>
<li>EMSA Compliance Guideline 4 -  ADVISORY AND ASSISTANCE OBLIGATIONS OF THE COMPLIANCE FUNCTION  (HOW MIURA COMPLIES) ZeroCRisk delivers and automates Advisory and assistance by linking all Regulatory Rules to all Ops Processes and maps the to the owner means all business owners have access to the rules they need to complete their Ops Process (we will also be introducing a Chatbot in the next few months to answer questions)</li>
<li>EMSA Compliance Guideline 5 -  EFFECTIVENESS OF THE COMPLIANCE FUNCTION  (HOW MIURA COMPLIES) The Miura Compliance Framework is one source of compliance truth based off the language of the regulation, meaning its real-time controls framework Reduces Regulatory Risk and Operational Risk to close to zero. This is a positive starting point for the Compliance Function to manage the compliance of the organisation</li>
<li>EMSA Compliance Guideline 6 -  SKILLS, KNOWLEDGE, EXPERTISE AND AUTHORITY OF THE COMPLIANCE FUNCTION  (HOW MIURA COMPLIES) The efficiency and transparency of the compliance of the Miura Framework:
1. Supports the skills of the Compliance Lead through the Real-Time view of all Compliance Processes
2. Supports the Compliance lead by providing knowledge on its own processes, but also the knowledge the business needs to execute its own processes
3. Supports the expertise of the compliance lead through a chatGPT driven database of answers
4. The tranparency of the Compliance message back to the business as to whether they comply with their obligations and the ability of the Regulator being able to log in remotely shortens the communication loop between the business and the regulator, helping compliance with the authority of their message</li>
<li>EMSA Compliance Guideline 7 -  PERMANENCE OF THE COMPLIANCE FUNCTION  (HOW MIURA COMPLIES) The Miura Compliance Framework is one source of compliance truth based off the language of the regulation, meaning its real-time controls framework is constant through Compliance Function and Business Change over time</li>
<li>EMSA Compliance Guideline 8 -  INDEPENDENCE OF THE COMPLIANCE FUNCTION  (HOW MIURA COMPLIES) The Miura Compliance Framework is one source of compliance truth based off the language of the regulation, meaning its real-time controls framework is independent from any organisational bias.</li>
<li>EMSA Compliance Guideline 9 -  PROPORTIONALITY WITH REGARD TO THE EFFECTIVENESS OF THE COMPLIANCE FUNCTION  (HOW MIURA COMPLIES) The Miura Compliance Framework is one source of compliance truth based off the language of the regulation, meaning its real-time controls framework Reduces Regulatory Risk and Operational Risk to close to zero with little reletive headcount, so proportionality is less relevant.</li>
<li>EMSA Compliance Guideline 10 -  COMBINING THE COMPLIANCE FUNCTION WITH OTHER INTERNAL CONTROL FUNCTIONS  (HOW MIURA COMPLIES) The Miura Controls Framework combines first level, second level controls on the one Dashboard. Third and Fourth level Dashboards can be added to have the complete Regulation Lifecycle traceable from publication to regulator review on the one system</li>
<li>EMSA Compliance Guideline 11 -  OUTSOURCING OF THE COMPLIANCE FUNCTION  (HOW MIURA COMPLIES) The Miura Compliance Framework is one source of compliance truth based off the language of the regulation, meaning its real-time controls framework Reduces Regulatory Risk and Operational Risk to close to zero. The impact of outsourcing this framework to be run by an external 3rd party to the controls framework maintained by the orgnaisation would be minimal</li>
<li>EMSA Compliance Guideline 12 -  REVIEW OF THE COMPLIANCE FUNCTION BY COMPETENT AUTHORITIES  (HOW MIURA COMPLIES) The Miura System creates a 100% Real-time Compliance Framework for a Regulated Entity for the very first time, giving firms a Compliance Framework they can be proud of.
Given this, they have the ability to invite Regulators to log into their system to conduct high-level and low level reviews remotely, reducing systemic risk significantly</li>
<li>Detailed Guideline 1_13 (REG RULE) As part of its responsibility for ensuring that the firm complies with its obligations under MiFID II, senior management must ensure that the compliance function fulfils the requirements set out in Article 22 of the MiFID II Delegated Regulation. (MAIN CLAUSE). SENIOR MANAGEMENT MUST ENSURE THAT THE COMPLIANCE FUNCTION FULFILS THE REQUIREMENTS SET OUT IN ARTICLE 22 OF THE MIFID II DELEGATED REGULATION. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because it tracks how the firm automates the 12 Roles of Compliance</li>
<li>Detailed Guideline 1_14 (REG RULE) In accordance with Article 22(2) of the MiFID II Delegated Regulation, the compliance function shall, as part of its tasks, conduct a risk assessment to ensure that compliance risks are comprehensively monitored.  (MAIN CLAUSE).  THE COMPLIANCE FUNCTION SHALL CONDUCT A RISK ASSESSMENT. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because The ZeroCRisk System Creates a Risk Assessment Document for each Reg and Ops Process</li>
<li>Detailed Guideline 1_14a (REG RULE) The compliance function shall establish a risk- based monitoring programme on the basis of this compliance risk assessment to determine its priorities and the focus of the monitoring, advisory and assistance activities. (MAIN CLAUSE). THE COMPLIANCE FUNCTION SHALL ESTABLISH A RISK-BASED MONITORING PROGRAMME. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because The ZeroCRisk  System has a Comprehensive Risk-based Monitoring programme addressing most Rules</li>
<li>Detailed Guideline 1_15 (REG RULE) The findings of the compliance risk assessment should be used to set the work programme of the compliance function and to allocate the functions resources efficiently.  (MAIN CLAUSE).  THE FINDINGS OF THE COMPLIANCE RISK ASSESSMENT SHOULD BE USED. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because The ZeroCRisk System uses the output of the Risk Assessment to Validate All processes daily and review processes Annually unless the Compliance team deem a process warrants more stringent oversight</li>
<li>Detailed Guideline 1_15a (REG RULE) The compliance risk assessment should be reviewed on a regular basis, and, when necessary, updated to ensure that the objectives, focus and the scope of compliance monitoring and advisory activities remain valid. (MAIN CLAUSE). THE COMPLIANCE RISK ASSESSMENT SHOULD BE REVIEWED ON A REGULAR BASIS. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because The Compliance Risk Assessment is Review as part of the Compliance Monitoring Programme, typicall Annually</li>
<li>Detailed Guideline 1_16 (REG RULE)  In identifying the level of compliance risk the firm faces, the second subparagraph of Article 22(1) of the MiFID II Delegated Regulation requires the compliance function to take into account all the areas of the investment services, activities and ancillary services provided by the firm.  (MAIN CLAUSE).  THE COMPLIANCE FUNCTION IS REQUIRED TO TAKE INTO ACCOUNT ALL THE AREAS OF THE INVESTMENT SERVICES, ACTIVITIES AND ANCILLARY SERVICES PROVIDED BY THE FIRM. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because All Operational and Regulatory Processes are addressed by the Compliance Monitoring Programme, which includes all of these items</li>
<li>Detailed Guideline 1_16a (REG RULE) This should include the types of financial instruments traded and distributed, the categories of the firms clients, the distribution channels and, where relevant, the internal organisation of the group. (MAIN CLAUSE). THE FINANCIAL INSTRUMENTS, CATEGORIES OF CLIENTS, DISTRIBUTION CHANNELS, AND INTERNAL ORGANISATION OF THE GROUP SHOULD BE INCLUDED. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because Finanical Instruments, categories of clients, distribution channels and internal organisations are reviewed as part of their individual Ops Processes</li>
<li>Detailed Guideline 1_17 (REG RULE) The compliance risk assessment should consider the applicable obligations under MiFID II, national implementing rules and the policies, procedures, systems and controls implemented within the firm in the area of investment services and activities.  (MAIN CLAUSE).  THE COMPLIANCE RISK ASSESSMENT SHOULD CONSIDER THE APPLICABLE OBLIGATIONS UNDER MIFID II, NATIONAL IMPLEMENTING RULES AND THE POLICIES, PROCEDURES, SYSTEMS AND CONTROLS IMPLEMENTED WITHIN THE FIRM IN THE AREA OF INVESTMENT SERVICES AND ACTIVITIES. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because All MIFID Regulatory Document Types are included as part of the Compliance Framework.
All Policies, Procedures, Systems and Controls are reviewed as part of the Framework</li>
<li>Detailed Guideline 1_17a (REG RULE) The assessment should also consider the results of any monitoring activities and of any relevant internal or external audit findings. (MAIN CLAUSE). THE ASSESSMENT SHOULD ALSO CONSIDER THE RESULTS OF ANY MONITORING ACTIVITIES AND OF ANY RELEVANT INTERNAL OR EXTERNAL AUDIT FINDINGS. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because All Monitoring reviews the issues that have arisen during the period</li>
<li>Detailed Guideline 1_18 (REG RULE) The identified risks should be reviewed on a regular basis and, when necessary, also on an ad-hoc basis to ensure that any emerging risks are taken into consideration (for example, resulting from new business fields, other relevant changes in the firms structure or in the applicable regulatory framework). (MAIN CLAUSE). THE IDENTIFIED RISKS SHOULD BE REVIEWED ON A REGULAR BASIS AND, WHEN NECESSARY, ALSO ON AN AD-HOC BASIS. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because All Ops Processes and Reg Processes are reviewed as per the Compliance Monitoring Schedule. This scchule can be augmented for specific processes </li>
<li>Detailed Guideline 2_19 (REG RULE) The aim of the risk-based monitoring programme should be to evaluate whether the firms business is conducted in compliance with its obligations under MiFID II as well as whether its internal policies and procedures, organisation and control measures remain effective and appropriate to ensure that compliance risk is comprehensively monitored. (MAIN CLAUSE). THE AIM OF THE RISK-BASED MONITORING PROGRAMME SHOULD BE TO EVALUATE WHETHER THE FIRMS BUSINESS IS CONDUCTED IN COMPLIANCE WITH ITS OBLIGATIONS UNDER MIFID II AS WELL AS WHETHER ITS INTERNAL POLICIES AND PROCEDURES, ORGANISATION AND CONTROL MEASURES REMAIN EFFECTIVE AND APPROPRIATE. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because All MIFID Obligations are tracked through the MIFID Rule Lists
Policies, Procedures and Controls are established for every Ops Process and Reg Process</li>
<li>Detailed Guideline 2_20 (REG RULE) Where a firm is part of a group, responsibility for the compliance function rests with each firm in that group.  (MAIN CLAUSE). RESPONSIBILITY FOR THE COMPLIANCE FUNCTION RESTS WITH EACH FIRM IN THAT GROUP. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because A firm will have a Legal Entity Moduel  Configured on ZeroCRisk for each group. </li>
<li>Detailed Guideline 2_20a (REG RULE) A firm should therefore ensure that its compliance function remains responsible for monitoring its own compliance risk.  (MAIN CLAUSE). A FIRM SHOULD THEREFORE ENSURE THAT ITS COMPLIANCE FUNCTION REMAINS RESPONSIBLE FOR MONITORING ITS OWN COMPLIANCE RISK.  (HOW MIURA COMPLIES) ZeroCRisk Automates this process because A firm will have a Legal Entity Moduel  Configured on ZeroCRisk for each group. </li>
<li>Detailed Guideline 2_20b (REG RULE) This includes where a firm outsources compliance tasks to another firm within the group.  (MAIN CLAUSE). THIS INCLUDES WHERE A FIRM OUTSOURCES COMPLIANCE TASKS TO ANOTHER FIRM WITHIN THE GROUP.  (HOW MIURA COMPLIES) ZeroCRisk Automates this process because A firm will have a Legal Entity Moduel  Configured on ZeroCRisk for each group. This can be managed by staff in other groups but the Real-Time Compliance Picture remains for each individual Entity</li>
<li>Detailed Guideline 2_20c (REG RULE) The compliance function within each firm should, however, take into account the group of which it is a part - for example, by working closely with audit, legal, regulatory and compliance staff in other parts of the group. (MAIN CLAUSE). THE COMPLIANCE FUNCTION WITHIN EACH FIRM SHOULD, HOWEVER, TAKE INTO ACCOUNT THE GROUP OF WHICH IT IS A PART - FOR EXAMPLE, BY WORKING CLOSELY WITH AUDIT, LEGAL, REGULATORY AND COMPLIANCE STAFF IN OTHER PARTS OF THE GROUP. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because A firm will have a Legal Entity Moduel  Configured on ZeroCRisk for each group. This can be managed by staff in other groups but the Real-Time Compliance Picture remains for each individual Entity</li>
<li>Detailed Guideline 2_21 (REG RULE) The risk-based approach to compliance should form the basis for determining the appropriate tools and methodologies used by the compliance function, as well as the extent of the monitoring programme and the frequency of monitoring activities performed by the compliance function (which may be recurring, ad-hoc and/or continuous). The compliance function should also ensure that its monitoring activities are not only desk- based, but that it also verifies how policies and procedures are implemented in practice, for example through on-site inspections at the operative business units. The compliance function should also consider the scope of reviews to be performed. (MAIN CLAUSE). THE COMPLIANCE FUNCTION SHOULD ALSO ENSURE THAT ITS MONITORING ACTIVITIES ARE NOT ONLY DESK- BASED, BUT THAT IT ALSO VERIFIES HOW POLICIES AND PROCEDURES ARE IMPLEMENTED IN PRACTICE, FOR EXAMPLE THROUGH ON-SITE INSPECTIONS AT THE OPERATIVE BUSINESS UNITS. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because The success of executing policies and procedures is validated through quality of the output validated by the syxtems scanners.</li>
<li>Detailed Guideline 2_22a (REG RULE) Examples of suitable tools and methodologies for monitoring activities that could be used by the compliance function include (but are not limited to):  the use of aggregated risk measurements (for example, risk indicators); (MAIN CLAUSE). THE USE OF AGGREGATED RISK MEASUREMENTS, SUCH AS RISK INDICATORS, FOR MONITORING ACTIVITIES COULD BE USED BY THE COMPLIANCE FUNCTION. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because There is no requirement for aggregated risk measures as each micro-process is monitored individually</li>
<li>Detailed Guideline 2_22b (REG RULE) Examples of suitable tools and methodologies for monitoring activities that could be used by the compliance function include (but are not limited to): the use of (additional) reports warranting management attention documenting material deviations between actual occurrences and expectations (exceptions report) or situations requiring resolution (issues log); (MAIN CLAUSE). THE COMPLIANCE FUNCTION COULD USE TOOLS AND METHODOLOGIES FOR MONITORING ACTIVITIES SUCH AS ADDITIONAL REPORTS, EXCEPTIONS REPORT, AND ISSUES LOG. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because All Issues logs, exception reports are montiored on the system in real-time</li>
<li>Detailed Guideline 2_22c (REG RULE) Examples of suitable tools and methodologies for monitoring activities that could be used by the compliance function include (but are not limited to): targeted trade surveillance, observation of procedures, desk reviews, interview of relevant staff and/or, where necessary, and at the discretion of the compliance function, of a relevant sample of firms clients; (MAIN CLAUSE). THE COMPLIANCE FUNCTION COULD USE TARGETED TRADE SURVEILLANCE, OBSERVATION OF PROCEDURES, DESK REVIEWS, INTERVIEWS OF RELEVANT STAFF, AND/OR, WHERE NECESSARY, A RELEVANT SAMPLE OF THE FIRMS CLIENTS TO MONITOR ACTIVITIES. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because These processes are tracked through these individual ops processes</li>
<li>Detailed Guideline 2_23 (REG RULE) The monitoring programme should reflect changes to the firms risk profile, which may arise, for example, from significant events such as corporate acquisitions, IT system changes, or reorganisation.  (MAIN CLAUSE). THE MONITORING PROGRAMME SHOULD REFLECT CHANGES TO THE FIRMS RISK PROFILE. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because Monitoring the Firms Risk Profile is a separate Ops process</li>
<li>Detailed Guideline 2_23a (REG RULE) It should also extend to the implementation and effectiveness of any remedial measures taken by the firm in response to breaches of MiFID II, related delegated or implementing acts and/or any national implementing provisions thereof. (MAIN CLAUSE). IT SHOULD ALSO EXTEND TO THE IMPLEMENTATION AND EFFECTIVENESS OF ANY REMEDIAL MEASURES TAKEN BY THE FIRM IN RESPONSE TO BREACHES OF MIFID II, RELATED DELEGATED OR IMPLEMENTING ACTS AND/OR ANY NATIONAL IMPLEMENTING PROVISIONS THEREOF. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because All Issues are managed and tracked by Ops and Reg Process. If there is an issue for an individual process this will be reviewed as part of the Compliance Monitoring and any changes to the monitoring frequency will be fed into the Review Schedule</li>
<li>Detailed Guideline 2_24a (REG RULE) Monitoring activities performed by the compliance function should also take into account: the business areas obligation to comply with regulatory requirements; (MAIN CLAUSE). THE COMPLIANCE FUNCTION SHOULD MONITOR ACTIVITIES TO ENSURE THE BUSINESS AREAS COMPLIANCE WITH REGULATORY REQUIREMENTS. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because Applicable Reg Rules are Mapped to each Operational Process. These will be Reviewed as part of the Montiroing of each Operational Process</li>
<li>Detailed Guideline 2_24b (REG RULE) Monitoring activities performed by the compliance function should also take into account: the first level of controls in the firms business areas (namely controls by the operative units, as opposed to second level controls performed by compliance); and (MAIN CLAUSE). MONITORING ACTIVITIES PERFORMED BY THE COMPLIANCE FUNCTION SHOULD TAKE INTO ACCOUNT THE FIRST LEVEL OF CONTROLS IN THE FIRMS BUSINESS AREAS. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because First line controls are reviewed as part of the Compliance Dashboard for eahc process</li>
<li>Detailed Guideline 2_24c (REG RULE) Monitoring activities performed by the compliance function should also take into account: reviews by the risk management function, internal audit function or other control functions in the area of investment services and activities. (MAIN CLAUSE).  MONITORING ACTIVITIES PERFORMED BY THE COMPLIANCE FUNCTION SHOULD ALSO TAKE INTO ACCOUNT REVIEWS BY THE RISK MANAGEMENT FUNCTION, INTERNAL AUDIT FUNCTION OR OTHER CONTROL FUNCTIONS IN THE AREA OF INVESTMENT SERVICES AND ACTIVITIES. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because The Compliance Function Reviews all Ope processes including Risk Management, Internal Audit and Other  Control Functions (e.g. Conflicts of Interest)</li>
<li>Detailed Guideline 2_25 (REG RULE) Reviews by control functions should be coordinated with the monitoring activities performed by the compliance function while respecting the different functions independence and mandate. (MAIN CLAUSE).  REVIEWS BY CONTROL FUNCTIONS SHOULD BE COORDINATED WITH THE MONITORING ACTIVITIES PERFORMED BY THE COMPLIANCE FUNCTION. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because All control functions are indicitual ops processes with individual control reports which are validated as per validation policy. Compliance then reviews the completion of these processes as a separate independent process</li>
<li>Detailed Guideline 2_26 (REG RULE)  The compliance function should have a role in monitoring the operation of the complaints process and it should consider complaints as a source of relevant information in the context of its general monitoring responsibilities.  (MAIN CLAUSE).  THE COMPLIANCE FUNCTION SHOULD HAVE A ROLE IN MONITORING THE OPERATION OF THE COMPLAINTS PROCESS. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because The Compliants Process is a Separate Ops Process that is monitored and reviewed like any other</li>
<li>Detailed Guideline 2_26a (REG RULE) This does not require the compliance function to have a role in determining the outcome of complaints.  (MAIN CLAUSE).  THIS DOES NOT REQUIRE THE COMPLIANCE FUNCTION TO HAVE A ROLE. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because The Compliants Process is a Separate Ops Process that is monitored and reviewed like any other by Compliance but they have not influence over it</li>
<li>Detailed Guideline 2_26b (REG RULE) In this regard, firms should grant the compliance function access to all customer complaints received by the firm. (MAIN CLAUSE). FIRMS SHOULD GRANT THE COMPLIANCE FUNCTION ACCESS TO ALL CUSTOMER COMPLAINTS RECEIVED BY THE FIRM. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because The Compliants Process is a Separate Ops Process that is monitored and reviewed like any other by Compliance but they have not influence over it</li>
<li>Detailed Guideline 3_27 (REG RULE)  The mandatory compliance reports according to paragraphs (2)(c) and (3)(c) of Article 22 and paragraphs (2) and (3) of Article 25 of the MiFID II Delegated Regulation are suitable tools to warrant the necessary management attention.  (MAIN CLAUSE). MANDATORY COMPLIANCE REPORTS ARE SUITABLE TOOLS TO WARRANT THE NECESSARY MANAGEMENT ATTENTION. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because Real-time Compliance Reporting is Available on the ZeroCRisk System for all key Roles  of the Compliance Function </li>
<li>Detailed Guideline 3_27a (REG RULE) The mandatory compliance reports should cover all business units involved in the provision of investment services, activities and ancillary services provided by a firm.  (MAIN CLAUSE).  MANDATORY COMPLIANCE REPORTS SHOULD COVER ALL BUSINESS UNITS INVOLVED IN THE PROVISION OF INVESTMENT SERVICES, ACTIVITIES AND ANCILLARY SERVICES. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because All Business Units have a Real-time view of their Ops Processes and Reg Processes on the system</li>
<li>Detailed Guideline 3_27b (REG RULE) Where the report does not cover all of these activities and services of the firm, it should clearly state the reasons. (MAIN CLAUSE). THE REPORT SHOULD CLEARLY STATE THE REASONS. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because The ZeroCRisk System addresses all Investment services, activities and ancilliary services as part of this Operations Processes</li>
<li>Detailed Guideline 3_28a1 (REG RULE)  The mandatory compliance reports should, inter alia, contain information on the following matters, where relevant: (a) General information: Â· information on the adequacy and effectiveness of the firms policies and procedures designed to ensure that the firm and its staff comply with the obligations under MiFID II; (MAIN CLAUSE).  THE MANDATORY COMPLIANCE REPORTS SHOULD CONTAIN INFORMATION ON THE ADEQUACY AND EFFECTIVENESS OF THE FIRMS POLICIES AND PROCEDURES DESIGNED TO ENSURE THAT THE FIRM AND ITS STAFF COMPLY WITH THE OBLIGATIONS UNDER MIFID II. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because  The system is broken into Ops Processes to take the MIFID Business formard and Reg Processes, to ensure the firm complies with each sentence of MIFID and applicable Regulation.
The system Auto-creates policies and procedures for each process.
These policy and procedures documents are scanned on a daily basis to ensure they comply</li>
<li>Detailed Guideline 3_28a2 (REG RULE)  The mandatory compliance reports should, inter alia, contain information on the following matters, where relevant: (a) General information: Â· relevant changes and developments in the applicable requirements over the period covered by the report; (MAIN CLAUSE).  THE MANDATORY COMPLIANCE REPORTS SHOULD CONTAIN INFORMATION ON RELEVANT CHANGES AND DEVELOPMENTS IN THE APPLICABLE REQUIREMENTS OVER THE PERIOD COVERED BY THE REPORT. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because ZeroCRisk is tracking all Reg Changes through to implementation on the Horizon Scanning, Impact Assessment Reg Change Magement Screens in Real-time. These are linked in the Compliance Report</li>
<li>Detailed Guideline 3_28a3 (REG RULE)  The mandatory compliance reports should, inter alia, contain information on the following matters, where relevant: (a) General information: Â· a summary of the compliance functions structure, including the overall personnel employed, their qualifications and reporting lines and in following reports, any change thereto; (MAIN CLAUSE).  THE MANDATORY COMPLIANCE REPORTS SHOULD CONTAIN INFORMATION ON THE FOLLOWING MATTERS, WHERE RELEVANT: GENERAL INFORMATION (HOW MIURA COMPLIES) ZeroCRisk Automates this process because ZeroCRisk contains a Real-time View of the Ops and Regulatory Processes in the organisation with the Responsibility Map of the Organisation, including owners.
Owners qualifications and training are references in the EMPLOYEE CERTIFICATION and TRAIN WORKFLORCE Ops Processes</li>"
"<li>Detailed Guideline 3_28b1 (REG RULE)  The mandatory compliance reports should, inter alia, contain information on the following matters, where relevant: (b) Manner of monitoring and reviewing Â· how the compliance-function monitors the development and review of the obligations under MiFID II and how possible risks of failure by the firm or its staff to comply with these obligations are identified at an early stage; (MAIN CLAUSE).  MANNER OF MONITORING AND REVIEWING
SUBORDINATE CLAUSE HOW THE COMPLIANCE-FUNCTION MONITORS THE DEVELOPMENT AND REVIEW OF THE OBLIGATIONS UNDER MIFID II AND HOW POSSIBLE RISKS OF FAILURE BY THE FIRM OR ITS STAFF TO COMPLY WITH THESE OBLIGATIONS ARE IDENTIFIED AT AN EARLY STAGE; (HOW MIURA COMPLIES) ZeroCRisk Automates this process because Compliance with MIFID (and other Regulations) are monitored via Regulatory Processes, one micro-process for each sentence of Regulation</li>
<li>Detailed Guideline 3_28b2 (REG RULE)  The mandatory compliance reports should, inter alia, contain information on the following matters, where relevant: (b) Manner of monitoring and reviewing Â· a summary of on-site inspections or desk-based reviews performed by the compliance function; (MAIN CLAUSE).  MANNER OF MONITORING AND REVIEWING (HOW MIURA COMPLIES) ZeroCRisk Automates this process because On-site Inspections are tracked through INTERNAL AUDIT and REGULATOR AUDIT Ops Processes and Desk Reviews are tracked through the CONDUCT DESK REVIEW Ops process although this process can be automated by greater validation of the Ops Process Output</li>
<li>Detailed Guideline 3_28b3 (REG RULE)  The mandatory compliance reports should, inter alia, contain information on the following matters, where relevant: (b) Manner of monitoring and reviewing Â· a summary of the planned monitoring activities for the subsequent review; (MAIN CLAUSE).  MANNER OF MONITORING AND REVIEWING SHOULD CONTAIN INFORMATION ON THE PLANNED MONITORING ACTIVITIES FOR THE SUBSEQUENT REVIEW. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because Planned Monitoring Activitied happen via the compliance monioring programme. Ops or Reg Processes to be reviewed appear in the Usr Management Dashbaord 30 days before Next Review Date</li>
<li>Detailed Guideline 3_28c1 (REG RULE) The mandatory compliance reports should, inter alia, contain information on the following matters, where relevant: (c) Findings Â· a summary of major findings of the review of the policies and procedure, including risks identified in the scope of the compliance functions monitoring activities; (MAIN CLAUSE). THE MANDATORY COMPLIANCE REPORTS SHOULD CONTAIN INFORMATION ON THE FINDINGS OF THE REVIEW OF THE POLICIES AND PROCEDURES. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because Policies and Procedures are reviewed on an annual basis as part of the Compliance Monitoring Programme for each process</li>
<li>Detailed Guideline 3_28c2 (REG RULE) The mandatory compliance reports should, inter alia, contain information on the following matters, where relevant: (c) FindingsÂ· breaches and deficiencies in the firms organisation and compliance process; (MAIN CLAUSE). THE MANDATORY COMPLIANCE REPORTS SHOULD CONTAIN INFORMATION ON FINDINGS, BREACHES, AND DEFICIENCIES IN THE FIRMS ORGANISATION AND COMPLIANCE PROCESS. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because Breaches and deficiencies appear in the Issue Management Dasboard for tracking and resolution</li>
<li>Detailed Guideline 3_28c3 (REG RULE) The mandatory compliance reports should, inter alia, contain information on the following matters, where relevant: (c) FindingsÂ· the number of complaints received in the period under review if not already reported through other sources.  (MAIN CLAUSE).  THE MANDATORY COMPLIANCE REPORTS SHOULD CONTAIN INFORMATION ON THE NUMBER OF COMPLAINTS RECEIVED IN THE PERIOD UNDER REVIEW IF NOT ALREADY REPORTED THROUGH OTHER SOURCES. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because Complaints appear in the COMPLAINTS HANDLING Ops Process</li>
<li>Detailed Guideline 3_28c4 (REG RULE) Where, as a result of the review of clients complaints, specific compliance or risk issues are identified in relation to the policies or procedures adopted by the firm for the provision of investment services and activities, these aspects should be specifically reported; (MAIN CLAUSE). SPECIFIC COMPLIANCE OR RISK ISSUES IDENTIFIED IN RELATION TO THE POLICIES OR PROCEDURES ADOPTED BY THE FIRM FOR THE PROVISION OF INVESTMENT SERVICES AND ACTIVITIES SHOULD BE REPORTED. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because Complaints appear in the Complaints Management Ops Process</li>
<li>Detailed Guideline 3_28d1 (REG RULE)  The mandatory compliance reports should, inter alia, contain information on the following matters, where relevant: (d) Actions takenÂ· a summary of any action taken to address any significant risk of failure by the firm or its staff to comply with the obligations under MiFID II; (MAIN CLAUSE).  ACTIONS TAKEN SHOULD BE INCLUDED IN THE MANDATORY COMPLIANCE REPORTS. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because Breaches and deficiencies appear in the Issue Management Dasboard for tracking and resolution</li>
<li>Detailed Guideline 3_28d2 (REG RULE)  The mandatory compliance reports should, inter alia, contain information on the following matters, where relevant: (d) Actions takenÂ· measures taken and to be taken to ensure compliance with changed applicable requirements; (MAIN CLAUSE).  THE MANDATORY COMPLIANCE REPORTS SHOULD CONTAIN INFORMATION ON THE FOLLOWING MATTERS, WHERE RELEVANT. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because Breaches and deficiencies appear in the Issue Management Dasboard for tracking and resolution</li>
<li>Detailed Guideline 3_28d3 (REG RULE)  The mandatory compliance reports should, inter alia, contain information on the following matters, where relevant: (d) Actions taken Â· reaction to complaints received and any pay-out performed based on the complaint, if not already reported through other sources.  (MAIN CLAUSE). ACTIONS TAKEN REACTION TO COMPLAINTS RECEIVED AND ANY PAY-OUT PERFORMED BASED ON THE COMPLAINT, IF NOT ALREADY REPORTED THROUGH OTHER SOURCES. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because Complaints appear in the Complaints Management Ops Process</li>
<li>Detailed Guideline 3_28d4 (REG RULE) Actions regarding specific compliance or risk issues identified in relation to the policies or procedures adopted by the firm for the provision of investment services and activities as a result of the review of clients complaints; (MAIN CLAUSE). ACTIONS REGARDING SPECIFIC COMPLIANCE OR RISK ISSUES IDENTIFIED IN RELATION TO THE POLICIES OR PROCEDURES ADOPTED BY THE FIRM FOR THE PROVISION OF INVESTMENT SERVICES AND ACTIVITIES. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because Risk and Compliance Actions are either recorded under the Ops Record when it goes to the Issue Management screen and complaints are recorded through this Ops Process</li>
<li>Detailed Guideline 3_28e1 (REG RULE) The mandatory compliance reports should, inter alia, contain information on the following matters, where relevant: (e) Others Â· other significant compliance issues that have occurred since the last report; (MAIN CLAUSE). THE MANDATORY COMPLIANCE REPORTS SHOULD CONTAIN INFORMATION ON OTHER SIGNIFICANT COMPLIANCE ISSUES THAT HAVE OCCURRED SINCE THE LAST REPORT. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because Breaches and deficiencies appear in the Issue Management Dasboard for tracking and resolution</li>
<li>Detailed Guideline 3_28e2 (REG RULE)  The mandatory compliance reports should, inter alia, contain information on the following matters, where relevant: (e) Others Â· overview of material correspondence with competent authorities; and (MAIN CLAUSE).  THE MANDATORY COMPLIANCE REPORTS SHOULD, INTER ALIA, CONTAIN INFORMATION ON OVERVIEW OF MATERIAL CORRESPONDENCE WITH COMPETENT AUTHORITIES (HOW MIURA COMPLIES) ZeroCRisk Automates this process because All Regulator Communication is managed through the COMMUNICATE WITH REGULATORS Operational Process, which is part of the Compliance Report, as all Compliance Ops Processes</li>
<li>Detailed Guideline 3_28e3 (REG RULE)  The mandatory compliance reports should, inter alia, contain information on the following matters, where relevant: (e) Others Â· information as regards any deviation by senior management from important recommendations or assessments issued by the compliance function; (MAIN CLAUSE).  THE MANDATORY COMPLIANCE REPORTS SHOULD CONTAIN INFORMATION ON THE FOLLOWING MATTERS, WHERE RELEVANT. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because The Compliance Report contains information on the deviation by senior management from important rcommendations from Compliance</li>
<li>Detailed Guideline 3_28e4 (REG RULE)  The mandatory compliance reports should, inter alia, contain information on the following matters, where relevant: (e) Others Â· information in relation to any deviation from the principle that the other business units must not issue instructions or otherwise influence compliance staff and their activities; and (MAIN CLAUSE).  THE MANDATORY COMPLIANCE REPORTS SHOULD CONTAIN INFORMATION ON ANY DEVIATION FROM THE PRINCIPLE THAT THE OTHER BUSINESS UNITS MUST NOT ISSUE INSTRUCTIONS OR OTHERWISE INFLUENCE COMPLIANCE STAFF AND THEIR ACTIVITIES. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because All Buisness Decisions, whether they are in contravention with the Compliance Advice or Company Strategy, are recorded in all Meeting Minutes</li>
<li>Detailed Guideline 3_28e5 (REG RULE)  The mandatory compliance reports should, inter alia, contain information on the following matters, where relevant: (e) Others Â· where a firm makes use of the exemption to avoid appointing a compliance officer whose sole responsibility within the firm is the compliance function, assessment of the continuing appropriateness of the arrangements to minimize conflicts of interest. (MAIN CLAUSE). A FIRM SHOULD MAKE AN ASSESSMENT OF THE CONTINUING APPROPRIATENESS OF THE ARRANGEMENTS TO MINIMIZE CONFLICTS OF INTEREST WHERE IT MAKES USE OF THE EXEMPTION TO AVOID APPOINTING A COMPLIANCE OFFICER WHOSE SOLE RESPONSIBILITY WITHIN THE FIRM IS THE COMPLIANCE FUNCTION. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because The Execution of the Conflicts of Interest Ops Process is always carried out by whoever is managing the Compliance Function (List of Compliance Ops Processes)</li>
<li>Detailed Guideline 3_29a (REG RULE) 29. In the section of the report covering the firms product governance arrangements, the compliance function should also address, where relevant to the situation of the firm (for example, taking into account its role as product manufacturer and/or distributor), at least: (a) the compliance functions role in participating to the elaboration, monitoring and reviewing of the firms product governance policies and procedures; (MAIN CLAUSE).  THE COMPLIANCE FUNCTION SHOULD ADDRESS...AT LEAST
(B) THE COMPLIANCE FUNCTIONS ROLE IN ENSURING THAT THE FIRMS PRODUCT GOVERNANCE POLICIES AND PROCEDURES ARE IMPLEMENTED AND ADHERED TO;
EXTRACT MAIN CLAUSE THE COMPLIANCE FUNCTION SHOULD ADDRESS...AT LEAST (HOW MIURA COMPLIES) ZeroCRisk Automates this process because Product Governenance on Miuras ZeroCRisk system is a Separate Ops process with Real-time Oversight, no matter the size of the organisation.</li>
<li>Detailed Guideline 3_29b (REG RULE) 29. In the section of the report covering the firms product governance arrangements, the compliance function should also address, where relevant to the situation of the firm (for example, taking into account its role as product manufacturer and/or distributor), at least: (b) all topics required under Article 22(2) MiFID II Delegated Regulation, regarding the monitoring of the firms product governance by the compliance-function (for example, the compliance functions findings relating to the firms product governance policies and procedures, breaches and deficiencies, actions taken or to be taken to remedy the latter). (MAIN CLAUSE). IN THE SECTION OF THE REPORT COVERING THE FIRMS PRODUCT GOVERNANCE ARRANGEMENTS, THE COMPLIANCE FUNCTION SHOULD ALSO ADDRESS, WHERE RELEVANT TO THE SITUATION OF THE FIRM, AT LEAST ALL TOPICS REQUIRED UNDER ARTICLE 22(2) MIFID II DELEGATED REGULATION, REGARDING THE MONITORING OF THE FIRMS PRODUCT GOVERNANCE BY THE COMPLIANCE-FUNCTION. (HOW MIURA COMPLIES) Product Governenance on Miuras ZeroCRisk system is a Separate Ops process with Real-time Oversight, no matter the size of the organisation.</li>
<li>Detailed Guideline 3_29c1 (REG RULE) 29. In the section of the report covering the firms product governance arrangements, the compliance function should also address, where relevant to the situation of the firm (for example, taking into account its role as product manufacturer and/or distributor), at least: (c) systematically, information about the financial instruments manufactured/distributed by the firm, including information on the distribution strategy according to Articles 9(6) and 10 (8) of the MiFID II Delegated Directive, namely at least: Â· the number and nature of the products manufactured or distributed (as applicable), including their respective target markets and other information from the respective product approval process necessary to assess the products compliance-risk, notably with the firms product governance policy (for example, complexity of the product, product related conflicts of interests, particularly relevant data from the scenario analysis, the cost-return ratio), with a specific focus on new types of products manufactured or distributed during the reporting period as well as the ones whose features have been significantly amended during that period. (MAIN CLAUSE). THE COMPLIANCE FUNCTION SHOULD ADDRESS, WHERE RELEVANT TO THE SITUATION OF THE FIRM, SYSTEMATICALLY INFORMATION ABOUT THE FINANCIAL INSTRUMENTS MANUFACTURED/DISTRIBUTED BY THE FIRM, INCLUDING INFORMATION ON THE DISTRIBUTION STRATEGY ACCORDING TO ARTICLES 9(6) AND 10 (8) OF THE MIFID II DELEGATED DIRECTIVE. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because Product Governenance on Miuras ZeroCRisk system is a Separate Ops process with Real-time Oversight, no matter the size of the organisation.</li>
<li>Detailed Guideline 3_29c2 (REG RULE) 29. In the section of the report covering the firms product governance arrangements, the compliance function should also address, where relevant to the situation of the firm (for example, taking into account its role as product manufacturer and/or distributor), at least: (c) systematically, information about the financial instruments manufactured/distributed by the firm, including information on the distribution strategy according to Articles 9(6) and 10 (8) of the MiFID II Delegated Directive, namely at least: Â· (in case of manufacturers) as part of the information on the respective distribution strategy: the respective distributors with a specific focus on new distributors; (MAIN CLAUSE). IN THE SECTION OF THE REPORT COVERING THE FIRMS PRODUCT GOVERNANCE ARRANGEMENTS, THE COMPLIANCE FUNCTION SHOULD ALSO ADDRESS, WHERE RELEVANT TO THE SITUATION OF THE FIRM, SYSTEMATICALLY, INFORMATION ABOUT THE FINANCIAL INSTRUMENTS MANUFACTURED/DISTRIBUTED BY THE FIRM. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because Product Governenance on Miuras ZeroCRisk system is a Separate Ops process with Real-time Oversight, no matter the size of the organisation.</li>
<li>Detailed Guideline 3_29c3 (REG RULE) 29. In the section of the report covering the firms product governance arrangements, the compliance function should also address, where relevant to the situation of the firm (for example, taking into account its role as product manufacturer and/or distributor), at least: (c) systematically, information about the financial instruments manufactured/distributed by the firm, including information on the distribution strategy according to Articles 9(6) and 10 (8) of the MiFID II Delegated Directive, namely at least: Â· whether the products are distributed outside their (positive) target market and to which extent, with the aim to assess whether the firms product governance arrangements function as intended.  (MAIN CLAUSE). THE COMPLIANCE FUNCTION SHOULD ADDRESS, WHERE RELEVANT TO THE SITUATION OF THE FIRM, INFORMATION ABOUT THE FINANCIAL INSTRUMENTS MANUFACTURED/DISTRIBUTED BY THE FIRM, INCLUDING INFORMATION ON THE DISTRIBUTION STRATEGY ACCORDING TO ARTICLES 9(6) AND 10 (8) OF THE MIFID II DELEGATED DIRECTIVE, WITH THE AIM TO ASSESS WHETHER THE FIRMS PRODUCT GOVERNANCE ARRANGEMENTS FUNCTION AS INTENDED. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because Product Governenance on Miuras ZeroCRisk system is a Separate Ops process with Real-time Oversight, no matter the size of the organisation.</li>
<li>Detailed Guideline 3_30 (REG RULE) According to the proportionality principle, when reporting, for example, on the firms product governance arrangements, the information for simpler, more common products may be less in-depth, whereas products characterised by complexity/risk features or by other relevant features (such as, for example, illiquidity and innovation) should be described in more detail. (MAIN CLAUSE). THE INFORMATION FOR SIMPLER, MORE COMMON PRODUCTS MAY BE LESS IN-DEPTH, WHEREAS PRODUCTS CHARACTERISED BY COMPLEXITY/RISK FEATURES OR BY OTHER RELEVANT FEATURES SHOULD BE DESCRIBED IN MORE DETAIL. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because Product Governenance on Miuras ZeroCRisk system is a Separate Ops process with Real-time Oversight, no matter the size of the organisation.</li>
<li>Detailed Guideline 3_30a (REG RULE) Subject to the proportionality principle, firms should favour an organisation where the compliance function and the complaints management function are properly separated.  (MAIN CLAUSE).  FIRMS SHOULD FAVOUR AN ORGANISATION WHERE THE COMPLIANCE FUNCTION AND THE COMPLAINTS MANAGEMENT FUNCTION ARE PROPERLY SEPARATED. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because The Compliants process is a separate Ops process which the Compliance Function has oversight of but cannot influence</li>
<li>Detailed Guideline 3_30b (REG RULE) Where the firms compliance function also acts as its complaints management function, the compliance report should address any issue arising out of the implementation of the arrangements the firm has in place to assess, minimise and manage any conflicts of interest between the two functions, including notably, any failure identified as regards the firms compliance with its complaints handling obligations. (MAIN CLAUSE). THE COMPLIANCE REPORT SHOULD ADDRESS ANY ISSUE ARISING OUT OF THE IMPLEMENTATION OF THE ARRANGEMENTS THE FIRM HAS IN PLACE TO ASSESS, MINIMISE AND MANAGE ANY CONFLICTS OF INTEREST BETWEEN THE TWO FUNCTIONS, INCLUDING NOTABLY, ANY FAILURE IDENTIFIED AS REGARDS THE FIRMS COMPLIANCE WITH ITS COMPLAINTS HANDLING OBLIGATIONS. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because Any conflicts between each Legal entity will be tracked and controlled through the Manage Conflicts of Interest Process</li>
<li>Detailed Guideline 3_31 (REG RULE) The compliance function should consider the need for additional reporting lines to any group compliance function. (MAIN CLAUSE). THE COMPLIANCE FUNCTION SHOULD CONSIDER THE NEED FOR ADDITIONAL REPORTING LINES. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because Legal Entities within a group have a separate legal entity within the system to manage their Ops and Reg Compliance.
Legal Entity Owners can be assigned to each group or and overall group</li>"
"<li>Detailed Guideline 3_32 (REG RULE) Competent authorities may take different approaches to supervising the reporting obligations of the compliance function.  (MAIN CLAUSE).  COMPETENT AUTHORITIES MAY TAKE DIFFERENT APPROACHES TO SUPERVISING THE REPORTING OBLIGATIONS OF THE COMPLIANCE FUNCTION. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because Regulators can log into the ZeroCRisk system Remotely and review the Ops Framework or the Regulatory Framework in Real-time.
They call also review issues and how they were resolved</li>
<li>Detailed Guideline 3_32 (REG RULE) By way of example, some competent authorities require firms to provide them with compliance function reports on a regular or ad hoc basis while others also require senior management to provide an annotated version of the report containing explanations of the compliance functions findings. These practices provide competent authorities with first-hand insight into a firms compliance activities, as well as any breaches of the applicable provisions. (MAIN CLAUSE). SOME COMPETENT AUTHORITIES REQUIRE FIRMS TO PROVIDE THEM WITH COMPLIANCE FUNCTION REPORTS AND FOR SENIOR MANAGEMENT TO PROVIDE AN ANNOTATED VERSION OF THE REPORT. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because Regulators can log into the ZeroCRisk system Remotely and review the Ops Framework or the Regulatory Framework in Real-time.
They call also review issues and how they were resolved</li>"
"<li>Detailed Guideline 4_33 (REG RULE) Firms should ensure that the compliance function fulfils its advisory and assistance responsibilities, including providing support for staff and management training; providing day-to-day assistance for staff and management and participating in the establishment of policies and procedures within the firm (e.g. the firms remuneration policy or the firms product governance policies and procedures) (MAIN CLAUSE). FIRMS SHOULD ENSURE THAT THE COMPLIANCE FUNCTION FULFILS ITS ADVISORY AND ASSISTANCE RESPONSIBILITIES. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because ZeroCRisk links the applicable Reg Rules with the Associated Ops processes so the busienss knows exactly what rules it needs to comply with.
The Business can also use Miuras Chatgpt chatbot to answer questions, so supporting the business in all its requirements.</li>
<li>Detailed Guideline 4_34 (REG RULE) Firms should promote and enhance a compliance culture throughout the firm, which should be supported by the senior management. The purpose of the compliance culture is not only to establish the overall environment in which compliance matters are treated, but also to engage staff with the principle of improving investor protection as well as contributing to the stability of the financial system. (MAIN CLAUSE). THE PURPOSE OF THE COMPLIANCE CULTURE IS TO ESTABLISH THE OVERALL ENVIRONMENT IN WHICH COMPLIANCE MATTERS ARE TREATED AND TO ENGAGE STAFF WITH THE PRINCIPLE OF IMPROVING INVESTOR PROTECTION AS WELL AS CONTRIBUTING TO THE STABILITY OF THE FINANCIAL SYSTEM. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because The Firms Compliance Culture is Managed and monitored through updates to all its Policy and procedures documents and Through the Compliance Culture Ops process itself</li>
<li>Detailed Guideline 4_35 (REG RULE) The firm needs to ensure that its staff is adequately trained (12 See Guidelines for the assessment of knowledge and competence; ESMA71-1154262120-153 EN (rev) 12 .  (MAIN CLAUSE). THE FIRM NEEDS TO ENSURE THAT ITS STAFF IS ADEQUATELY TRAINED. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because  Miura ZeroCRisk  has training courses for each specific MIFID Regulatory Process and Operational process, covering both the Policy and Procedures</li>
<li>Detailed Guideline 4_35 (REG RULE) The compliance function should support the business units in the area of investment services and activities (namely all staff involved directly or indirectly in the provision of investment services and activities) in performing any relevant training.  (MAIN CLAUSE).  THE COMPLIANCE FUNCTION SHOULD SUPPORT THE BUSINESS UNITS. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because  ZeroCRisk links the applicable Reg Rules with the Associated Ops processes so the busienss knows exactly what rules it needs to comply with.
The Business can also use Miuras Chatgpt chatbot to answer questions, so supporting the business in all its requirements.</li>
<li>Detailed Guideline 4_35a (REG RULE) Training and other support should focus particularly, but not exclusively, on: (a) the internal policies and procedures of the firm and its organisational structure in the area of investment services and activities; and (MAIN CLAUSE). TRAINING AND OTHER SUPPORT SHOULD FOCUS ON THE INTERNAL POLICIES AND PROCEDURES OF THE FIRM AND ITS ORGANISATIONAL STRUCTURE IN THE AREA OF INVESTMENT SERVICES AND ACTIVITIES. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because Miura ZeroCRisk  has training courses for each specific MIFID Regulatory Process and Operational process, covering both the Policy and Procedures</li>
<li>Detailed Guideline 4_35b (REG RULE) Training and other support should focus particularly, but not exclusively, on: (b) MiFID II, its delegated and implementing acts, national implementing laws, the applicable standards, guidelines and other guidance set out by ESMA and competent authorities, any other supervisory and regulatory requirements that may be relevant and any changes to those. (MAIN CLAUSE).  TRAINING AND OTHER SUPPORT SHOULD FOCUS PARTICULARLY, BUT NOT EXCLUSIVELY, ON MIFID II, ITS DELEGATED AND IMPLEMENTING ACTS, NATIONAL IMPLEMENTING LAWS, THE APPLICABLE STANDARDS, GUIDELINES AND OTHER GUIDANCE SET OUT BY ESMA AND COMPETENT AUTHORITIES, ANY OTHER SUPERVISORY AND REGULATORY REQUIREMENTS THAT MAY BE RELEVANT AND ANY CHANGES TO THOSE. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because  Miura has training courses for each specific MIFID Regulatory Process</li>
<li>Detailed Guideline 4_36 (REG RULE) Training should be performed on a regular basis, and needs-based training should be performed where necessary. (MAIN CLAUSE). TRAINING SHOULD BE PERFORMED ON A REGULAR BASIS. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because Training on the Generic Ops Processes and applicable Regulatory Rules for each Process is available on ZeroCRisk on demand. </li>
<li>Detailed Guideline 4_36 (REG RULE) Training should be delivered as appropriate - for example, to the firms entire staff as a whole, to specific business units, or to a particular individual. (MAIN CLAUSE). TRAINING SHOULD BE DELIVERED AS APPROPRIATE. (HOW MIURA COMPLIES) ZeroCRisk Automates this process because  Training on the Generic Ops Processes and applicable Regulatory Rules for each Process is available on ZeroCRisk on demand. </li>
<li>Detailed Guideline 4_37 (REG RULE) Training should be developed on an on-going basis so that it takes into account all relevant changes (for example, new legislation, standards or guidelines issued by ESMA and competent authorities, and changes in the firms business model). (MAIN CLAUSE). TRAINING SHOULD BE DEVELOPED ON AN ON-GOING BASIS. (HOW MIURA COMPLIES) ZeroCRisk Automatically delivers Training on the Generic Ops Processes and applicable Regulatory Rules for each Process.
Training will be provided for any new Regulatory document as standard.</li>
<li>Detailed Guideline 4_38 (REG RULE)  The compliance function should monitor, in cooperation with the management team, which holds ultimate executive responsibility, whether staff in the area of investment services and activities hold the necessary level of awareness and correctly apply the firms policies and procedures. (MAIN CLAUSE).  THE COMPLIANCE FUNCTION SHOULD MONITOR, IN COOPERATION WITH THE MANAGEMENT TEAM, WHETHER STAFF IN THE AREA OF INVESTMENT SERVICES AND ACTIVITIES HOLD THE NECESSARY LEVEL OF AWARENESS AND CORRECTLY APPLY THE FIRMS POLICIES AND PROCEDURES. (HOW MIURA COMPLIES) ZeroCRisk Automatically detects the Application of appplicable policies and procedures is detected through the validaton of the output files</li>
<li>Detailed Guideline 4_39 (REG RULE)  Compliance staff should also provide assistance to staff from the operative units in their day-to-day business and be available to answer questions arising out of daily business activity. (MAIN CLAUSE).  COMPLIANCE STAFF SHOULD ALSO PROVIDE ASSISTANCE TO STAFF FROM THE OPERATIVE UNITS IN THEIR DAY-TO-DAY BUSINESS AND BE AVAILABLE TO ANSWER QUESTIONS ARISING OUT OF DAILY BUSINESS ACTIVITY. (HOW MIURA COMPLIES) ZeroCRisk Automatically provides assistance to staff from the business through linking applicable rules to Ops processes and Miuras Help Chatbot</li>
<li>Detailed Guideline 4_40 (REG RULE)  Firms should ensure that the compliance function is involved in the development of the relevant policies and procedures within the firm in the area of investment services, activities and ancillary services (for example the firms remuneration policy or the firms product governance policies and procedures).  (MAIN CLAUSE).  FIRMS SHOULD ENSURE THAT THE COMPLIANCE FUNCTION IS INVOLVED IN THE DEVELOPMENT OF THE RELEVANT POLICIES AND PROCEDURES WITHIN THE FIRM IN THE AREA OF INVESTMENT SERVICES, ACTIVITIES AND ANCILLARY SERVICES. (HOW MIURA COMPLIES) ZeroCRisk Automatically creates Regulatory and Operations Policies and Procedures from the core aims os the documents and the process requirements, faciliatating a Real-time Compliance Framework.
These are then added to by the business to provides a comprehensive set of documents that meet the requirements of the firm</li>
<li>Detailed Guideline 4_40a (REG RULE) In this context, the compliance function should be enabled, for example, to provide compliance expertise and advice to business units about all strategic decisions or new business models, or about the launch of a new advertising strategy in the area of investment services and activities. (MAIN CLAUSE).  THE COMPLIANCE FUNCTION SHOULD BE ENABLED. (HOW MIURA COMPLIES) ZeroCRisk Automatically provides assistance to staff from the business through linking applicable rules to Ops processes and Miuras Help Chatbot</li>
<li>Detailed Guideline 4_40b (REG RULE)  If the compliance functions advice is not followed, the compliance function should document this accordingly and present it in its compliance reports (possibly as ad-hoc reports, where necessary). (MAIN CLAUSE).  THE COMPLIANCE FUNCTION SHOULD DOCUMENT THIS ACCORDINGLY AND PRESENT IT IN ITS COMPLIANCE REPORTS. (HOW MIURA COMPLIES) ZeroCRisk Automatically detects when its rules are not being followed, as the Regulatory Micro-process validation will fail.</li>
<li>Detailed Guideline 4_41 (REG RULE) Firms should ensure that the compliance function is involved in all significant modifications of the organisation of the firm in the area of investment services, activities and ancillary services.  (MAIN CLAUSE).  FIRMS SHOULD ENSURE THAT THE COMPLIANCE FUNCTION IS INVOLVED IN ALL SIGNIFICANT MODIFICATIONS OF THE ORGANISATION OF THE FIRM IN THE AREA OF INVESTMENT SERVICES, ACTIVITIES AND ANCILLARY SERVICES. (HOW MIURA COMPLIES) ZeroCRisk Automatically ensures that the Compliance Function is involved in all significant modifications of the firm, as they will appear as different Ops or Regulatory Processes in the ZeroCRisk system and need to be Reviewed by Compliance</li>
<li>Detailed Guideline 4_41a (REG RULE) This includes the decision-making process when new business lines or new financial products are being approved as well as the definition of staff remuneration policies.  (MAIN CLAUSE).  THE DECISION-MAKING PROCESS WHEN NEW BUSINESS LINES OR NEW FINANCIAL PRODUCTS ARE BEING APPROVED AND THE DEFINITION OF STAFF REMUNERATION POLICIES ARE INCLUDED. (HOW MIURA COMPLIES) ZeroCRisk Automatically ensures that the Compliance Function is involved in all significant modifications of the firm, as they will appear as different Ops or Regulatory Processes in the ZeroCRisk system and need to be Reviewed by Compliance</li>
<li>Detailed Guideline 4_41b (REG RULE) In this context, the compliance function should be given the right to participate in the product approval process for manufacturers and distributors as applicable.  (MAIN CLAUSE).  THE COMPLIANCE FUNCTION SHOULD BE GIVEN THE RIGHT TO PARTICIPATE IN THE PRODUCT APPROVAL PROCESS. (HOW MIURA COMPLIES) ZeroCRisk Automatically ensures that the Compliance Function is involved in all significant modifications of the firm, as they will appear as different Ops or Regulatory Processes in the ZeroCRisk system and need to be Reviewed by Compliance. This applies to new products as they will be Separate Ops Processes</li>
<li>Detailed Guideline 4_41c (REG RULE) Senior management should therefore request business units to consult with the compliance function in due time regarding their operations, where relevant. (MAIN CLAUSE). SENIOR MANAGEMENT SHOULD REQUEST BUSINESS UNITS TO CONSULT WITH THE COMPLIANCE FUNCTION. (HOW MIURA COMPLIES) ZeroCRisk Automatically the consultantion required between the business and the Compliance Function by giving access to all the applicable regulatory rules applicable to their business and providing access to their chatbot for the busienss to ask questions</li>
<li>Detailed Guideline 4_42 (REG RULE) Firms should ensure that the compliance function is involved in all material non-routine correspondence with competent authorities in the area of investment services and activities. (MAIN CLAUSE). FIRMS SHOULD ENSURE THAT THE COMPLIANCE FUNCTION IS INVOLVED IN ALL MATERIAL NON-ROUTINE CORRESPONDENCE WITH COMPETENT AUTHORITIES IN THE AREA OF INVESTMENT SERVICES AND ACTIVITIES. (HOW MIURA COMPLIES) ZeroCRisk Automatically ensures that they are involved in all non-routine correspondance with the Regulator as they the owners of the COMMUNICATE WITH REGULATOR Ops Process and so review all the updates to this operational process.</li>
<li>Detailed Guideline 5_43 (REG RULE) When ensuring that appropriate human and other resources are allocated to the compliance function, firms should take into account the scale and types of investment services, activities and ancillary services undertaken by the firm. (MAIN CLAUSE).  FIRMS SHOULD TAKE INTO ACCOUNT THE SCALE AND TYPES OF INVESTMENT SERVICES, ACTIVITIES AND ANCILLARY SERVICES UNDERTAKEN BY THE FIRM. (HOW MIURA COMPLIES) ZeroCRisk Automatically ensures that adequate resources are allocated to the Compliance Function through the ENSURE ADEQUATE RESOURCES Ops Process</li>
<li>Detailed Guideline 5_44 (REG RULE) The number of staff required for the tasks of the compliance function depends to a large extent on the nature of the investment services, activities and ancillary services and other services provided by the firm.  (MAIN CLAUSE). THE NUMBER OF STAFF REQUIRED FOR THE TASKS OF THE COMPLIANCE FUNCTION DEPENDS TO A LARGE EXTENT ON THE NATURE OF THE SERVICES PROVIDED BY THE FIRM. (HOW MIURA COMPLIES) ZeroCRisk Automatically ensures that adequate resources are allocated to the Compliance Function through the ENSURE ADEQUATE RESOURCES Ops Process</li>
<li>Detailed Guideline 5_44a (REG RULE) Where a firms business unit activities are significantly extended, the firm should ensure that the compliance function is similarly extended as necessary in view of changes to the firms compliance risk. (MAIN CLAUSE). THE FIRM SHOULD ENSURE THAT THE COMPLIANCE FUNCTION IS SIMILARLY EXTENDED AS NECESSARY IN VIEW OF CHANGES TO THE FIRMS COMPLIANCE RISK. (HOW MIURA COMPLIES) ZeroCRisk Automatically ensures that adequate resources are allocated to the Compliance Function through the ENSURE ADEQUATE RESOURCES Ops Process</li>
<li>Detailed Guideline 5_44b (REG RULE)  Senior management should monitor regularly, and at least once a year, whether the number of staff and their expertise is still adequate for the fulfilment of the duties of the compliance function. (MAIN CLAUSE).  SENIOR MANAGEMENT SHOULD MONITOR REGULARLY, AND AT LEAST ONCE A YEAR, WHETHER THE NUMBER OF STAFF AND THEIR EXPERTISE IS STILL ADEQUATE FOR THE FULFILMENT OF THE DUTIES OF THE COMPLIANCE FUNCTION. (HOW MIURA COMPLIES) ZeroCRisk Automatically ensures that adequate resources are allocated to the Compliance Function through the ENSURE ADEQUATE RESOURCES Ops Process</li>
<li>Detailed Guideline 5_45 (REG RULE) In addition to human resources, sufficient IT resources should be allocated to the compliance function. (MAIN CLAUSE). SUFFICIENT IT RESOURCES SHOULD BE ALLOCATED TO THE COMPLIANCE FUNCTION. (HOW MIURA COMPLIES) ZeroCRisk Automatically ensures that adequate resources are allocated to the Compliance Function through the ENSURE ADEQUATE RESOURCES Ops Process</li>
<li>Detailed Guideline 5_46 (REG RULE)  Where the firm establishes budgets for specific functions or units, the compliance function should be allocated a budget that is consistent with the level of compliance risk the firm is exposed to.  (MAIN CLAUSE).  THE COMPLIANCE FUNCTION SHOULD BE ALLOCATED A BUDGET THAT IS CONSISTENT WITH THE LEVEL OF COMPLIANCE RISK THE FIRM IS EXPOSED TO. (HOW MIURA COMPLIES) ZeroCRisk Automatically ensures that adequate resources are allocated to the Compliance Function through the ENSURE ADEQUATE RESOURCES Ops Process</li>
<li>Detailed Guideline 5_46a (REG RULE) The compliance officer should be consulted before the budget is determined.  (MAIN CLAUSE). THE COMPLIANCE OFFICER SHOULD BE CONSULTED BEFORE THE BUDGET IS DETERMINED.  (HOW MIURA COMPLIES) ZeroCRisk Automatically ensures The compliance officer should be consulted before the budget is determined by having them as a signature signing off the document.</li>
<li>Detailed Guideline 5_46b (REG RULE) All decisions for significant cuts in the budget should be documented in writing and should contain detailed explanations. (MAIN CLAUSE).  DECISIONS FOR SIGNIFICANT CUTS IN THE BUDGET SHOULD BE DOCUMENTED IN WRITING. (HOW MIURA COMPLIES) ZeroCRisk Automatically ensures that large cuts in the budget should be docmented in writing as they will be part of the Finance Committee Agenda which must be signed off by all members of the Committee</li>
<li>Detailed Guideline 5_47 (REG RULE)  In ensuring compliance staff have access to the relevant information for their tasks at all times, firms should provide access to all relevant database and records (such as recordings of telephone conversations and electronic communications referred to in Article 76 of MiFID II Delegated Regulation).  (MAIN CLAUSE).  FIRMS SHOULD PROVIDE ACCESS TO ALL RELEVANT DATABASE AND RECORDS. (HOW MIURA COMPLIES) ZeroCRisk Automatically ensures that Compliance have access to all the relevant information by tracking all Data Management and Document Archiving, including Log-ing information</li>
<li>Detailed Guideline 5_47a (REG RULE) In order to have a permanent overview of the areas of the firm where sensitive or relevant information might arise, the compliance officer should have access to all relevant information systems within the firm as well as any internal or external audit reports or other reporting to senior management or the supervisory function, if any.  (MAIN CLAUSE). THE COMPLIANCE OFFICER SHOULD HAVE ACCESS TO ALL RELEVANT INFORMATION SYSTEMS WITHIN THE FIRM AS WELL AS ANY INTERNAL OR EXTERNAL AUDIT REPORTS OR OTHER REPORTING TO SENIOR MANAGEMENT OR THE SUPERVISORY FUNCTION, IF ANY. (HOW MIURA COMPLIES) ZeroCRisk Automatically ensures that Compliance have access to all the relevant information by tracking all Supervisory Ops Processes including Audit and all applicable reports</li>
<li>Detailed Guideline 5_47b (REG RULE) Where relevant, the compliance officer should also be able to attend meetings of senior management or the supervisory function.  (MAIN CLAUSE).  THE COMPLIANCE OFFICER SHOULD ALSO BE ABLE TO ATTEND MEETINGS OF SENIOR MANAGEMENT OR THE SUPERVISORY FUNCTION. (HOW MIURA COMPLIES) ZeroCRisk Automatically ensures that Compliance is at all Senior Management Meetings and they are on each of the Committees</li>
<li>Detailed Guideline 5_47c (REG RULE) Where this right is not granted (which should remain exceptional) this should be documented and explained in writing.  (MAIN CLAUSE). THIS SHOULD BE DOCUMENTED AND EXPLAINED IN WRITING. (HOW MIURA COMPLIES) ZeroCRisk Automatically ensures that Compliance is at all Senior Management Meetings and they are on each of the Committees.
Compliances absence should be monitored</li>
<li>Detailed Guideline 5_47d (REG RULE) The compliance officer should have in-depth knowledge of the firms organisation, corporate culture and decision-making processes in order to be able to identify which meetings are important to attend. (MAIN CLAUSE).  THE COMPLIANCE OFFICER SHOULD HAVE IN-DEPTH KNOWLEDGE OF THE FIRMS ORGANISATION, CORPORATE CULTURE AND DECISION-MAKING PROCESSES. (HOW MIURA COMPLIES) ZeroCRisk Automatically ensures that Compliance is at all Senior Management Meetings and they are on each of the Committees</li>
<li>Detailed Guideline 5_48 (REG RULE) In particular, it is important that the firm puts in place necessary arrangements to ensure an effective exchange of information between the compliance function and other control functions (for example internal audit and risk management) as well as with any internal or external auditors. (MAIN CLAUSE). THE FIRM SHOULD PUT IN PLACE NECESSARY ARRANGEMENTS TO ENSURE AN EFFECTIVE EXCHANGE OF INFORMATION BETWEEN THE COMPLIANCE FUNCTION AND OTHER CONTROL FUNCTIONS. (HOW MIURA COMPLIES) ZeroCRisk Automatically ensures that Compliance is communicates with all other control functions as they are all present in the Audti, Risk and Compliance Committees, and all have access to the same transparent information at each meeting</li>
<li>Detailed Guideline 6_49 (REG RULE) Firms compliance staff shall have the necessary skills, knowledge and expertise to discharge their obligations pursuant to Articles 21(1)(d) of the MiFID II Delegated Regulation.  (MAIN CLAUSE).  FIRMS COMPLIANCE STAFF SHALL HAVE THE NECESSARY SKILLS, KNOWLEDGE AND EXPERTISE. (HOW MIURA COMPLIES) ZeroCRisk Automatically ensures a Specialist level of knowledge in MIFID II through the numbers modules it has to address every document, supported by sentence level Training through its training module</li>
<li>Detailed Guideline 6_49a (REG RULE) Furthermore, the compliance function shall have the necessary authority pursuant to Article 22(3)(a) of the MiFID II Delegated Regulation.  (MAIN CLAUSE).  THE COMPLIANCE FUNCTION SHALL HAVE THE NECESSARY AUTHORITY. (HOW MIURA COMPLIES) ZeroCRisk Automatically ensures a Specialist level of knowledge in MIFID II through the numbers modules it has to address every document, supported by sentence level Training through its training module</li>
<li>Detailed Guideline 6_49b (REG RULE) These requirements should in particular be taken into account by firms when appointing the compliance officer.  (MAIN CLAUSE).  FIRMS SHOULD TAKE THESE REQUIREMENTS INTO ACCOUNT WHEN APPOINTING THE COMPLIANCE OFFICER.

1. ENSURE THAT THE COMPLIANCE OFFICER HAS THE NECESSARY QUALIFICATIONS AND EXPERIENCE TO CARRY OUT THE ROLE EFFECTIVELY.
2. ENSURE THAT THE COMPLIANCE OFFICER HAS THE AUTHORITY TO CARRY OUT THEIR DUTIES AND RESPONSIBILITIES.
3. ENSURE THAT THE COMPLIANCE OFFICER HAS THE NECESSARY RESOURCES TO CARRY OUT THEIR DUTIES AND RESPONSIBILITIES.
4. ENSURE THAT THE COMPLIANCE OFFICER HAS THE NECESSARY INDEPENDENCE TO CARRY OUT THEIR DUTIES AND RESPONSIBILITIES.
5. ENSURE THAT THE COMPLIANCE OFFICER HAS THE NECESSARY SUPPORT FROM SENIOR MANAGEMENT.
6. ENSURE THAT THE COMPLIANCE OFFICER HAS THE NECESSARY ACCESS TO INFORMATION AND DATA TO CARRY OUT THEIR DUTIES AND RESPONSIBILITIES.
7. ENSURE THAT THE COMPLIANCE OFFICER HAS THE NECESSARY TRAINING AND DEVELOPMENT TO CARRY OUT THEIR DUTIES AND RESPONSIBILITIES.
8. ENSURE THAT THE COMPLIANCE OFFICER HAS THE NECESSARY REPORTING LINES TO CARRY OUT THEIR DUTIES AND RESPONSIBILITIES.
9. ENSURE THAT THE COMPLIANCE OFFICER HAS THE NECESSARY SYSTEMS AND PROCESSES IN PLACE TO CARRY OUT THEIR DUTIES AND RESPONSIBILITIES.
10. ENSURE THAT THE COMPLIANCE OFFICER HAS THE NECESSARY SUPPORT FROM THE BOARD OF DIRECTORS. (HOW MIURA COMPLIES) ZeroCRisk Automatically Ensures that the following process has been followed when appoitning a compliance officer as it will require management to sign off this checklist</li>
<li>Detailed Guideline 6_49c (REG RULE) Having regard to the function and tasks assigned to the compliance officer, he or she should demonstrate high professional ethical standards and personal integrity. (MAIN CLAUSE). THE COMPLIANCE OFFICER SHOULD DEMONSTRATE HIGH PROFESSIONAL ETHICAL STANDARDS AND PERSONAL INTEGRITY. (HOW MIURA COMPLIES) ZeroCRisk Automatically validates the Head of Compliance has sufficient professional experiences through validaton with the Requirements of the Certification Requirements</li>
<li>Detailed Guideline 6_50 (REG RULE) In order to ensure that the compliance function has the authority required for its duties, the senior management of the firm should support it in the exercise of these duties.  (MAIN CLAUSE). THE SENIOR MANAGEMENT OF THE FIRM SHOULD SUPPORT THE COMPLIANCE FUNCTION IN THE EXERCISE OF ITS DUTIES TO ENSURE THAT IT HAS THE AUTHORITY REQUIRED. (HOW MIURA COMPLIES) ZeroCRisk Automatically proves the that the Head of Compliance has the correct support for the exercise of its duties by validating the Terms of Reference</li>
<li>Detailed Guideline 6_50a (REG RULE) Authority implies possessing adequate expertise and relevant personal skills (such as, for instance, judgment), and may be enhanced by the firms compliance policy explicitly acknowledging the specific authority of the compliance function. (MAIN CLAUSE). AUTHORITY IMPLIES POSSESSING ADEQUATE EXPERTISE AND RELEVANT PERSONAL SKILLS (SUCH AS, FOR INSTANCE, JUDGMENT), AND MAY BE ENHANCED BY THE FIRMS COMPLIANCE POLICY EXPLICITLY ACKNOWLEDGING THE SPECIFIC AUTHORITY OF THE COMPLIANCE FUNCTION. (HOW MIURA COMPLIES) ZeroCRisk Automatically validates the Head of Compliance has sufficient professional experiences through validaton with the Requirements of the Certification Requirements</li>
<li>Detailed Guideline 6_51 (REG RULE)  Within the compliance function there should at least be knowledge of MiFID II and all related delegated and implementing acts, the national implementing laws and regulations as well as of all applicable standards, guidelines and other guidance issued by ESMA and competent authorities, as far as these are relevant for the performance of the compliance tasks. Compliance staff should be regularly trained in order to maintain their knowledge.  (MAIN CLAUSE).  COMPLIANCE STAFF SHOULD BE REGULARLY TRAINED IN ORDER TO MAINTAIN THEIR KNOWLEDGE. (HOW MIURA COMPLIES) ZeroCRisk Automatically ensures a Specialist level of knowledge in MIFID II through the numbers modules it has to address every document, supported by sentence level Training through its training module</li>
<li>Detailed Guideline 6_51a (REG RULE) The designated compliance officer should possess a higher level of expertise. (MAIN CLAUSE). THE DESIGNATED COMPLIANCE OFFICER SHOULD POSSESS A HIGHER LEVEL OF EXPERTISE. (HOW MIURA COMPLIES) ZeroCRisk Automatically ensures a Specialist level of knowledge in MIFID II through the numbers modules it has to address every document, supported by sentence level Training through its training module</li>
<li>Detailed Guideline 6_52 (REG RULE)  The compliance officer should have sufficiently broad knowledge and experience and a sufficiently high level of expertise so as to be able to assume responsibility for the compliance function as a whole and ensure that it is effective.  (MAIN CLAUSE).  THE COMPLIANCE OFFICER SHOULD HAVE SUFFICIENTLY BROAD KNOWLEDGE AND EXPERIENCE AND A SUFFICIENTLY HIGH LEVEL OF EXPERTISE. (HOW MIURA COMPLIES) ZeroCRisk Automatically validates the Head of Compliance has sufficient professional experiences through validaton with the Requirements of the Certification Requirements.
ZeroCRisk Automatically provides speciaist knowledge of the firm through its low level Ops training modules on all parts of the business
</li>
<li>Detailed Guideline 6_52a (REG RULE) In order to demonstrate the necessary level of knowledge and/or of experience, different options may be foreseen at national level in the Member State concerned.  (MAIN CLAUSE).  DIFFERENT OPTIONS MAY BE FORESEEN AT NATIONAL LEVEL IN THE MEMBER STATE CONCERNED. (HOW MIURA COMPLIES) ZeroCRisk Automatically validates the Head of Compliance has sufficient professional experiences through validaton with the Requirements of the Certification Requirements</li>
<li>Detailed Guideline 6_52b (REG RULE) For instance, some competent authorities license or approve the nominated compliance officer following an assessment of the qualifications of the compliance officer.  (MAIN CLAUSE).  SOME COMPETENT AUTHORITIES LICENSE OR APPROVE THE NOMINATED COMPLIANCE OFFICER. (HOW MIURA COMPLIES) ZeroCRisk Automatically validates the Head of Compliance has sufficient professional experiences through validaton with the Requirements of the Certification Requirements</li>
<li>Detailed Guideline 6_52c (REG RULE) This assessment may include an analysis of the compliance officers curriculum vitae, as well as an interview with the nominated person and/or an exam to be passed.  (MAIN CLAUSE).  THIS ASSESSMENT MAY INCLUDE AN ANALYSIS OF THE COMPLIANCE OFFICERS CURRICULUM VITAE, AN INTERVIEW WITH THE NOMINATED PERSON AND/OR AN EXAM TO BE PASSED. (HOW MIURA COMPLIES) ZeroCRisk Automatically validates the Head of Compliance has sufficient professional experiences through validaton with the Requirements of the Certification Requirements</li>
<li>Detailed Guideline 6_52d (REG RULE) This sort of process may help to strengthen the position of the compliance function within the firm and in relation to third parties.  (MAIN CLAUSE).  THIS SORT OF PROCESS MAY HELP TO STRENGTHEN THE POSITION OF THE COMPLIANCE FUNCTION. (HOW MIURA COMPLIES) ZeroCRisk Automatically validates the Head of Compliance has sufficient professional experiences through validaton with the Requirements of the Certification Requirements</li>
<li>Detailed Guideline 6_52e (REG RULE) Other regulatory approaches impose the responsibility for the assessment of the compliance officers qualification solely on the senior management of the firm.   (MAIN CLAUSE). OTHER REGULATORY APPROACHES IMPOSE THE RESPONSIBILITY FOR THE ASSESSMENT OF THE COMPLIANCE OFFICERS QUALIFICATION SOLELY ON THE SENIOR MANAGEMENT OF THE FIRM. (HOW MIURA COMPLIES) ZeroCRisk Automatically validates the Head of Compliance has sufficient professional experiences through validaton with the Requirements of the Certification Requirements</li>
<li>Detailed Guideline 6_52f (REG RULE) Senior management assesses the prospective compliance officers qualifications before appointment. (MAIN CLAUSE). SENIOR MANAGEMENT ASSESSES THE PROSPECTIVE COMPLIANCE OFFICERS QUALIFICATIONS BEFORE APPOINTMENT. (HOW MIURA COMPLIES) ZeroCRisk Automatically validates the Head of Compliance has sufficient professional experiences through validaton with the Requirements of the Certification Requirements.
It also records Senior Mgt approval</li>
<li>Detailed Guideline 6_52g (REG RULE) Whether the firm properly complies with the requirements in Article 21(1)(d) and paragraphs (a) and (b) of Article 22(3) is then assessed within the general review of the firms compliance with the relevant MiFID II requirements. (MAIN CLAUSE). THE FIRMS COMPLIANCE WITH THE RELEVANT MIFID II REQUIREMENTS IS THEN ASSESSED. (HOW MIURA COMPLIES) ZeroCRisk Automatically validates MIFID II Requirements through its Real-time Validation of all the MIFID II Rules</li>
<li>Detailed Guideline 6_53 (REG RULE) The compliance officer should demonstrate sufficient professional experience as it is necessary to be able to assess the compliance risks and conflicts of interest inherent in the firms business activities.  (MAIN CLAUSE).  THE COMPLIANCE OFFICER SHOULD DEMONSTRATE SUFFICIENT PROFESSIONAL EXPERIENCE. (HOW MIURA COMPLIES) ZeroCRisk Automatically validates the Head of Compliance has sufficient professional experiences through validaton with the Requirements of the Certification Requirements</li>
<li>Detailed Guideline 6_53a (REG RULE) The required professional experience may have, amongst others, been acquired in operational positions, in other control functions or in regulatory functions.  (MAIN CLAUSE).  THE REQUIRED PROFESSIONAL EXPERIENCE MAY HAVE BEEN ACQUIRED IN OPERATIONAL POSITIONS, IN OTHER CONTROL FUNCTIONS OR IN REGULATORY FUNCTIONS. (HOW MIURA COMPLIES) ZeroCRisk Automatically provides speciaist knowledge of the firm through its low level Ops training modules on all parts of the business</li>
<li>Detailed Guideline 6_53b (REG RULE) In some jurisdictions, the professional experience is only taken into consideration if it has been acquired during a minimum period of time and provided it is not outdated. (MAIN CLAUSE). IN SOME JURISDICTIONS, THE PROFESSIONAL EXPERIENCE IS ONLY TAKEN INTO CONSIDERATION IF CERTAIN CONDITIONS ARE MET. (HOW MIURA COMPLIES) ZeroCRisk Automatically provides speciaist knowledge of the firm through its low level Ops training modules on all parts of the business</li>
<li>Detailed Guideline 6_54 (REG RULE) The compliance officer should have specific knowledge of the different activities provided by the firm. The relevant expertise required may differ from one firm to another, as the nature of the main compliance risks that firms face could differ.  (MAIN CLAUSE).  THE COMPLIANCE OFFICER SHOULD HAVE SPECIFIC KNOWLEDGE OF THE DIFFERENT ACTIVITIES PROVIDED BY THE FIRM. (HOW MIURA COMPLIES) ZeroCRisk Automatically provides speciaist knowledge of the firm through its low level Ops training modules on all parts of the business</li>
<li>Detailed Guideline 6_54a (REG RULE) A newly employed compliance officer may therefore need additional specialised knowledge focused on the specific business model of the firm even if the person has previously been the compliance officer for another firm. (MAIN CLAUSE). THE NEWLY EMPLOYED COMPLIANCE OFFICER MAY THEREFORE NEED ADDITIONAL SPECIALISED KNOWLEDGE FOCUSED ON THE SPECIFIC BUSINESS MODEL OF THE FIRM. (HOW MIURA COMPLIES) ZeroCRisk Automatically provides speciaist knowledge of the firm through its low level Ops training modules on all parts of the business</li>
<li>Detailed Guideline 7_55 (REG RULE) The first subparagraph of Article 22(2) of the MiFID II Delegated Regulation requires firms to ensure that the compliance function performs its tasks and responsibilities on a permanent basis.  (MAIN CLAUSE).  THE COMPLIANCE FUNCTION MUST PERFORM ITS TASKS AND RESPONSIBILITIES ON A PERMANENT BASIS. (HOW MIURA COMPLIES) ZeroCRisk Automatically automatically ensure the responsibilities of the Compliance Function are fulfilled on a permanen basis through scheduleing and tracking the completion of all compliance tasks and providing step by step training instruction to complete them</li>
<li>Detailed Guideline 7_55a (REG RULE) Firms should therefore establish adequate arrangements for ensuring that the responsibilities of the compliance officer are fulfilled when the compliance officer is absent, and adequate arrangements to ensure that the responsibilities of the compliance function are performed on an ongoing basis.  (MAIN CLAUSE).  FIRMS SHOULD THEREFORE ESTABLISH ADEQUATE ARRANGEMENTS FOR ENSURING THAT THE RESPONSIBILITIES OF THE COMPLIANCE OFFICER ARE FULFILLED WHEN THE COMPLIANCE OFFICER IS ABSENT, AND ADEQUATE ARRANGEMENTS TO ENSURE THAT THE RESPONSIBILITIES OF THE COMPLIANCE FUNCTION ARE PERFORMED ON AN ONGOING BASIS. (HOW MIURA COMPLIES) ZeroCRisk Automatically automatically ensure the responsibilities of the Compliance Function are fulfilled during any absence of the Compliance officer through scheduleing and tracking the completion of all compliance tasks and providing step by step training instruction for other employees to complete them</li>
<li>Detailed Guideline 7_55b (REG RULE) The arrangements for ensuring that the responsibilities of the compliance officer are fulfilled when the compliance officer is absent, and adequate arrangements to ensure that the responsibilities of the compliance function are performed on an ongoing basis should be in writing. (MAIN CLAUSE).  THE ARRANGEMENTS FOR ENSURING THAT THE RESPONSIBILITIES OF THE COMPLIANCE OFFICER ARE FULFILLED WHEN THE COMPLIANCE OFFICER IS ABSENT, AND ADEQUATE ARRANGEMENTS TO ENSURE THAT THE RESPONSIBILITIES OF THE COMPLIANCE FUNCTION ARE PERFORMED ON AN ONGOING BASIS SHOULD BE IN WRITING. (HOW MIURA COMPLIES) ZeroCRisk Automatically automatically ensure the responsibilities of the Compliance Function are fulfilled during any absence of the Compliance officer through scheduleing and tracking the completion of all compliance tasks and providing step by step training instruction for other employees to complete them</li>
<li>Detailed Guideline 7_56 (REG RULE) The firm should ensure, for example through internal procedures and stand-in arrangements, that the responsibilities of the compliance function are fulfilled adequately during any absence of the compliance officer. (MAIN CLAUSE). THE FIRM SHOULD ENSURE THAT THE RESPONSIBILITIES OF THE COMPLIANCE FUNCTION ARE FULFILLED ADEQUATELY DURING ANY ABSENCE OF THE COMPLIANCE OFFICER. (HOW MIURA COMPLIES) ZeroCRisk Automatically automatically ensure the responsibilities of the Compliance Function are fulfilled during any absence of the Compliance officer through scheduleing and tracking the completion of all compliance tasks and providing step by step training instruction for other employees to complete them</li>
<li>Detailed Guideline 7_57 (REG RULE) The responsibilities and competences as well as the authority of the compliance function should be set out in a compliance policy or other general policies or internal rules that take account of the scope and nature of the firms investment services and activities.  (MAIN CLAUSE). THE COMPLIANCE FUNCTIONS RESPONSIBILITIES, COMPETENCES, AND AUTHORITY SHOULD BE SET OUT IN A COMPLIANCE POLICY OR OTHER GENERAL POLICIES OR INTERNAL RULES. (HOW MIURA COMPLIES) ZeroCRisk Automatically sets out the Compliance Functions Responsibilities as they are the list of Operational processes underneath the Compliance Section</li>
<li>Detailed Guideline 7_57a (REG RULE) This should include information on the monitoring programme and the reporting duties of the compliance function as well as information on the compliance functions risk-based approach to monitoring activities.  (MAIN CLAUSE). THE COMPLIANCE FUNCTION SHOULD PROVIDE INFORMATION ON ITS MONITORING PROGRAMME, REPORTING DUTIES, AND RISK-BASED APPROACH TO MONITORING ACTIVITIES. (HOW MIURA COMPLIES) ZeroCRisk Automatically provides information on its monitoring programme, reporting duties and Risk-based approach as all this information is available on-line in Real-time</li>
<li>Detailed Guideline 7_57b (REG RULE) Relevant amendments to the applicable requirements should be reflected promptly by adapting these policies/rules. (MAIN CLAUSE).  RELEVANT AMENDMENTS SHOULD BE REFLECTED PROMPTLY. (HOW MIURA COMPLIES) ZeroCRisk Automatically caters for ammendments to the Regulation or to the business by the adding new Reg Processes or Ops Processes to the Framework</li>
<li>Detailed Guideline 7_58 (REG RULE) The compliance function should perform its activities on a permanent basis and not only in specific circumstances.  (MAIN CLAUSE).  THE COMPLIANCE FUNCTION SHOULD PERFORM ITS ACTIVITIES ON A PERMANENT BASIS. (HOW MIURA COMPLIES) ZeroCRisk Automatically monitors all Ops Processes and Reg Processes  as per its internal annual compliance monitoring Schedule</li>
<li>Detailed Guideline 7_58_1 (REG RULE) This entails regular monitoring on the basis of a monitoring schedule.  (MAIN CLAUSE). THIS ENTAILS REGULAR MONITORING ON THE BASIS OF A MONITORING SCHEDULE.  (HOW MIURA COMPLIES) ZeroCRisk Automatically monitors all Ops Processes and Reg Processes  as per its internal annual compliance monitoring Schedule</li>
<li>Detailed Guideline 7_58a (REG RULE) The monitoring activities should regularly cover all key areas of the investment services and activities provided by the firm, taking into account the compliance risk associated with the business areas.  (MAIN CLAUSE). THE MONITORING ACTIVITIES SHOULD REGULARLY COVER ALL KEY AREAS OF THE INVESTMENT SERVICES AND ACTIVITIES PROVIDED BY THE FIRM. (HOW MIURA COMPLIES) ZeroCRisk Automatically monitors all activities by having a complete library of all the firms Operational processes, and all the Reg Processes associated with those Operational Processes</li>
<li>Detailed Guideline 7_58b (REG RULE) The compliance function should be able to respond rapidly to unforeseen events, thereby changing the focus of its activities within a short timeframe if necessary. (MAIN CLAUSE). THE COMPLIANCE FUNCTION SHOULD BE ABLE TO RESPOND RAPIDLY TO UNFORESEEN EVENTS. (HOW MIURA COMPLIES) ZeroCRisk Automatically Enables compliance to respond rapidly to unforseen events by providing a real-time compliance framework, so issues can be spotted quickly, having low level training to help with issue diagnosis and also real-time validation to help with fixing issues.</li>
<li>Detailed Guideline 8_59 (REG RULE) Firms should ensure that the compliance function holds a position in their organisational structure that ensures that the compliance officer and other compliance staff act independently when performing their tasks. (MAIN CLAUSE). FIRMS SHOULD ENSURE THAT THE COMPLIANCE FUNCTION HOLDS A POSITION IN THEIR ORGANISATIONAL STRUCTURE. (HOW MIURA COMPLIES) ZeroCRisk Automatically confirms the Compliance Function holds a position in their Org Structure by having all its ops processes under the Compliance Function and ensuring the Compliance Function is well represented in Governance meetings.</li>
<li>Detailed Guideline 8_60 (REG RULE) While senior management is responsible for establishing an appropriate compliance organisation and for monitoring the effectiveness of the organisation that has been implemented, the tasks performed by the compliance function should be carried out independently from senior management and other units of the firm. (MAIN CLAUSE).  THE TASKS PERFORMED BY THE COMPLIANCE FUNCTION SHOULD BE CARRIED OUT INDEPENDENTLY FROM SENIOR MANAGEMENT AND OTHER UNITS OF THE FIRM. (HOW MIURA COMPLIES) ZeroCRisk Automatically confirms the Compliance Function holds ain independent position in their Org Structure by having all its ops processes under the Compliance Function and ensuring the Compliance Function is well represented in Governance meetings.</li>
<li>Detailed Guideline 8_60a (REG RULE)  In particular, the firms organisation should ensure that other business units may not issue instructions or otherwise influence compliance staff and their activities and an appropriate escalation process by the compliance function to senior management should be implemented. (MAIN CLAUSE). THE FIRMS ORGANISATION SHOULD ENSURE THAT OTHER BUSINESS UNITS MAY NOT ISSUE INSTRUCTIONS OR OTHERWISE INFLUENCE COMPLIANCE STAFF AND THEIR ACTIVITIES AND AN APPROPRIATE ESCALATION PROCESS BY THE COMPLIANCE FUNCTION TO SENIOR MANAGEMENT SHOULD BE IMPLEMENTED. (HOW MIURA COMPLIES) ZeroCRisk Automatically ensures the Compliance Function holds an independent position in their Org Structure and is not influenced by the business by having all its ops processes under the Compliance Function and ensuring the Compliance Function is well represented in Governance meetings where joint decisions are made.</li>
<li>Detailed Guideline 8_61 (REG RULE) Where senior management deviates from important recommendations or assessments issued by the compliance function, the compliance officer should document this accordingly and present it in the compliance reports. (MAIN CLAUSE). THE COMPLIANCE OFFICER SHOULD DOCUMENT THIS ACCORDINGLY AND PRESENT IT IN THE COMPLIANCE REPORTS. (HOW MIURA COMPLIES) The Compliance Report contains information on the deviation by senior management from important rcommendations from Compliance</li>
<li>Detailed Guideline 9_62 (REG RULE) Firms should decide which measures, including organisational measures and the level of resources, are best suited to ensuring the effectiveness of the compliance function in the firms particular circumstances. (MAIN CLAUSE).  FIRMS SHOULD DECIDE WHICH MEASURES ARE BEST SUITED TO ENSURING THE EFFECTIVENESS OF THE COMPLIANCE FUNCTION IN THE FIRMS PARTICULAR CIRCUMSTANCES. (HOW MIURA COMPLIES) ZeroCRisk Automatically ng such a comprehensive framework which can be run by few people so that the proportionality exemption in some cases does not need to be used</li>
<li>Detailed Guideline 9_63a (REG RULE) In deciding whether the requirements under points (d) and (e) of Article 22(3) of the MiFID II Delegated Regulation are proportionate and whether their compliance function continues to be effective, firms should take at least the following criteria into account: (a) the types of investment services, activities and ancillary services and other business activities provided by the firm (including those not related to investment services, activities and ancillary services); (MAIN CLAUSE).  FIRMS SHOULD TAKE AT LEAST THE FOLLOWING CRITERIA INTO ACCOUNT (HOW MIURA COMPLIES) ZeroCRisk Automatically supports the use of the proportionality exemption by providing such a comprehensive framework which can be run by few people so that the proportionality exemption in some cases does not need to be used</li>
<li>Detailed Guideline 9_63b (REG RULE) In deciding whether the requirements under points (d) and (e) of Article 22(3) of the MiFID II Delegated Regulation are proportionate and whether their compliance function continues to be effective, firms should take at least the following criteria into account: (b)  the interaction between the investment services and activities and ancillary services and other business activities carried out by the firm; (MAIN CLAUSE).  FIRMS SHOULD TAKE AT LEAST THE FOLLOWING CRITERIA INTO ACCOUNT (HOW MIURA COMPLIES) ZeroCRisk Automatically supports the use of the proportionality exemption by providing such a comprehensive framework which can be run by few people so that the proportionality exemption in some cases does not need to be used</li>
<li>Detailed Guideline 9_63c (REG RULE) In deciding whether the requirements under points (d) and (e) of Article 22(3) of the MiFID II Delegated Regulation are proportionate and whether their compliance function continues to be effective, firms should take at least the following criteria into account: (c)  the scope and volume of the investment services, activities and ancillary services carried out (absolute and relative to other business activities), balance sheet total and income of the firm from commissions and fees and other income in the context of the provision of investment services, activities and ancillary services; (MAIN CLAUSE). FIRMS SHOULD TAKE AT LEAST THE FOLLOWING CRITERIA INTO ACCOUNT WHEN DECIDING WHETHER THE REQUIREMENTS UNDER POINTS (D) AND (E) OF ARTICLE 22(3) OF THE MIFID II DELEGATED REGULATION ARE PROPORTIONATE AND WHETHER THEIR COMPLIANCE FUNCTION CONTINUES TO BE EFFECTIVE. (HOW MIURA COMPLIES) ZeroCRisk Automatically supports the use of the proportionality exemption by providing such a comprehensive framework which can be run by few people so that the proportionality exemption in some cases does not need to be used</li>
<li>Detailed Guideline 9_63d (REG RULE) In deciding whether the requirements under points (d) and (e) of Article 22(3) of the MiFID II Delegated Regulation are proportionate and whether their compliance function continues to be effective, firms should take at least the following criteria into account: (d)  the types of financial instruments offered to clients; (MAIN CLAUSE).  FIRMS SHOULD TAKE AT LEAST THE FOLLOWING CRITERIA INTO ACCOUNT (HOW MIURA COMPLIES) ZeroCRisk Automatically supports the use of the proportionality exemption by providing such a comprehensive framework which can be run by few people so that the proportionality exemption in some cases does not need to be used</li>
<li>Detailed Guideline 9_63e (REG RULE) In deciding whether the requirements under points (d) and (e) of Article 22(3) of the MiFID II Delegated Regulation are proportionate and whether their compliance function continues to be effective, firms should take at least the following criteria into account: (e) the types of clients targeted by the firm (professional, retail, eligible counterparties); (MAIN CLAUSE).  FIRMS SHOULD TAKE AT LEAST THE FOLLOWING CRITERIA INTO ACCOUNT (HOW MIURA COMPLIES) ZeroCRisk Automatically supports the use of the proportionality exemption by providing such a comprehensive framework which can be run by few people so that the proportionality exemption in some cases does not need to be used</li>
<li>Detailed Guideline 9_63f (REG RULE) In deciding whether the requirements under points (d) and (e) of Article 22(3) of the MiFID II Delegated Regulation are proportionate and whether their compliance function continues to be effective, firms should take at least the following criteria into account: (f)  staff headcount; (MAIN CLAUSE).  FIRMS SHOULD TAKE AT LEAST THE FOLLOWING CRITERIA INTO ACCOUNT STAFF HEADCOUNT. (HOW MIURA COMPLIES) ZeroCRisk Automatically supports the use of the proportionality exemption by providing such a comprehensive framework which can be run by few people so that the proportionality exemption in some cases does not need to be used</li>
<li>Detailed Guideline 9_63g (REG RULE) In deciding whether the requirements under points (d) and (e) of Article 22(3) of the MiFID II Delegated Regulation are proportionate and whether their compliance function continues to be effective, firms should take at least the following criteria into account: (g)  whether the firm is part of a group within the meaning of point 11 of Article 2 of CRD; (MAIN CLAUSE).  FIRMS SHOULD TAKE AT LEAST THE FOLLOWING CRITERIA INTO ACCOUNT. (HOW MIURA COMPLIES) ZeroCRisk Automatically supports the use of the proportionality exemption by providing such a comprehensive framework which can be run by few people so that the proportionality exemption in some cases does not need to be used</li>
<li>Detailed Guideline 9_63h (REG RULE) In deciding whether the requirements under points (d) and (e) of Article 22(3) of the MiFID II Delegated Regulation are proportionate and whether their compliance function continues to be effective, firms should take at least the following criteria into account: (h)  services provided through a commercial network, such as tied agents, or branches; (MAIN CLAUSE).  FIRMS SHOULD TAKE AT LEAST THE FOLLOWING CRITERIA INTO ACCOUNT WHEN DECIDING WHETHER THE REQUIREMENTS UNDER POINTS (D) AND (E) OF ARTICLE 22(3) OF THE MIFID II DELEGATED REGULATION ARE PROPORTIONATE AND WHETHER THEIR COMPLIANCE FUNCTION CONTINUES TO BE EFFECTIVE. (HOW MIURA COMPLIES) ZeroCRisk Automatically supports the use of the proportionality exemption by providing such a comprehensive framework which can be run by few people so that the proportionality exemption in some cases does not need to be used</li>
<li>Detailed Guideline 9_63i (REG RULE) In deciding whether the requirements under points (d) and (e) of Article 22(3) of the MiFID II Delegated Regulation are proportionate and whether their compliance function continues to be effective, firms should take at least the following criteria into account: (i)  cross-border activities provided by the firm; and (MAIN CLAUSE).  FIRMS SHOULD TAKE AT LEAST THE FOLLOWING CRITERIA INTO ACCOUNT (HOW MIURA COMPLIES) ZeroCRisk Automatically supports the use of the proportionality exemption by providing such a comprehensive framework which can be run by few people so that the proportionality exemption in some cases does not need to be used</li>
<li>Detailed Guideline 9_63j (REG RULE) In deciding whether the requirements under points (d) and (e) of Article 22(3) of the MiFID II Delegated Regulation are proportionate and whether their compliance function continues to be effective, firms should take at least the following criteria into account: (j)  organisation and sophistication of the IT systems. (MAIN CLAUSE).  FIRMS SHOULD TAKE AT LEAST THE FOLLOWING CRITERIA INTO ACCOUNT (HOW MIURA COMPLIES) ZeroCRisk Automatically supports the use of the proportionality exemption by providing such a comprehensive framework which can be run by few people so that the proportionality exemption in some cases does not need to be used</li>
<li>Detailed Guideline 9_64 (REG RULE) Competent authorities may also find these criteria useful in determining which types of firms may benefit from the proportionality exemption under Article 22(4) of the MiFID II Delegated Regulation. (MAIN CLAUSE).  COMPETENT AUTHORITIES MAY ALSO FIND THESE CRITERIA USEFUL. (HOW MIURA COMPLIES) ZeroCRisk Automatically supports the use of the proportionality exemption by providing such a comprehensive framework which can be run by few people so that the proportionality exemption in some cases does not need to be used</li>
<li>Detailed Guideline 9_65 (REG RULE)  A firm may fall, for example, under the proportionality exemption if the performance of the necessary compliance tasks does not require a full-time position due to the nature, scale and complexity of the firms business, and the nature and range of the investment services, activities and ancillary services offered. (MAIN CLAUSE).  A FIRM MAY FALL, FOR EXAMPLE, UNDER THE PROPORTIONALITY EXEMPTION. (HOW MIURA COMPLIES) ZeroCRisk Automatically supports the use of the proportionality exemption by providing such a comprehensive framework which can be run by few people so that the proportionality exemption in some cases does not need to be used</li>
<li>Detailed Guideline 9_66 (REG RULE) While a compliance officer must always be appointed, it may be disproportionate for some firms, depending on the circumstances (for instance, small firms with limited and non-complex activities and/or limited volumes) to appoint a separate compliance officer that does not perform any other function.  (MAIN CLAUSE). A COMPLIANCE OFFICER MUST ALWAYS BE APPOINTED. (HOW MIURA COMPLIES) ZeroCRisk Automatically supports the assignment of a compliance officer by providing such a comprehensive framework which can be run by few people supported by low level training, both in the Compliance Role but also in business Roles</li>
<li>Detailed Guideline 9_66a (REG RULE) Where a firm makes use of the exemption (which should be assessed and justified on a case-by-case basis), conflicts of interest between the tasks performed by the relevant persons should be minimised as much as possible. (MAIN CLAUSE).  CONFLICTS OF INTEREST BETWEEN THE TASKS PERFORMED BY THE RELEVANT PERSONS SHOULD BE MINIMISED AS MUCH AS POSSIBLE. (HOW MIURA COMPLIES) ZeroCRisk Automatically supports the use of the proportionality exemption by providing such a comprehensive framework which can be run by few people so that the proportionality exemption in some cases does not need to be used</li>
<li>Detailed Guideline 9_67 (REG RULE) A firm that does not need to comply with all the requirements set out in Article 22(3) of the MiFID II Delegated Regulation under the proportionality principle may combine the legal and compliance function. However, a firm with more complex activities or greater size should avoid such combination if it could undermine the compliance functions effectiveness. (MAIN CLAUSE). A FIRM MAY COMBINE THE LEGAL AND COMPLIANCE FUNCTION. (HOW MIURA COMPLIES) ZeroCRisk Automatically supports the combination of the Legal and Compliance Function but simply assigning the responsibilities of one or other of the Business Functions to the joint owner</li>
<li>Detailed Guideline 9_68 (REG RULE)  Where a firm makes use of the proportionality exemption, it should record how this is justified, so that the competent authority is able to assess this. (MAIN CLAUSE). A FIRM SHOULD RECORD HOW THE PROPORTIONALITY EXEMPTION IS JUSTIFIED. (HOW MIURA COMPLIES) ZeroCRisk Automatically supports the use of the proportionality exemption by providing such a comprehensive framework which can be run by few people so that the proportionality exemption in some cases does not need to be used</li>
<li>Detailed Guideline 10_69 (REG RULE)  A firm should favour an organisation where control functions are properly separated.  (MAIN CLAUSE).  A FIRM SHOULD FAVOUR AN ORGANISATION WHERE CONTROL FUNCTIONS ARE PROPERLY SEPARATED. (HOW MIURA COMPLIES) ZeroCRisk Automatically ensures the control functions are properly separated by virtue of separate dashboards, transparent sign-offs and transparent information</li>
<li>Detailed Guideline 10_69_1 (REG RULE) The combination of the compliance function with other control functions may be acceptable if this does not compromise the effectiveness and independence of the compliance function.  (MAIN CLAUSE). THE COMBINATION OF THE COMPLIANCE FUNCTION WITH OTHER CONTROL FUNCTIONS MAY BE ACCEPTABLE IF THIS DOES NOT COMPROMISE THE EFFECTIVENESS AND INDEPENDENCE OF THE COMPLIANCE FUNCTION.  (HOW MIURA COMPLIES) ZeroCRisk Automatically ensures the control functions are properly separated by virtue of separate dashboards, transparent sign-offs and transparent information</li>
<li>Detailed Guideline 10_69_2 (REG RULE) Any such combination should be documented, including the reasons for the combination so that competent authorities are able to assess whether the combination of functions is appropriate in the circumstances.  (MAIN CLAUSE). ANY SUCH COMBINATION SHOULD BE DOCUMENTED SO THAT COMPETENT AUTHORITIES ARE ABLE TO ASSESS WHETHER THE COMBINATION OF FUNCTIONS IS APPROPRIATE IN THE CIRCUMSTANCES.  (HOW MIURA COMPLIES) ZeroCRisk Automatically ensures the control functions are properly separated by virtue of separate dashboards, transparent sign-offs and transparent information.
The Combination of functions would simply involve assigning ops processes to the relevant new owner. There would be no compromise.</li>
<li>Detailed Guideline 10_69_3 (REG RULE) However, where an internal audit function has been established and is maintained within the investment firm in accordance with Article 24 of the MiFID II Delegated Regulation, such function may not be combined with other control functions such as the compliance function, in accordance with Article 24. (MAIN CLAUSE). HOWEVER, WHERE AN INTERNAL AUDIT FUNCTION HAS BEEN ESTABLISHED AND IS MAINTAINED WITHIN THE INVESTMENT FIRM IN ACCORDANCE WITH ARTICLE 24 OF THE MIFID II DELEGATED REGULATION, SUCH FUNCTION MAY NOT BE COMBINED WITH OTHER CONTROL FUNCTIONS SUCH AS THE COMPLIANCE FUNCTION, IN ACCORDANCE WITH ARTICLE 24. (HOW MIURA COMPLIES) ZeroCRisk Automatically ensures the control functions are properly separated by virtue of separate dashboards, transparent sign-offs and transparent information</li>
<li>Detailed Guideline 10_70 (REG RULE)  Compliance staff should generally not be involved in the activities they monitor. However, a combination of the compliance function with other control units at the same level (such as money laundering prevention) may be acceptable if this does not generate conflicts of interests or compromise the effectiveness of the compliance function. (MAIN CLAUSE). A COMBINATION OF THE COMPLIANCE FUNCTION WITH OTHER CONTROL UNITS AT THE SAME LEVEL MAY BE ACCEPTABLE. (HOW MIURA COMPLIES) ZeroCRisk Automatically ensures the control functions are properly separated by virtue of separate dashboards, transparent sign-offs and transparent information</li>
<li>Detailed Guideline 10_71 (REG RULE)  Whether staff from other control functions also perform compliance tasks should also be a relevant consideration in the determination of the relevant number of staff necessary for the compliance function. (MAIN CLAUSE).  STAFF FROM OTHER CONTROL FUNCTIONS SHOULD ALSO BE A RELEVANT CONSIDERATION IN THE DETERMINATION OF THE RELEVANT NUMBER OF STAFF NECESSARY FOR THE COMPLIANCE FUNCTION. (HOW MIURA COMPLIES) ZeroCRisk Automatically helps ensure Compliance is adequately resourced by Providing a Real-time Sentence-level Ops and Reg Compliance Framework that can be run by very few staff</li>
<li>Detailed Guideline 10_72 (REG RULE)  Whether or not the compliance function is combined with other control functions, the compliance function should coordinate its activities with the second-level control activities performed by other units in charge of other control functions. (MAIN CLAUSE). THE COMPLIANCE FUNCTION SHOULD COORDINATE ITS ACTIVITIES WITH THE SECOND-LEVEL CONTROL ACTIVITIES PERFORMED BY OTHER UNITS IN CHARGE OF OTHER CONTROL FUNCTIONS. (HOW MIURA COMPLIES) ZeroCRisk Automatically coordinates the combination of Control Functions by hosting first level and second level control dashboards for each Ops and Reg process </li>
<li>Detailed Guideline 10_73 (REG RULE)  Where the compliance officer is not appointed as the single officer referred to in article 7 of the MiFID II Delegated Directive, both the officer referred to in Article 7 of the MiFID II Delegated Directive and the compliance officer should act independently, and the compliance officer should not supervise and/or issue any instruction to the single officer referred to in Article 7 of the MiFID II Delegated Directive. (MAIN CLAUSE). THE COMPLIANCE OFFICER SHOULD NOT SUPERVISE AND/OR ISSUE ANY INSTRUCTION TO THE SINGLE OFFICER REFERRED TO IN ARTICLE 7 OF THE MIFID II DELEGATED DIRECTIVE. (HOW MIURA COMPLIES) ZeroCRisk Automatically ensures there is no supersvision issues between Compliance Officers as the Organisation is broken up into Ops processes and allocated to owners with Transparenct oversight, reducing the ability of influence.</li>
<li>Detailed Guideline 10_74 (REG RULE)  Where the compliance function is combined with other control functions as specified in paragraph 69 or where it is also responsible for other tasks (for example anti-money laundering), the firm should ensure that it allocates enough resources for MiFID- compliance at all times. (MAIN CLAUSE). THE FIRM SHOULD ENSURE THAT IT ALLOCATES ENOUGH RESOURCES FOR MIFID- COMPLIANCE AT ALL TIMES. (HOW MIURA COMPLIES) ZeroCRisk Automatically Addresses Review of their staffing requirements through their Ops process ADEQUATE RESOURCING CHECK</li>
<li>Detailed Guideline 11_75 (REG RULE)  Firms should ensure that all requirements applicable to the compliance function continued to be fulfilled where all or part of the compliance function is outsourced. (MAIN CLAUSE).  FIRMS SHOULD ENSURE THAT ALL REQUIREMENTS APPLICABLE TO THE COMPLIANCE FUNCTION CONTINUED TO BE FULFILLED. (HOW MIURA COMPLIES) ZeroCRisk Automatically ensures the integrity of the Compliance Framework by breaking the business into a series of Ops Processes and Reg Processes and then assigning them owners and creating a Real-time Compliance Framework around them. The framework will remain the same, regardless of who performs the tasks (ie if they were outsourced)</li>
<li>Detailed Guideline 11_76 (REG RULE)  The outsourcing requirements for critical or important functions set out in Articles 16(5) of MiFID II and 31 of the MiFID II Commission Delegated Regulation apply in full to the outsourcing of the compliance function. (MAIN CLAUSE). THE OUTSOURCING REQUIREMENTS FOR CRITICAL OR IMPORTANT FUNCTIONS SET OUT IN ARTICLES 16(5) OF MIFID II AND 31 OF THE MIFID II COMMISSION DELEGATED REGULATION APPLY IN FULL TO THE OUTSOURCING OF THE COMPLIANCE FUNCTION. (HOW MIURA COMPLIES) ZeroCRisk Automatically ensures the integrity of the Compliance Framework by breaking the business into a series of Ops Processes and Reg Processes and then assigning them owners and creating a Real-time Compliance Framework around them. The framework will remain the same, regardless of who performs the tasks (ie if they were outsourced) The same applies for Critical Functions.</li>
<li>Detailed Guideline 11_77 (REG RULE) Firms can only outsource tasks, but not responsibilities: firms wishing to engage in outsourcing remain fully responsible for the tasks that are outsourced. In other words, as set out in Article 31(2)(e) of the MiFID II Delegated Regulation, the ability to control outsourced tasks and manage the risks associated with the outsourcing must always be retained by the firm initiating the outsourcing. (MAIN CLAUSE). FIRMS CAN ONLY OUTSOURCE TASKS, BUT NOT RESPONSIBILITIES. (HOW MIURA COMPLIES) ZeroCRisk Automatically ensures the integrity of the Compliance Framework by breaking the business into a series of Ops Processes and Reg Processes and then assigning them owners and creating a Real-time Compliance Framework around them. The framework will remain the same, regardless of who performs the tasks (ie if they were outsourced)</li>
<li>Detailed Guideline 11_78 (REG RULE) The firm should perform a due diligence assessment before choosing a service provider in order to ensure that the requirements set out in Articles 22 and 31 of the MiFID II Delegated Regulation are met.  (MAIN CLAUSE).  THE FIRM SHOULD PERFORM A DUE DILIGENCE ASSESSMENT. (HOW MIURA COMPLIES) ZeroCRisk Automatically ensures that the correct due diligence has been carried out through the Ops Process COMPLETE OUTOURCING DUE DILIGENCE ASSESSMENT</li>
<li>Detailed Guideline 11_78a (REG RULE) The firm should ensure that the service provider has the necessary authority, resources, expertise and access to all relevant information in order to perform the outsourced compliance function tasks effectively.  (MAIN CLAUSE).  THE FIRM SHOULD ENSURE THAT THE SERVICE PROVIDER HAS THE NECESSARY AUTHORITY, RESOURCES, EXPERTISE AND ACCESS TO ALL RELEVANT INFORMATION. (HOW MIURA COMPLIES) ZeroCRisk Automatically ensures that the correct due diligence has been carried out through the Ops Process COMPLETE OUTOURCING DUE DILIGENCE ASSESSMENT</li>
<li>Detailed Guideline 11_78b (REG RULE) The extent of the due diligence assessment should be dependent on the nature, scale, complexity and risk of the compliance tasks and processes that are outsourced. (MAIN CLAUSE).  THE EXTENT OF THE DUE DILIGENCE ASSESSMENT SHOULD BE DEPENDENT ON THE NATURE, SCALE, COMPLEXITY AND RISK OF THE COMPLIANCE TASKS AND PROCESSES THAT ARE OUTSOURCED. (HOW MIURA COMPLIES) ZeroCRisk Automatically ensures that the correct due diligence has been carried out through the Ops Process COMPLETE OUTOURCING DUE DILIGENCE ASSESSMENT</li>
<li>Detailed Guideline 11_79 (REG RULE) Firms should also ensure that when, outsourced partially or fully, the compliance function remains permanent in nature, namely that the service provider should be able to perform the function on an ongoing basis and not only in specific circumstances. (MAIN CLAUSE).  FIRMS SHOULD ALSO ENSURE THAT THE COMPLIANCE FUNCTION REMAINS PERMANENT IN NATURE. (HOW MIURA COMPLIES) ZeroCRisk Automatically ensures the integrity of the Compliance Framework by breaking the business into a series of Ops Processes and Reg Processes and then assigning them owners and creating a Real-time Compliance Framework around them. The framework will remain the same, regardless of who performs the tasks (ie if they were outsourced)</li>
<li>Detailed Guideline 11_80 (REG RULE) Firms should monitor whether the service provider performs its duties adequately, which includes monitoring the quality and the quantity of the services provided.  (MAIN CLAUSE).  SENIOR MANAGEMENT IS RESPONSIBLE FOR SUPERVISING AND MONITORING THE OUTSOURCED TASKS ON AN ONGOING BASIS. (HOW MIURA COMPLIES) ZeroCRisk Automatically ensures Senior Managerment can monitor the Compliance Framework in Realt-time through its real-time view of the whole organisation</li>
<li>Detailed Guideline 11_80_1 (REG RULE) Senior management is responsible for supervising and monitoring the outsourced tasks on an ongoing basis and should have the necessary resources and expertise to be able to fulfil this responsibility.  (MAIN CLAUSE). SENIOR MANAGEMENT IS RESPONSIBLE FOR SUPERVISING AND MONITORING THE OUTSOURCED TASKS ON AN ONGOING BASIS AND SHOULD HAVE THE NECESSARY RESOURCES AND EXPERTISE TO BE ABLE TO FULFIL THIS RESPONSIBILITY.  (HOW MIURA COMPLIES) ZeroCRisk Automatically ensures Senior Managerment can monitor the Compliance Framework in Realt-time through its real-time view of the whole organisation</li>
<li>Detailed Guideline 11_80a (REG RULE) Senior management may appoint a specific person to supervise and monitor the outsourced function on their behalf. (MAIN CLAUSE). MANAGEMENT MAY APPOINT A PERSON TO SUPERVISE AND MONITOR THE OUTSOURCED FUNCTION. (HOW MIURA COMPLIES) ZeroCRisk Automatically ensures Senior Managerment can monitor the Compliance Framework in Realt-time through its real-time view of the whole organisation. It just needs to give access to a different user</li>
<li>Detailed Guideline 11_81 (REG RULE) Outsourcing of the compliance functions tasks within a group does not lead to a lower level of responsibility for the senior management of the individual firms within the group.  (MAIN CLAUSE).  A CENTRALISED GROUP COMPLIANCE FUNCTION MAY, IN SOME CASES, PROVIDE THE COMPLIANCE OFFICER WITH BETTER ACCESS TO INFORMATION, AND LEAD TO GREATER EFFICIENCY OF THE FUNCTION. (HOW MIURA COMPLIES) ZeroCRisk Automatically ensures Senior Managerment can monitor the Compliance Framework in Realt-time through its real-time view of the whole organisation</li>
<li>Detailed Guideline 11_81_1 (REG RULE) However, a centralised group compliance function may, in some cases, provide the compliance officer with better access to information, and lead to greater efficiency of the function, especially if the entities share the same premises. (MAIN CLAUSE). HOWEVER, A CENTRALISED GROUP COMPLIANCE FUNCTION MAY, IN SOME CASES, PROVIDE THE COMPLIANCE OFFICER WITH BETTER ACCESS TO INFORMATION, AND LEAD TO GREATER EFFICIENCY OF THE FUNCTION, ESPECIALLY IF THE ENTITIES SHARE THE SAME PREMISES. (HOW MIURA COMPLIES) ZeroCRisk Automatically ensures Senior Managerment can monitor the Compliance Framework in Realt-time through its real-time view of the whole organisation.
Multiple legal entities can be tracked in Real-time</li>
<li>Detailed Guideline 11_82 (REG RULE) In compliance with the proportionality principle set out in Article 22(4) of the MiFID II Delegated Regulation, if a firm, due to the nature, scale and complexity of its business and the nature and range of investment services and activities, does not comply with Article 22(3)(d) of the MiFID II Delegated Regulation (namely its compliance staff is also involved in the performance of services or activities they monitor), then it may consider that the outsourcing of the compliance functions tasks is likely to be an appropriate approach to take. (MAIN CLAUSE). THE FIRM MAY CONSIDER OUTSOURCING THE COMPLIANCE FUNCTIONS TASKS TO BE AN APPROPRIATE APPROACH. (HOW MIURA COMPLIES) ZeroCRisk Automatically ensures the integrity of the Compliance Framework by breaking the business into a series of Ops Processes and Reg Processes and then assigning them owners and creating a Real-time Compliance Framework around them. The framework will remain the same, regardless of who performs the tasks (ie if they were outsourced)</li>
<li>Detailed Guideline 11_83 (REG RULE)  In all cases, outsourcing of the compliance function should not (i) undermine its quality and independence, (ii) create undue additional operational risks, (iii) impair the activities of internal controls or (iv) impair the ability of the firm and the relevant competent authority to supervise compliance with the applicable requirements. (MAIN CLAUSE).  OUTSOURCING OF THE COMPLIANCE FUNCTION SHOULD NOT UNDERMINE ITS QUALITY AND INDEPENDENCE, CREATE UNDUE ADDITIONAL OPERATIONAL RISKS, IMPAIR THE ACTIVITIES OF INTERNAL CONTROLS OR IMPAIR THE ABILITY OF THE FIRM AND THE RELEVANT COMPETENT AUTHORITY TO SUPERVISE COMPLIANCE WITH THE APPLICABLE REQUIREMENTS. (HOW MIURA COMPLIES) ZeroCRisk Automatically ensures the integrity of the Compliance Framework by breaking the business into a series of Ops Processes and Reg Processes and then assigning them owners and creating a Real-time Compliance Framework around them. The framework will remain the same, regardless of who performs the tasks (ie if they were outsourced)</li>
<li>Detailed Guideline 11_84 (REG RULE) Outsourcing of all or part of the tasks of the compliance function to non-EU entities may potentially make oversight and supervision of the compliance function more difficult and should therefore be subject to a closer monitoring. (MAIN CLAUSE)  OUTSOURCING OF ALL OR PART OF THE TASKS OF THE COMPLIANCE FUNCTION TO NON-EU ENTITIES SHOULD BE SUBJECT TO A CLOSER MONITORING. (HOW MIURA COMPLIES) ZeroCRisk Automatically ensures the integrity of the Compliance Framework by breaking the business into a series of Ops Processes and Reg Processes and then assigning them owners and creating a Real-time Compliance Framework around them. The framework will remain the same, regardless of who performs the tasks (ie if they were outsourced)
Multiple legal entities can be tracked in Real-time</li>
<li>Detailed Guideline 11_85 (REG RULE) In case the outsourcing arrangement related to the compliance function is terminated, firms should ensure the continuity of the compliance function either by transferring it back to the firm or outsourcing it to another provider. (MAIN CLAUSE).  FIRMS SHOULD ENSURE THE CONTINUITY OF THE COMPLIANCE FUNCTION. (HOW MIURA COMPLIES) ZeroCRisk Automatically ensures the integrity of the Compliance Framework by breaking the business into a series of Ops Processes and Reg Processes and then assigning them owners and creating a Real-time Compliance Framework around them. The framework will remain the same, regardless of who performs the tasks (ie if they were outsourced)</li>
<li>Detailed Guideline 12_86 (REG RULE) Competent authorities should review how firms plan to meet, implement and maintain the applicable compliance function requirements.  (MAIN CLAUSE).  COMPETENT AUTHORITIES SHOULD REVIEW HOW FIRMS PLAN TO MEET, IMPLEMENT AND MAINTAIN THE APPLICABLE COMPLIANCE FUNCTION REQUIREMENTS. (HOW MIURA COMPLIES) ZeroCRisk Automates the Review of the firms plan to maintain the firms Compliance Function Requirements by having a transparent miura model of
1. how we automate Regulatory Compliance and Reg Change Management
2. How we automate the 12 Compliance Roles
3. How our model allows Regulation and business to speak the same language
4. Our 1st, 2nd, 3rd and 4th level oversight process</li>"
"<li>Detailed Guideline 12_86_1 (REG RULE) This should apply in the context of the authorisation process, as well as, following a risk-based approach, in the course of on- going supervision. (MAIN CLAUSE). THIS SHOULD APPLY IN THE CONTEXT OF THE AUTHORISATION PROCESS, AS WELL AS, FOLLOWING A RISK-BASED APPROACH, IN THE COURSE OF ON- GOING SUPERVISION. (HOW MIURA COMPLIES) ZeroCRisk Automates the Review of the firms plan to maintain the firms Compliance Function Requirements by having a transparent miura model of
1. how we automate Regulatory Compliance and Reg Change Management
2. How we automate the 12 Compliance Roles
3. How our model allows Regulation and business to speak the same language
4. Our 1st, 2nd, 3rd and 4th level oversight process</li>
<li>Detailed Guideline 12_87 (REG RULE)  Article 7 of MiFID II states that a competent authority shall not grant authorisation (to a firm) unless and until such time as it is fully satisfied that the applicant complies with all requirements under the provisions adopted pursuant to this Directive (MiFID II). (MAIN CLAUSE).  A COMPETENT AUTHORITY SHALL NOT GRANT AUTHORISATION TO A FIRM UNLESS AND UNTIL IT IS FULLY SATISFIED THAT THE APPLICANT COMPLIES WITH ALL REQUIREMENTS UNDER THE PROVISIONS ADOPTED PURSUANT TO MIFID II. (HOW MIURA COMPLIES) ZeroCRisk Automates Firms Authorisation by proving the Firm Complies in real-time with every Rule of the MIFID Regulation, or whatever Regulatory Documents the firm needs to comply with</li>
<li>Detailed Guideline 12_87a (REG RULE) Accordingly, the competent authority should assess whether a firms compliance function is adequately resourced and organised and whether adequate reporting lines have been established. It should require, as a condition for authorisation, that any necessary amendments to the compliance function are made as a condition for authorisation. (MAIN CLAUSE). THE COMPETENT AUTHORITY SHOULD ASSESS WHETHER A FIRMS COMPLIANCE FUNCTION IS ADEQUATELY RESOURCED AND ORGANISED AND WHETHER ADEQUATE REPORTING LINES HAVE BEEN ESTABLISHED, AND REQUIRE ANY NECESSARY AMENDMENTS TO THE COMPLIANCE FUNCTION AS A CONDITION FOR AUTHORISATION. (HOW MIURA COMPLIES) ZeroCRisk Automatically helps ensure Compliance is adequately resourced by Providing a Real-time Sentence-level Ops and Reg Compliance Framework that can be run by very few staff</li>
<li>Detailed Guideline 12_88 (REG RULE) Additionally, as part of the ongoing supervisory process, a competent authority should - following a risk-based approach - assess whether the measures implemented by the firm for the compliance function are adequate, and whether the compliance function fulfils its responsibilities appropriately. (MAIN CLAUSE).  A COMPETENT AUTHORITY SHOULD ASSESS WHETHER THE MEASURES IMPLEMENTED BY THE FIRM FOR THE COMPLIANCE FUNCTION ARE ADEQUATE, AND WHETHER THE COMPLIANCE FUNCTION FULFILS ITS RESPONSIBILITIES APPROPRIATELY. (HOW MIURA COMPLIES) ZeroCRisk Automatically helps Competent Authorities assess wheether the measures implemented are adequate by allowing them to log in remotely and review the Real-time Sentence-level Ops and Reg Compliance Framework</li>
<li>Detailed Guideline 12_88a (REG RULE)  Firms are responsible for determining whether amendments to the resources and organisation of the compliance function are required due to changes in the business model of the firm.  (MAIN CLAUSE).  FIRMS ARE RESPONSIBLE FOR DETERMINING WHETHER AMENDMENTS TO THE RESOURCES AND ORGANISATION OF THE COMPLIANCE FUNCTION ARE REQUIRED. (HOW MIURA COMPLIES) ZeroCRisk Automatically Addresses Review of their staffing requirements through their Ops process ADEQUATE RESOURCING CHECK</li>
<li>Detailed Guideline 12_88b (REG RULE) Competent authorities should also, as part of their ongoing supervision and following a risk-based approach, assess and monitor - where and if appropriate - whether such amendments are necessary and have been implemented.  (MAIN CLAUSE).  COMPETENT AUTHORITIES SHOULD ASSESS AND MONITOR, WHERE AND IF APPROPRIATE, WHETHER SUCH AMENDMENTS ARE NECESSARY AND HAVE BEEN IMPLEMENTED. (HOW MIURA COMPLIES) ZeroCRisk Automatically ensures Regulators can check whether ammendments have been implemented by Reviewing the ADEQUATE RESOUCING CHECK Report</li>
<li>Detailed Guideline 12_88c (REG RULE) The competent authority should provide a reasonable timeframe for the firm to make amendments. However, firms amendments are not necessarily subject to approval by the competent authorities. (MAIN CLAUSE). FIRMS AMENDMENTS ARE NOT NECESSARILY SUBJECT TO APPROVAL BY THE COMPETENT AUTHORITIES. (HOW MIURA COMPLIES) ZeroCRisk Automatically ensures Regulators can check whether ammendments have been implemented by Reviewing the ADEQUATE RESOUCING CHECK Report</li>
<li>Detailed Guideline 12_89 (REG RULE) As mentioned under paragraph 52 above, some competent authorities license or approve the nominated compliance officer following an assessment of the qualifications of the compliance officer. (MAIN CLAUSE).  SOME COMPETENT AUTHORITIES LICENSE OR APPROVE THE NOMINATED COMPLIANCE OFFICER. (HOW MIURA COMPLIES) ZeroCRisk Automatically validates the Compliance Officer qualifications thorugh the CERTIFY EMPLOYEE Ops Process. This can be reviewed on-line by the Regulator.</li>
<li>Detailed Guideline 12_90 (REG RULE) Other regulatory approaches impose the responsibility for the assessment of the compliance officers qualification solely on the senior management of the firm.  (MAIN CLAUSE).  SOME MEMBER STATES REQUIRE FIRMS TO NOTIFY THE COMPETENT AUTHORITIES OF THE APPOINTMENT AND REPLACEMENT OF THE COMPLIANCE OFFICER. (HOW MIURA COMPLIES) ZeroCRisk Automatically validates the Compliance Officer qualifications thorugh the CERTIFY EMPLOYEE Ops Process. This can be reviewed on-line by the Regulator.</li>
<li>Detailed Guideline 12_91 (REG RULE) Some Member States require firms to notify the competent authorities of the appointment and replacement of the compliance officer. (MAIN CLAUSE). SOME MEMBER STATES REQUIRE FIRMS TO NOTIFY THE COMPETENT AUTHORITIES OF THE APPOINTMENT AND REPLACEMENT OF THE COMPLIANCE OFFICER. (HOW MIURA COMPLIES) ZeroCRisk Automatically notifies the Regulator and the Regulator can log in and Review the APPOINT A COMPLIANCE OFFICER Process</li>
<li>Detailed Guideline 12_90a (REG RULE)  In some jurisdictions, this notification must also be accompanied by a detailed statement on the grounds for the replacement.  (MAIN CLAUSE).  THIS NOTIFICATION MUST BE ACCOMPANIED BY A DETAILED STATEMENT ON THE GROUNDS FOR THE REPLACEMENT. (HOW MIURA COMPLIES) ZeroCRisk Automatically notifies the Regulator and the Regulator can log in and Review the APPOINT A COMPLIANCE OFFICER Process</li>
<li>Detailed Guideline 12_90b (REG RULE) This can help competent authorities gain insight into possible tensions between the compliance officer and senior management which could be an indication of deficiencies in the compliance functions independence. (MAIN CLAUSE). THIS CAN HELP COMPETENT AUTHORITIES GAIN INSIGHT INTO POSSIBLE TENSIONS BETWEEN THE COMPLIANCE OFFICER AND SENIOR MANAGEMENT. (HOW MIURA COMPLIES) ZeroCRisk Automatically notifies the Regulator and the Regulator can log in and Review the APPOINT A COMPLIANCE OFFICER Process</li>
<li>Detailed Guideline 12_91 (REG RULE) Some Member States require the compliance officer to fulfil an annual questionnaire in order to gather information on compliance of the firm.  (MAIN CLAUSE).  SOME MEMBER STATES REQUIRE THE COMPLIANCE OFFICER TO FULFIL AN ANNUAL QUESTIONNAIRE. (HOW MIURA COMPLIES) ZeroCRisk Automatically tracks compliance with the Completion of an annual Compliance Questionaire where applicable through our ANNUAL COMPLIANCE QUESTIONAIRE Ops Process which can be reviewed by the Regulator on-line</li>
<li>Detailed Guideline 12_91a (REG RULE) The questionnaire is an evaluation grid on how the firms business is intended to be conducted and monitored by the firm.  (MAIN CLAUSE). THE QUESTIONNAIRE IS AN EVALUATION GRID. (HOW MIURA COMPLIES) ZeroCRisk Automatically tracks compliance with the Completion of an annual Compliance Questionaire where applicable through our ANNUAL COMPLIANCE QUESTIONAIRE Ops Process which can be reviewed by the Regulator on-line</li>
<li>Detailed Guideline 12_91b (REG RULE) This evaluation grid includes questions related to all investment services the firm is authorised to perform.  (MAIN CLAUSE). THE EVALUATION GRID INCLUDES QUESTIONS RELATED TO ALL INVESTMENT SERVICES. (HOW MIURA COMPLIES) ZeroCRisk Automatically tracks compliance with the Completion of an annual Compliance Questionaire where applicable through our ANNUAL COMPLIANCE QUESTIONAIRE Ops Process which can be reviewed by the Regulator on-line</li>
<li>Detailed Guideline 12_91c (REG RULE) Some questions also relate to the monitoring and control of the activity to be performed by the firm. (e.g. how the control functions are organized, who they report to, whether some functions are outsourced, etc., as well as a number of open fields asking the firm to describe any relevant changes and developments compared to the previous years).  (MAIN CLAUSE). A NUMBER OF QUESTIONS RELATE TO THE MONITORING AND CONTROL OF THE ACTIVITY TO BE PERFORMED BY THE FIRM. (HOW MIURA COMPLIES) ZeroCRisk Automatically tracks compliance with the Completion of an annual Compliance Questionaire where applicable through our ANNUAL COMPLIANCE QUESTIONAIRE Ops Process which can be reviewed by the Regulator on-line</li>
<li>Detailed Guideline 12_91d (REG RULE) The answers could be validated by the firms senior management and then sent to the competent authority.  (MAIN CLAUSE).  THE ANSWERS COULD BE VALIDATED BY THE FIRMS SENIOR MANAGEMENT. (HOW MIURA COMPLIES) ZeroCRisk Automatically tracks compliance with the Completion of an annual Compliance Questionaire where applicable through our ANNUAL COMPLIANCE QUESTIONAIRE Ops Process which can be reviewed by the Regulator on-line</li>
<li>Detailed Guideline 12_91e (REG RULE) This questionnaire could be a standardised, machine-readable report to enable data extraction, incorporate qualitative indicators and flags anomalies in a resource-efficient manner.  (MAIN CLAUSE). THIS QUESTIONNAIRE COULD ENABLE DATA EXTRACTION, INCORPORATE QUALITATIVE INDICATORS AND FLAG ANOMALIES IN A RESOURCE-EFFICIENT MANNER. (HOW MIURA COMPLIES) ZeroCRisk Automatically tracks compliance with the Completion of an annual Compliance Questionaire where applicable through our ANNUAL COMPLIANCE QUESTIONAIRE Ops Process which can be reviewed by the Regulator on-line</li>
<li>Detailed Guideline 12_91f (REG RULE) The questionnaire could be used by competent authorities to monitor the firm and to require the firm to adopt an action plan to remediate to the issues as well as to determine the priorities of the supervision of the competent authority and to calibrate its risk-based approach. (MAIN CLAUSE).  THE QUESTIONNAIRE COULD BE USED BY COMPETENT AUTHORITIES TO MONITOR THE FIRM. (HOW MIURA COMPLIES) ZeroCRisk Automatically tracks compliance with the Completion of an annual Compliance Questionaire where applicable through our ANNUAL COMPLIANCE QUESTIONAIRE Ops Process which can be reviewed by the Regulator on-line</li>
<li>Detailed Guideline 12_92 (REG RULE)  The above practices could be helpful to other competent authorities. (MAIN CLAUSE).  THE ABOVE PRACTICES COULD BE HELPFUL TO OTHER COMPETENT AUTHORITIES. (HOW MIURA COMPLIES) ZeroCRisk Automatically tracks compliance with the Completion of an annual Compliance Questionaire where applicable through our ANNUAL COMPLIANCE QUESTIONAIRE Ops Process which can be reviewed by the Regulator on-line</li>
                  </ol>
                </span>
              </li>
            </ul>
          </SidePanelCardItem>
          <SidePanelCardItem height={500} marginBottom={0} canScroll={true}>
            <span>Validation logs <DialogLogs data={props.validationLogsData || { results: [] }}/></span>
            <ul>
              {validationLogs !== undefined &&
                validationLogs.results !== undefined &&
                validationLogs.results.map((logItem, i) => {
                  const logStatus = logItem.compliance_document_validation_status;
                  return (
                    <li key={`log-${i}`}>
                      <span>
                        <span className="title">
                          <span
                            className={`log-status 
                            ${logStatus === 'Failed' ? 'failed' : 'passed'}
                          `}
                          >
                            {logStatus}
                          </span>
                          <span className="log-status-date">
                            {Moment(logItem.created_on).format('MMM Do YYYY, h:mm a')}
                          </span>
                        </span>
                        <br />
                        <span className="log-status-content">
                          {logItem.compliance_document_validation_info}
                        </span>
                      </span>
                    </li>
                  );
                })}
            </ul>
          </SidePanelCardItem>
        </div>
      </div>

      <div className="document-footer-container">
        <div className="footer-title">Risk Rules</div>
        <div className="footer-content">
          <ProcessTable
            microRegulatoryProcessItems={lifecycleProcesses}
            triggerReloadContent={() => props.triggerReloadContent()}
            microProcessId={props.microRegulatoryProcessData.id}
            ruleDocType="Risk"
          />
        </div>
      </div>
    </div>
  );
};

Risk.defaultProps = {};

export default Risk;