import { AUTH_LOGOUT, AUTH_REFRESH_TOKEN, PUBLIC_ENDPOINTS } from 'config/endpoints';

import axios from 'axios';
import { injectStringParams } from 'utils/string';
import { rootStore } from 'stores';

const { REACT_APP_API_BASE_URL } = process.env;

const httpClient = axios.create({
  baseURL: REACT_APP_API_BASE_URL,
  timeout: 30 * 1000, // 30 sec
});


httpClient.interceptors.request.use(  async config => {
  if (!PUBLIC_ENDPOINTS.includes(config.url) && rootStore.user.access_token) {
    config.headers.Authorization = `Bearer ${rootStore.user.access_token}`;
  }
  if (config.requestParams) {
    config.url = injectStringParams(config.url, config.requestParams);
  }
  if(config?.data?.domain) {
    config.baseURL = config.data.domain;
    return config;
  }
  if(config?.domain) {
    config.baseURL = config.domain;
    return config;
  }
  if (!config.baseURL) {
     config.baseURL = REACT_APP_API_BASE_URL;
     return config;
  }
  config.baseURL = window.localStorage.getItem('baseURL');
  return config;
  },
  error => Promise.reject(error),
);

httpClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error && error.response && error?.response?.status === 401) {
      const excludedRoute = [AUTH_REFRESH_TOKEN, AUTH_LOGOUT].includes(error.config.url);
      if (!excludedRoute && !error.config.reprocessed) {
        error.config.reprocessed = true;
        return rootStore.user
          .refreshToken(rootStore.user.refresh_token)
          .then(({ data }) => {
            error.config.headers.Authorization = `Bearer ${data.access_token}`;
            return httpClient.request(error.config);
          })
          .catch(() => {
            return rootStore.user.removeAuthInfo();
          });
      } else {
        rootStore.user.removeAuthInfo();
      }
    }
    return Promise.reject(error);
  },
);

export default httpClient;
