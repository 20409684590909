import 'assets/styles/compliance.scss';

import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useStore } from 'hooks';
import * as config from "../../config/constants";
import ReportGraph from "../../shared/ReportGraph/index";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Grid from "@material-ui/core/Grid";
import {Button} from "@material-ui/core";

const Report = (props) => {
  const [businessProcessesData, setBusinessProcessesData] = useState();
  const [businessProcessesNext, setBusinessProcessesNext] = useState('');
  const [businessProcessesCount, setBusinessProcessesCount] = useState(0);
  const [businessProcessesDisplayCount, setBusinessProcessesDisplayCount] = useState(0);
  const [docRegulationUnderControlCount, setDocRegulationUnderControlCount] = useState();
  const [logMonitoringData, setLogMonitoringData] = useState();
  const [businessProcessesLoading, setBusinessProcessesLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const store = useStore();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const numRecordPage = config.pageSize;
  const { customProp } = props;
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [selectedDateEnd, setSelectedDateEnd] = React.useState(new Date());
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleDateChangeEnd = (date) => {
    setSelectedDateEnd(date);
  };
  const handleSearch = () => {
    getDocRegulationPercentageByRange();
  };
  const getDocRegulationPercentage = async (regulation_document_id) => {
    try {
      const { data } = await store.api.getRegulationPercentage(regulation_document_id);
      return data;
    } catch (error) {
      if (error.response && error?.response?.status === 403) {
        enqueueSnackbar('You do not have permission to view this data', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('An error occurred, please contact support', {
          variant: 'error',
        });
      }
    }
  };
  const formatDate = (date) => {
    const d = new Date(date);
    let day = '' + d.getDate(),
        month = '' + (d.getMonth() + 1),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [day, month, year].join('-');
  }

  const getMonitoringLogs = async (regulation_document_id, startDateString, endDateString) => {
    try {

      const { data } = await store.api.getMonitoringLogs(regulation_document_id,startDateString, endDateString);

      return data;
    } catch (error) {
      if (error.response && error?.response?.status === 403) {
        enqueueSnackbar('You do not have permission to view this data', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('An error occurred, please contact support', {
          variant: 'error',
        });
      }
    }
  };

  const getDocRegulationPercentageByRange = async () => {
    try {
      var dict = {};
      const startDateString = formatDate(selectedDate);
      const endDateString = formatDate(selectedDateEnd);
      const promises = businessProcessesData.map(async (regulation_document, i) => {
         dict[regulation_document.id] =  await getMonitoringLogs(regulation_document.id, startDateString, endDateString);
      });
      await Promise.all(promises);
      setLogMonitoringData(dict);
    } catch (error) {
      if (error.response && error?.response?.status === 403) {
        enqueueSnackbar('You do not have permission to view this data', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('An error occurred, please contact support', {
          variant: 'error',
        });
      }
    }
  };

  const getBusinessProcesses = async () => {
    try {
      setIsLoading(true);
      const { data } = await store.api.getDocumentRegulations(customProp);
      setBusinessProcessesData(data);
      setBusinessProcessesNext(data.next);
      setBusinessProcessesCount(data.count);
      data.count > numRecordPage
        ? setBusinessProcessesDisplayCount(numRecordPage)
        : setBusinessProcessesDisplayCount(data.count);

      var monitoringData ={};
      var dict ={};
      const today = new Date();
      const threeMonthsAgo = new Date(today.setMonth(today.getMonth() - 3));
      threeMonthsAgo.setDate(1);
      setSelectedDate(threeMonthsAgo);
      const startDateString = formatDate(threeMonthsAgo);
      const endDateString = formatDate(selectedDateEnd);
      const monitoringLogs = data.map(async (regulation_document, i) => {
         monitoringData[regulation_document.id] =  await getMonitoringLogs(regulation_document.id,startDateString, endDateString);
         dict[regulation_document.id] =  await getDocRegulationPercentage(regulation_document.id);
      });
      await Promise.all(monitoringLogs);
      setLogMonitoringData(monitoringData);
      setDocRegulationUnderControlCount(dict);
    } catch (error) {
      if (error.response && error?.response?.status === 403) {
        enqueueSnackbar('You do not have permission to view this data', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('An error occurred, please contact support', {
          variant: 'error',
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getNextBusinessProcesses = async () => {
    try {
      setBusinessProcessesLoading(true);
      const { data } = await store.api.getNextDataWithURL(businessProcessesNext);
      let addNewDomains = businessProcessesData;
      setBusinessProcessesNext(data.next);
      setBusinessProcessesData(undefined);
      const arrayLength = data.results.length;
      data.results.map((process, i) => {
        addNewDomains.push(process);
        if (arrayLength === i + 1) {
          setBusinessProcessesData(addNewDomains);
          setBusinessProcessesLoading(false);
          setBusinessProcessesDisplayCount(businessProcessesDisplayCount + arrayLength);
        }
      });
    } catch (error) {
      setBusinessProcessesLoading(false);
      if (error.response && error?.response?.status === 403) {
        enqueueSnackbar('You do not have permission to view this data', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('An error occurred, please contact support', {
          variant: 'error',
        });
      }
    } finally {
      setBusinessProcessesLoading(false);
    }
  };

  function LoadingMask() {
      return (
          <div style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.5)'
          }}>
              <p style={{ color: 'white' }}>Loading...</p>
          </div>
      );
  };

  useEffect(() => {
    getBusinessProcesses();
  }, [props.location.pathname]);

  const navigateToBusinessProcess = (processId) => {
    const path = '/micro-regulatory-list?regulation=' + processId;
    history.push(path);
  };

  return (
    <section className={'compliance-container'}>
      <div className="back-nav" onClick={() => history.push('/companies-dashboard')}>
        Home
      </div>

      <div className="header-icon"></div>
      <h4>{customProp === 'regulation' ? '08 Real-time Regulation Control Report': '07 Real-time Ops Controls Report' } </h4>
      <div className="button-search">
         <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container justifyContent="flex-start">
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            id="date-picker-inline"
            label="Start Date"
            value={selectedDate}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
          <div>&nbsp; &nbsp;</div>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            id="date-picker-inline"
            label="End Date"
            value={selectedDateEnd}
            onChange={handleDateChangeEnd}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
          <div>&nbsp; &nbsp;</div>
           <Button variant="contained" color="primary" onClick={handleSearch}>
            Search
          </Button>
        </Grid>
      </MuiPickersUtilsProvider>
      </div>
      <ul className="categories-list">
        <li key="count" style={{ backgroundColor: '#f4f5f7' }}>
          <span>
            Displaying {businessProcessesDisplayCount} of {businessProcessesCount} Regulation
            Documents
          </span>
        </li>

        {businessProcessesData !== undefined &&  docRegulationUnderControlCount !== undefined &&
          businessProcessesData.map((process, i) => {
            const processId = process.id;
            const applicable = docRegulationUnderControlCount[process.id] ? docRegulationUnderControlCount[process.id].applicable: 0;
            const validation_passed = docRegulationUnderControlCount[process.id] ? docRegulationUnderControlCount[process.id].validation_passed: 0;
            const total = docRegulationUnderControlCount[process.id] ? docRegulationUnderControlCount[process.id].total: 0;
            return (
              <li key={i} onClick={() => navigateToBusinessProcess(processId)}>
                <span>{processId}</span>
                <span className="title-spacing"> <span style={{fontSize:'15px'}}>{process.name}</span></span>
                <div className="summary">
                  Summary:
                  <span> % Processes Validated: <span className="number">{(validation_passed * 100/applicable).toFixed(2)}%</span></span>
                  <span className="total">Applicable Compliance Processes : <span className="number">{applicable}</span></span>
                  <span className="total-passed">Total Compliance Processes :  <span className="number">{total}</span></span>
                </div>
                <div className="graph">
                  {isLoading  ? (
                        <LoadingMask />
                      ) : (
                         <ReportGraph data={logMonitoringData} index={i} selectedDate={selectedDate}/>
                      )}
                 </div>
              </li>
            );
          })}
        {businessProcessesCount > 10 && (
          <li className="get-next" key="next" onClick={() => getNextBusinessProcesses()}>
            <span>{businessProcessesLoading ? 'Loading...' :  'Load ' + numRecordPage.toString() + ' more'}</span>
          </li>
        )}
      </ul>
    </section>
  );
};

Report.defaultProps = {};

export default Report;
