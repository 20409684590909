import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {MenuItem, Select,  CircularProgress, Backdrop} from "@material-ui/core";

export default function UserOwner ({owners, newOwner, handleOwnerChange, handleSave, loading = false}){
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <span>
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Change Owner</DialogTitle>
            <DialogContent>
              <Select value={newOwner} onChange={handleOwnerChange} displayEmpty fullWidth>
                <MenuItem value="" disabled>Select new owner</MenuItem>
                {owners.map((owner) => (
                  <MenuItem key={owner.username} value={owner.username}>
                    {owner.first_name} {owner.last_name}
                  </MenuItem>
                ))}
              </Select>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">Cancel</Button>
              <Button onClick={handleSave} color="primary">Save</Button>
            </DialogActions>
            {loading && (
            <Backdrop open={loading} style={{ zIndex: 1 }}>
                <div className="loading-icon-container">
                    <div className="loading-icon"></div>
                </div>
            </Backdrop>
          )}
        </Dialog>
    </span>
  );
}