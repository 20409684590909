import { Cell, Pie, PieChart } from 'recharts';
import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useStore } from 'hooks';

const COLORS = ['#16b67b', '#e0123c', '#ffc73a', '#B2B2B2'];

const STATUS_COLOR = {
  PASSED: '#16b67b',
  FAILED: '#e0123c',
  UNDERWAY: '#ffc73a',
  SCHEDULED: '#B2B2B2',
};

const Dashboard = (props) => {
  const [metricsData, setMetricsData] = useState();
  const [opsControlsMetricsData, setOpsControlsMetricsData] = useState();
  const [activeChartHoverTitle, setActiveChartHoverTitle] = useState();
  const [activeChartHoverTitleControl, setActiveChartHoverTitleControl] = useState();
  const [activeChartHoverMetric, setActiveChartHoverMetric] = useState();
  const [activeChartHoverMetricControl, setActiveChartHoverMetricControl] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const store = useStore();
  const { enqueueSnackbar } = useSnackbar();

  const [chartData, setChartData] = useState([
    { name: 'Passed', value: 25 },
    { name: 'Failed', value: 25 },
    { name: 'Scheduled', value: 25 },
    { name: 'Underway', value: 25 },
  ]);

  const [opsControlsChartData, setOpsControlsChartData] = useState([
    { name: 'In Control', value: 50 },
    { name: 'Not In Control', value: 50 },
  ]);

  const getMetrics = async () => {
    try {
      setIsLoading(true);
      const { data } = await store.api.getComplianceFramework('1');
      setMetricsData(data);
    } catch (error) {
      console.log('An error occured loading metrics ' + error);
      if (error.response && error?.response?.status === 403) {
        enqueueSnackbar('You do not have permission to view this data', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('An error occurred, please contact support', {
          variant: 'error',
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getOperationControlMetrics = async () => {
    try {
      const { data } = await store.api.getOperationalControlFramework('1');
      setOpsControlsMetricsData(data);
    } catch (error) {
      console.log('An error occured loading metrics ' + error);
      if (error.response && error?.response?.status === 403) {
        enqueueSnackbar('You do not have permission to view this data', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('An error occurred, please contact support', {
          variant: 'error',
        });
      }
    }
  };

  useEffect(() => {
    getMetrics();
    getOperationControlMetrics();
  }, []);

  useEffect(() => {
    metricsData &&
      setChartData([
        { name: 'Passed', value: Math.floor(metricsData.percentage_passed) },
        { name: 'Failed', value: Math.ceil(metricsData.percentage_failed) },
        {
          name: 'Underway',
          value: Math.floor(metricsData.percentage_underway),
        },
        {
          name: 'Scheduled',
          value: Math.floor(metricsData.percentage_scheduled),
        },
      ]);
    metricsData && setActiveChartHoverTitle('Passed');
    metricsData && setActiveChartHoverMetric(Math.floor(metricsData.percentage_passed));
  }, [metricsData]);

  useEffect(() => {
    opsControlsMetricsData &&
      setOpsControlsChartData([
        {
          name: 'In Control',
          value: Math.floor(opsControlsMetricsData.percentage_in_control),
        },
        {
          name: 'Not In Control',
          value: Math.floor(opsControlsMetricsData.percentage_not_in_control),
        },
      ]);
    opsControlsMetricsData && setActiveChartHoverTitleControl('In Control');
    opsControlsMetricsData &&
      setActiveChartHoverMetricControl(Math.floor(opsControlsMetricsData.percentage_in_control));
  }, [opsControlsMetricsData]);

  const chartHover = (e) => {
    setActiveChartHoverTitle(e.payload.name);
    setActiveChartHoverMetric(e.payload.value);
  };
  const chartHoverControl = (e) => {
    setActiveChartHoverTitleControl(e.payload.name);
    setActiveChartHoverMetricControl(e.payload.value);
  };


  return (
    <section id={`home-container`}>
      <>
        <h2>Regulation Change Lifecycle</h2>
        {metricsData && (
          <div className="table-container">
            <div className="card-holder-horizontal">
              <div className="card">
                <span>Horizon Scanning</span>
                <span className="metric">0</span>
                {/*<span className="metric">{metricsData.applicable_micro_regulatory_processes}</span>*/}
              </div>
              <div className="card">
                <span>Impact Assessment</span>
                <span className="metric">0</span>
              </div>
              <div className="card">
                <span>Reg Change Mgt</span>
                <span className="metric">0</span>
              </div>
              <div className="card">
                <span>Issues Raised</span>
                <span className="metric">{metricsData.total_rules_validation_failed}</span>
              </div>
            </div>
          </div>
        )}

        <h2>Regulatory Compliance Framework</h2>
        {metricsData && (
          <div className="table-container">
            <div className="card-holder">
              <div className="card total-rules">
                <span>Applicable Rules</span>
                <span className="metric">{metricsData.applicable_micro_regulatory_processes}</span>
              </div>
              <div className="card applicable-rules">
                <span>Rules In Control</span>
                <span className="metric">{metricsData.total_rules_validation_passed}</span>
              </div>
            </div>
            <div className="card-holder">
              <div className="card passed-rules">
                <span>Passed</span>
                <span className="metric">{Math.round(metricsData.percentage_passed)}%</span>
              </div>

              <div className="card failed-rules">
                <span>Failed</span>
                <span className="metric">{Math.round(metricsData.percentage_failed)}%</span>
              </div>
            </div>

            <div className="chart-holder">
              <div className="hover-info">
                <span
                  className={`hover-title ${
                    activeChartHoverTitle ? activeChartHoverTitle.toLowerCase() : ''
                  }`}
                >
                  {activeChartHoverTitle}
                </span>
                <br />
                <span
                  className={`hover-metric ${
                    activeChartHoverTitle ? activeChartHoverTitle.toLowerCase() : ''
                  }`}
                >
                  {activeChartHoverMetric}%
                </span>
              </div>

              <PieChart width={240} height={220}>
                <Pie
                  data={chartData}
                  dataKey="value"
                  innerRadius={60}
                  outerRadius={80}
                  cx={140}
                  fill="#8884d8"
                  paddingAngle={5}
                  onMouseEnter={(e) => chartHover(e)}
                >
                  {chartData.map((entry, index) => (
                    <Cell key={index} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
              </PieChart>

              <div className="chart-key">
                <div className="key-item">
                  <div className="box" style={{ backgroundColor: STATUS_COLOR.PASSED }}></div>
                  <span>Passed</span>
                </div>
                <div className="key-item">
                  <div className="box" style={{ backgroundColor: STATUS_COLOR.FAILED }}></div>
                  <span>Failed</span>
                </div>
                <div className="key-item">
                  <div className="box" style={{ backgroundColor: STATUS_COLOR.UNDERWAY }}></div>
                  <span>Underway</span>
                </div>
                <div className="key-item">
                  <div className="box" style={{ backgroundColor: STATUS_COLOR.SCHEDULED }}></div>
                  <span>Scheduled</span>
                </div>
              </div>
            </div>
          </div>
        )}

        <h2>Operational Controls Framework</h2>
        {opsControlsMetricsData && (
          <div className="table-container">
            <div className="card-holder">
              <div className="card total-rules">
                <span>Business Processes</span>
                <span className="metric">
                  {Math.round(opsControlsMetricsData.applicable_business_processes)}
                </span>
              </div>
              <div className="card applicable-rules">
                <span>In Control</span>
                <span className="metric">
                  {Math.round(opsControlsMetricsData.applicable_business_processes_in_control)}
                </span>
              </div>
            </div>
            <div className="card-holder">
              <div className="card passed-rules">
                <span>In Control</span>
                <span className="metric">
                  {Math.round(opsControlsMetricsData.percentage_in_control)}%
                </span>
              </div>

              <div className="card failed-rules">
                <span>Not In Control</span>
                <span className="metric">
                  {Math.round(opsControlsMetricsData.percentage_not_in_control)}%
                </span>
              </div>
            </div>

            <div className="chart-holder">
              <div className="hover-info">
                <span
                  className={`hover-title ${
                    activeChartHoverTitleControl ? activeChartHoverTitleControl.toLowerCase() : ''
                  }`}
                >
                  {activeChartHoverTitleControl}
                </span>
                <br />
                <span
                  className={`hover-metric ${
                    activeChartHoverTitleControl ? activeChartHoverTitleControl.toLowerCase() : ''
                  }`}
                >
                  {activeChartHoverMetricControl}%
                </span>
              </div>

              <PieChart width={240} height={220}>
                <Pie
                  data={opsControlsChartData}
                  dataKey="value"
                  innerRadius={60}
                  outerRadius={80}
                  cx={140}
                  fill="#8884d8"
                  paddingAngle={5}
                   onMouseEnter={(e) => chartHoverControl(e)}
                >
                  {opsControlsChartData.map((entry, index) => (
                    <Cell key={index} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
              </PieChart>

              <div className="chart-key">
                <div className="key-item">
                  <div className="box" style={{ backgroundColor: STATUS_COLOR.PASSED }}></div>
                  <span>In Control</span>
                </div>
                <div className="key-item">
                  <div className="box" style={{ backgroundColor: STATUS_COLOR.FAILED }}></div>
                  <span>Not In Control</span>
                </div>
              </div>
            </div>
          </div>
        )}

        <h2>Compliance Lifecycle Monitoring</h2>
        <h3 style={{ fontSize: '20px', marginTop: '20px' }}>Compliance Documents Validated</h3>
        {metricsData && (
          <div className="table-container">
            <div className="card-holder-horizontal">
              <div className="card">
                <span>Risk Assessment</span>
                <span className="metric">{Math.round(metricsData.risk_documents_validated)}</span>
              </div>
              <div className="card">
                <span>Policy</span>
                <span className="metric">{Math.round(metricsData.policy_documents_validated)}</span>
              </div>
              <div className="card">
                <span>Procedure</span>
                <span className="metric">
                  {Math.round(metricsData.procedure_documents_validated)}
                </span>
              </div>
              <div className="card">
                <span>Evidence</span>
                <span className="metric">
                  {Math.round(metricsData.evidence_documents_validated)}
                </span>
              </div>
              <div className="card">
                <span>Control</span>
                <span className="metric">
                  {Math.round(metricsData.control_documents_validated)}
                </span>
              </div>
              <div className="card">
                <span>Monitoring</span>
                <span className="metric">
                  {Math.round(metricsData.monitoring_documents_validated)}
                </span>
              </div>
            </div>
          </div>
        )}

        <h3 style={{ fontSize: '20px', marginTop: '20px' }}>
          Compliance Lifecycle Rules Validated
        </h3>
        {metricsData && (
          <div className="table-container" style={{ marginBottom: 120 }}>
            <div className="card-holder-horizontal">
              <div className="card">
                <span>Risk Assessment</span>
                <span className="metric">
                  {Math.round(metricsData.risk_rules_validation_passed)}
                </span>
              </div>
              <div className="card">
                <span>Policy</span>
                <span className="metric">
                  {Math.round(metricsData.policy_rules_validation_passed)}
                </span>
              </div>
              <div className="card">
                <span>Procedures</span>
                <span className="metric">
                  {Math.round(metricsData.procedure_rules_validation_passed)}
                </span>
              </div>
              <div className="card">
                <span>Evidence</span>
                <span className="metric">
                  {Math.round(metricsData.evidence_rules_validation_passed)}
                </span>
              </div>
              <div className="card">
                <span>Control</span>
                <span className="metric">
                  {Math.round(metricsData.control_rules_validation_passed)}
                </span>
              </div>
              <div className="card">
                <span>Monitoring</span>
                <span className="metric">
                  {Math.round(metricsData.monitoring_rules_validation_passed)}
                </span>
              </div>
            </div>
          </div>
        )}
      </>
    </section>
  );
};

export default Dashboard;
