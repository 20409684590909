import 'assets/styles/compliance.scss';

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useStore } from 'hooks';
import * as config from 'config/constants.js';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import { Button } from '@material-ui/core';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const MicroRegulatoryProcessesList = (props) => {
  const [microRegulatoryProcessNext, setMicroRegulatoryProcessNext] = useState('');
  const [microRegulatoryProcessData, setMicroRegulatoryProcessData] = useState();
  const [microRegulatoryProcessCount, setMicroRegulatoryProcessCount] = useState(0);
  const [microRegulatoryProcessDisplayCount, setMicroRegulatoryProcessDisplayCount] = useState(0);
  const [microRegulatoryProcessLoading, setMicroRegulatoryProcessLoading] = useState(false);
  const store = useStore();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const numRecordPage = config.pageSize;
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [selectedDateEnd, setSelectedDateEnd] = React.useState(new Date());


  const getMicroRegulatoryProcessList = async () => {
    try {
      const { data } = await store.api.getMicroProcessesReviewed();
      setMicroRegulatoryProcessData(data.results);
      setMicroRegulatoryProcessNext(data.next);
      setMicroRegulatoryProcessCount(data.count);
      data.count > numRecordPage
        ? setMicroRegulatoryProcessDisplayCount(numRecordPage)
        : setMicroRegulatoryProcessDisplayCount(data.count);
    } catch (error) {
      if (error.response && error?.response?.status === 403) {
        enqueueSnackbar('You do not have permission to view this data', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('An error occurred, please contact support', {
          variant: 'error',
        });
      }
    }
  };
  const formatDate = (date) => {
    const d = new Date(date);
    let day = '' + d.getDate(),
        month = '' + (d.getMonth() + 1), // Months are zero-based
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [day, month, year].join('-');
}


  const getMicroRegulatoryProcessReviewedBetween = async () => {
    try {
      const startDateString = formatDate(selectedDate);
      const endDateString = formatDate(selectedDateEnd);
      const { data } = await store.api.getMicroProcessesReviewedBetween(startDateString, endDateString);
      setMicroRegulatoryProcessData(data.results);
      setMicroRegulatoryProcessNext(data.next);
      setMicroRegulatoryProcessCount(data.count);
      data.count > numRecordPage
        ? setMicroRegulatoryProcessDisplayCount(numRecordPage)
        : setMicroRegulatoryProcessDisplayCount(data.count);
    } catch (error) {
      if (error.response && error?.response?.status === 403) {
        enqueueSnackbar('You do not have permission to view this data', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('An error occurred, please contact support', {
          variant: 'error',
        });
      }
    }
  };

  const getNextMicroRegulatoryProcesses = async () => {
    try {
      setMicroRegulatoryProcessLoading(true);
      const { data } = await store.api.getNextDataWithURL(microRegulatoryProcessNext);
      let newMicroRegulatoryProcesses = microRegulatoryProcessData;
      setMicroRegulatoryProcessNext(data.next);
      setMicroRegulatoryProcessData(undefined);
      const arrayLength = data.results.length;
      data.results.map((process, i) => {
        newMicroRegulatoryProcesses.push(process);
        if (arrayLength == i + 1) {
          setMicroRegulatoryProcessData(newMicroRegulatoryProcesses);
          setMicroRegulatoryProcessDisplayCount(microRegulatoryProcessDisplayCount + arrayLength);
        }
      });
    } catch (error) {
      setMicroRegulatoryProcessLoading(false);
      console.log(error);
      if (error.response && error?.response?.status === 403) {
        enqueueSnackbar('You do not have permission to view this data', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('An error occurred, please contact support', {
          variant: 'error',
        });
      }
    } finally {
      setMicroRegulatoryProcessLoading(false);
    }
  };

  useEffect(() => {
    getMicroRegulatoryProcessList();
  }, []);

  const navigateToMicroRegulatoryProcess = (microProcessId) => {
    const path = 'user-micro-regulatory-process-detail?micro_process='+microProcessId;
    history.push(path);
  };

  const handleDateChange = (date) => {
      setSelectedDate(date);
    };
  const handleDateChangeEnd = (date) => {
    setSelectedDateEnd(date);
  };
  const handleSearch = () => {
    // Your search logic here
    getMicroRegulatoryProcessReviewedBetween();
    console.log('Search button clicked!');
};

  return (
    <section className={'compliance-container'}>
      <div className="back-nav" onClick={() => history.push('/companies-dashboard')}>
        Home
      </div>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container justifyContent="flex-end">
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            id="date-picker-inline"
            label="Start Date"
            value={selectedDate}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
          <div>&nbsp; &nbsp;</div>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            id="date-picker-inline"
            label="End Date"
            value={selectedDateEnd}
            onChange={handleDateChangeEnd}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
          <div>&nbsp; &nbsp;</div>
           <Button variant="contained" color="primary" onClick={handleSearch}>
            Search
          </Button>
        </Grid>
      </MuiPickersUtilsProvider>
      <div className="header-icon"></div>
      <h4>
       Compliance Monitoring Programme Dashboard
      </h4>

      <ul className="categories-list">
        <li key="count" style={{ backgroundColor: '#f4f5f7' }}>
          <div>
          <div>
          <div>This screen lists all the Regulatory Micro-processes that this user is the owner of that need to be actioned in the next month or those that have failed validation by the Automated Compliance Testing Module.&nbsp;</div>
          <div>&nbsp;</div>
          <div><strong>ACTION</strong>:&nbsp; Process Owners will be responsible for either Fixing Issues and/or Reviewing and Signing off the Risk Assessment, Policy, Procedures documents, uploading the applicable Evidence Files/Reports and completing the Compliance Monitoring Programme.&nbsp;</div>
          <div><strong>RESULT</strong>: Regulatory Micro-processes will be removed from this screen when they have passed validation and appear again 1 month from the Next Review Date as part of the Compliance Monitoring Programme.</div>
          </div>
          </div>
          <p>&nbsp;</p>
          <span>
            Displaying {microRegulatoryProcessDisplayCount} of {microRegulatoryProcessCount} Micro
            Regulatory Processes
          </span>
        </li>
        {microRegulatoryProcessData !== undefined &&
          microRegulatoryProcessData.map((microProcess, i) => {
            const microProcessId = microProcess.id;

            return (
              <li key={i} onClick={() => navigateToMicroRegulatoryProcess(microProcessId)}>
                <span>{microProcessId}</span>
                <span className="title-spacing">{microProcess.micro_regulatory_process.name}</span>
                <div className="status-text">
                  <span className="last-updated">Last Updated At {microProcess.last_updated}</span>
                  <span className="owner"> Owner: {microProcess.owner.first_name}</span>
                </div>
                {microProcess.validation_status == 'Passed' ? (
                  <div className="status green-status"></div>
                ) : (
                  <div className="status red-status"></div>
                )}
              </li>
            );
          })}
        {microRegulatoryProcessCount > numRecordPage && (
          <li className="get-next" key="next" onClick={() => getNextMicroRegulatoryProcesses()}>
            <span>{microRegulatoryProcessLoading ? 'Loading...' : 'Load ' + numRecordPage.toString() + ' more'}</span>
          </li>
        )}
      </ul>
    </section>
  );
};

MicroRegulatoryProcessesList.defaultProps = {};

export default MicroRegulatoryProcessesList;