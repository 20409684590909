import React, { useState, useEffect } from 'react';
import {
    Line,
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip
} from 'recharts';


export default function ReportGraph (props) {
  const seriesData =  Object.values(props.data)[props.index].results;
  const [chartWidth, setChartWidth] = useState(Math.max(500, Math.min(1000, window.innerWidth - 950*(1500/window.innerWidth))));

  useEffect(() => {
    const handleResize = () => {
      setChartWidth(Math.max(500, Math.min(1000, window.innerWidth - 950*(1500/window.innerWidth)))); // Apply min and max limits
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  function formatTimestampToDate(timestamp) {
      const date = new Date(timestamp);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
  }
  var result = []
  var first = true;
  if (seriesData.length === 0) {
      var m = {
        name: null,
        Failed: 0.00,
        Passed: 0.00,
        amt: 1
    }
     m['name'] = formatTimestampToDate(props.selectedDate) || formatTimestampToDate(new Date());
     result.push(m)
  }
  seriesData.forEach(function(item, i) {
    var m = {
        name: formatTimestampToDate(item.created_on),
        Passed: (item.total_rules_validation_passed * 1.00 / item.applicable_micro_regulatory_processes ).toFixed(2),
        amt: 1
    }
    if (first) {
        m['name'] = formatTimestampToDate(item.created_on);
        first = false
     }
    if (seriesData.length === i+1)
         m['name'] = formatTimestampToDate(item.created_on);

    result.push(m)
  });
  const tickData = [
    result[0].name,
    result[Math.floor(result.length / 2)].name,
    result[result.length - 1].name
  ];

  const toPercentage = (decimal, fixed = 0) => {
    return `${(decimal * 100.00).toFixed(fixed)}%`;
  };
  debugger;
  return (
    <AreaChart
      width={chartWidth}
      height={120}
      data={result}
      margin={{
        top: 5,
        right: 50,
        left: 20,
        bottom: 5
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name"  ticks={tickData}/>
      <YAxis domain={[0, 1]} tickFormatter={(value) => toPercentage(value, 0)} />
      <Tooltip formatter={(value) => toPercentage(value, 2)} />
      <defs>
        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1" margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
          <stop offset="5%" stopColor="#f70000" stopOpacity={0.8}/>
          <stop offset="95%" stopColor="#f70000" stopOpacity={0}/>
        </linearGradient>
      </defs>
      <Area
        type="monotone"
        dataKey="Passed"
        stroke="#82ca9d"
        activeDot={{ r: 8 }}
        fillOpacity={1} fill="url(#colorUv)"
      >
        </Area>
        <Line type="monotone" dataKey="Passed" stroke="#8b8989" margin={{ top: 0, right: 0, left: 0, bottom: 0 }}/>

    </AreaChart>
  );
};