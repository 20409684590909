import 'assets/styles/compliance.scss';

import React, { useEffect, useState } from 'react';

import AccountTreeIcon from '@material-ui/icons/AccountTree';
import { useHistory } from 'react-router-dom';
import {useStore} from "../../hooks";
import {useSnackbar} from "notistack";
import * as config from "../../config/constants";
import * as dns from "../../config/constants";

let isCompaniesDashboardSelected = false;
export const setIsCompaniesDashboardSelected = (value) => {
  isCompaniesDashboardSelected = value;
};

export const getIsCompaniesDashboardSelected = () => {
  return isCompaniesDashboardSelected;
};

const CompaniesDashboard = () => {
  const history = useHistory();
  const [microRegulatoryProcessNext, setMicroRegulatoryProcessNext] = useState('');
  const [legalEntityData, setLegalEntityData] = useState([]);
  const [microRegulatoryProcessData, setMicroRegulatoryProcessData] = useState();
  const [microRegulatoryProcessCount, setMicroRegulatoryProcessCount] = useState(0);
  const [microRegulatoryProcessDisplayCount, setMicroRegulatoryProcessDisplayCount] = useState(0);
  const [microRegulatoryProcessLoading, setMicroRegulatoryProcessLoading] = useState(false);
  const store = useStore();
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {}, []);
  const numRecordPage = config.pageSize;


  const getLegalEntityList1 = async (domain) => {
  try {
    const { data } = await store.api.getLegalEntity(dns.domains[domain]);
    return data.results[0];
  } catch (error) {
    console.error(`Error fetching data for domain ${domain}:`, error);
    throw error;
  }
 };
 const getNextMicroRegulatoryProcesses = async () => {
    try {
      setMicroRegulatoryProcessLoading(true);
      const { data } = await store.api.getNextDataWithURL(microRegulatoryProcessNext);
      let newMicroRegulatoryProcesses = microRegulatoryProcessData;
      setMicroRegulatoryProcessNext(data.next);
      setMicroRegulatoryProcessData(undefined);
      const arrayLength = data.results.length;
      data.results.map((process, i) => {
        newMicroRegulatoryProcesses.push(process);
        if (arrayLength === i + 1) {
          setMicroRegulatoryProcessData(newMicroRegulatoryProcesses);
          setMicroRegulatoryProcessDisplayCount(microRegulatoryProcessDisplayCount + arrayLength);
        }
      });
    } catch (error) {
      setMicroRegulatoryProcessLoading(false);
      console.log(error);
      if (error.response && error?.response?.status === 403) {
        enqueueSnackbar('You do not have permission to view this data', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('An error occurred, please contact support', {
          variant: 'error',
        });
      }
    } finally {
      setMicroRegulatoryProcessLoading(false);
    }
  };

 useEffect(() => {
      const domains = Object.keys(dns.domains);
      const fetchData = async () => {
        const results = await Promise.all(domains.map(getLegalEntityList1));
        setLegalEntityData(results.flat());
  };

  fetchData();
  }, []);

 const navigateToMicroRegulatoryProcess = (url, domain) => {
      window.localStorage.setItem('baseURL', url);
      window.localStorage.setItem('domain', domain);
      setIsCompaniesDashboardSelected(true); // Example usage to set the value to true
      const path = '/businesses';
      history.push(path);
  };

  return (
    <section className={'compliance-container'}>
      <div className="back-nav" onClick={() => history.push('/dashboard')}>
        Home
      </div>
      <AccountTreeIcon
        style={{
          width: '42px',
          height: '42px',
          color: '#e0123c',
          fontSize: 'large',
          position: 'absolute',
        }}
      />
      <h4>User Company Management Dashboard</h4>

               <ul className="categories-list">
        <li key="count" style={{ backgroundColor: '#f4f5f7' }}>
        <div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        </div>
        <p>&nbsp;</p>
        </li>
          {legalEntityData !== undefined &&
          legalEntityData.map((microProcess, i) => {
            const domains = Object.keys(dns.domains);
            return (
              <li key={i} onClick={() => navigateToMicroRegulatoryProcess(dns.domains[domains[i]], domains[i])}>
                <span>{i}</span>
                <span className="title-spacing">{microProcess.name}</span>
                <div className="status-text">
                  <span className="owner"> Owner: {microProcess.description}</span>
                    <span className="last-updated">Last Updated At {microProcess.updated_on}</span>
                </div>
              </li>
            );
          })}
        {microRegulatoryProcessCount > 10 && (
          <li className="get-next" key="next" onClick={() => getNextMicroRegulatoryProcesses()}>
            <span>{microRegulatoryProcessLoading ? 'Loading...' :  'Load ' + numRecordPage.toString() + ' more'}</span>
          </li>
        )}
      </ul>
    </section>
  );
};
CompaniesDashboard.defaultProps = {};

export default CompaniesDashboard;