export const drawerClosedWidth = 56;
export const drawerWidth = 257;
export const pageSize = 100;
export const BusinessPageSize = 15;
const { REACT_APP_API_BASE_URL } = process.env;
export const domains = {
  domain1 : REACT_APP_API_BASE_URL
};
const dashboardLinks = {
  domain1: {
    'Training': '/business-process?business=1&business_process=23&business_function=2',
    'Compliance Reporting': '/business-process?business=1&business_process=19&business_function=2',
    'Regulatory Reporting': '/business-process?business=1&business_process=18&business_function=2',
    'Audit': '/business-process?business=9&business_process=309&business_function=26',
    'Communication with the Regulator': 'business-process?business=1&business_process=24&business_function=2'
  },
  domain2: {
    '10 Training': 'business-process?business=2&business_process=96&business_function=22',
    '11 Compliance Reporting': '/business-process?business=2&business_process=57&business_function=16',
    '12 Regulatory Reporting': '/business-process?business=2&business_process=61&business_function=16',
    '13 Audit': '/business-process?business=8&business_process=78&business_function=24',
    '14 Communication with the Regulator': '/business-process?business=2&business_process=52&business_function=16'
  },
  domain3: {
    '10 Training': '/business-process?business=2&business_process=96&business_function=22',
    '11 Compliance Reporting': '/business-process?business=2&business_process=57&business_function=16',
    '12 Regulatory Reporting': '/business-process?business=2&business_process=61&business_function=16',
    '13 Audit': '/business-process?business=2&business_process=10&business_function=4',
    '14 Communication with the Regulator': 'business-process?business=2&business_process=52&business_function=16'
  }
};

export { dashboardLinks };
export const multiDomain = true;
