import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import SearchIcon from '@material-ui/icons/Search';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableSortLabel from '@material-ui/core/TableSortLabel';



export default function DialogLogs(props) {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  const [sortColumn, setSortColumn] = useState('');
  const [sortOrder, setSortOrder] = useState('desc'); // 'asc' for ascending

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };
   const dialogStyles = {
    style: { maxWidth: 'none', width: '70%', height: '70vh', maxHeight: 'none' },
  };
   const handleSort = (column) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortOrder('desc');
    }
  };
     const formatDate = (dateString) => {
      const date = new Date(dateString);
      const formattedDate = date.toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      });

      // Convert date from MM/DD/YYYY to YYYY-MM-DD and remove comma
      return formattedDate.replace(/(\d+)\/(\d+)\/(\d+),\s/, '$3-$1-$2 ').replace(/,/, '');
    };


  const sortedResults = React.useMemo(() => {
    return [...props.data.results].sort((a, b) => {
      let comparison = 0;
      if (new Date(a[sortColumn]) > new Date(b[sortColumn])) {
        comparison = 1;
      } else if (new Date(a[sortColumn]) < new Date(b[sortColumn])) {
        comparison = -1;
      }
      return sortOrder === 'desc' ? comparison * -1 : comparison;
    });
  }, [props.data.results, sortColumn, sortOrder]);

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <span>
      <Button onClick={handleClickOpen('paper')}><SearchIcon/></Button>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
         maxWidth="xl" fullWidth
        fullWidth
        PaperProps={dialogStyles}
      >
        <DialogTitle id="scroll-dialog-title">Compliance Log Details</DialogTitle>
       <DialogContent dividers>
        <TableContainer component={Paper} style={{overflow: 'auto' }}>
          <Table stickyHeader aria-label="compliance log table">
            <TableHead>
              <TableRow>
                <TableCell style={{ whiteSpace: 'nowrap' }}>
                  <TableSortLabel
                    active={sortColumn === 'created_on'}
                    direction={sortColumn === 'created_on' ? sortOrder : 'asc'}
                    onClick={() => handleSort('created_on')}
                  >
                    Created On
                  </TableSortLabel>
                </TableCell>
               <TableCell style={{ whiteSpace: 'nowrap' }}>
                <TableSortLabel
                  active={sortColumn === 'updated_on'}
                  direction={sortColumn === 'updated_on' ? sortOrder : 'asc'}
                  onClick={() => handleSort('updated_on')}
                >
                  Updated On
                </TableSortLabel>
                </TableCell>
                <TableCell>Document Type</TableCell>
                  <TableCell>Micro Process Name</TableCell>
                <TableCell>Validation Logic</TableCell>
                <TableCell>Event Info</TableCell>
                <TableCell>Validation Info</TableCell>
                <TableCell>Validation Status</TableCell>
                <TableCell>Applicable Micro Regulatory Processes</TableCell>
                <TableCell>Total Rules Validation Passed</TableCell>
                <TableCell>Total Rules Validation Failed</TableCell>
                <TableCell>Percentage Passed</TableCell>
                <TableCell>Last Validated</TableCell>
                <TableCell>Micro Process Name</TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {sortedResults.map((item, index) => (
                <TableRow key={index}>
                  <TableCell style={{ whiteSpace: 'nowrap' }}>{formatDate(item.created_on)}</TableCell>
                  <TableCell style={{ whiteSpace: 'nowrap' }}>{formatDate(item.updated_on)}</TableCell>
                  <TableCell>{item.compliance_document_type}</TableCell>
                   <TableCell>{item.company_micro_regulatory_process_name}</TableCell>
                  <TableCell>{item.document_validation_logic}</TableCell>
                  <TableCell>{item.event_info}</TableCell>
                  <TableCell>{item.compliance_document_validation_info}</TableCell>
                  <TableCell>
                    <span
                      style={{
                        border: `1px solid ${item.compliance_document_validation_status === 'Passed' ? '#16b67b' : '#e0123c'}`,
                        color: item.compliance_document_validation_status === 'Passed' ? '#16b67b' : '#e0123c',
                        padding: '2px 4px',
                        borderRadius: '4px',
                      }}
                    >
                      {item.compliance_document_validation_status}
                    </span>
                  </TableCell>
                  <TableCell>{item.applicable_micro_regulatory_processes}</TableCell>
                  <TableCell>{item.total_rules_validation_passed}</TableCell>
                  <TableCell>{item.total_rules_validation_failed}</TableCell>
                  <TableCell>{`${item.percentage_passed}%`}</TableCell>
                  <TableCell>{formatDate(item.last_validated)}</TableCell>


                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
        {/*<DialogContent dividers={scroll === 'paper'}>*/}
      {/*  <DialogContent dividers={true} style={{ maxHeight: '400px', overflow: 'auto' }}>*/}
      {/*  {props.data.results.map((item, index) => (*/}
      {/*    <Box key={index} component="div" my={2} p={2} bgcolor="#f0f0f0" borderRadius="borderRadius">*/}
      {/*      <Typography gutterBottom><strong>Compliance Document Type:</strong> {item.compliance_document_type}</Typography>*/}
      {/*      <Typography gutterBottom><strong>Validation Logic:</strong> {item.document_validation_logic}</Typography>*/}
      {/*      <Typography gutterBottom><strong>Event Info:</strong> {item.event_info}</Typography>*/}
      {/*      <Typography gutterBottom><strong>Validation Info:</strong> {item.compliance_document_validation_info}</Typography>*/}
      {/*      <Typography gutterBottom><strong>Validation Status:</strong> {item.compliance_document_validation_status}</Typography>*/}
      {/*      <Typography gutterBottom><strong>Last Validated:</strong> {item.last_validated}</Typography>*/}
      {/*      <Typography gutterBottom><strong>Percentage Passed:</strong> {item.percentage_passed}%</Typography>*/}
      {/*    </Box>*/}
      {/*  ))}*/}
      {/*</DialogContent>*/}
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </span>
  );
}