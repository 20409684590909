import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'; //

export default function AlertDialog(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <span>
      <Button  className="alert-dialog-button" onClick={(event) => {
     handleClickOpen();
     event.stopPropagation();
      }}>
          <HelpOutlineIcon />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"> {"Why this  micro processs is" +
        " applicable?" }</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.reason}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={(event) => {handleClose();event.stopPropagation();}} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </span>
  );
}