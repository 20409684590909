import React, { useState } from 'react';





function PdfViewerDialog(props) {


  return (
     <div className="rules-popup">
         <div className="rules-popup-content">
          <div className="close-popup" onClick={() => props.closePopup()}></div>
              <object data={props.pdfUrl} type='application/pdf' width='100%' height='100%'>
                <p>PDF cannot be displayed</p>
              </object>
             <button onClick={() => props.closeDialog()}>Back</button>
         </div>

     </div>
  );
}

export default PdfViewerDialog;
