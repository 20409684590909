import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

const rows = [];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

export default function DocumentPreview(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [columnsTableJson, setColumnsTableJson] = useState();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const table_json = props.table_json;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  function getColumns() {
    if(table_json !== undefined) {
      let col = table_json['file']['columns'];
      setColumnsTableJson(col);
    }
  };




  return (
    <div className="rules-popup">
    <div className="rules-popup-content">
      <div className="close-popup" onClick={() => props.closePopup()}></div>
    <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {table_json !== undefined && table_json['file']['columns'] !== undefined &&
                table_json['file']['columns'].map((column, i) => {
                  return (
                      <TableCell>
                        {column}
                      </TableCell>
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {table_json['file']['data'].slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row,id) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {table_json['file']['columns'].map((column, i) => {
                      const value = table_json['file']['data'][id][i];
                      return (
                        <TableCell>
                          {column.format && typeof value === 'number' ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        />
    </Paper>
      <button onClick={() => props.handleCsvClick()}>Back</button>
    </div>
  </div>
  );
}
