import 'assets/styles/compliance.scss';

import React, { useEffect, useState } from 'react';

import SwapHorizontalCircleOutlinedIcon from '@material-ui/icons/SwapHorizontalCircleOutlined';
import { useHistory } from 'react-router-dom';

const HorizonScanning = (props) => {
  const history = useHistory();

  useEffect(() => {}, []);

  return (
    <section className={'compliance-container'}>
      <div className="back-nav" onClick={() => history.push('/companies-dashboard')}>
        Home
      </div>
      <SwapHorizontalCircleOutlinedIcon
        style={{
          width: '42px',
          height: '42px',
          color: '#e0123c',
          fontSize: 'large',
          position: 'absolute',
        }}
      />
      <h4>Horizon Scanning</h4>

      <ul className="categories-list">
        <li key="count" style={{ backgroundColor: '#f4f5f7' }}>
          <div>This screen lists all Regulatory Documents that have been flagged as applicable to this firm type (e.g. UK MIFID Firm).&nbsp;</div>
<div><strong>ACTION</strong>: It is the responsibility of the system owner to mark each Regulatory Document as APPLICABLE to their organisation, or NOT APPLICABLE, adding appropriate reasons for Audit Trail purposes.&nbsp;</div>
<div><strong>RESULT</strong>: Applicable Regulatory Documents will be converted into Regulatory Micro-processes and sent to the Impact Assessment Screen for Assignment to process owners, validation as applicable.</div>
        </li>
      </ul>
    </section>
  );
};

HorizonScanning.defaultProps = {};

export default HorizonScanning;
