import './status-card.scss';

import React, { useEffect, useState } from 'react';

import Moment from 'moment';

const StatusCard = (props) => {
  useEffect(() => {
  }, []);

  return (
    <div className="status-card">
      <div className="last-validated">
        Last validated:{' '}
        {props.sectionData.last_validated
          ? Moment(props.sectionData.last_validated).format('DD MM YYYY, h:mm a')
          : props.sectionData.validation_status}
      </div>

      <div className="status-indicator">
        <div
          className="status green-status"
          style={{ width: `${props.sectionData.percentage_passed}%` }}
        ></div>
        <div
          className="status yellow-status"
          style={{ width: `${props.sectionData.percentage_underway}%` }}
        ></div>
        <div
          className="status red-status"
          style={{ width: `${props.sectionData.percentage_failed}%` }}
        ></div>
      </div>

      {!props.hideMetaData && (
        <span className="status-text">
          NEXT DATE: {props.rules[0]?.next_reviewed
          ? Moment(props.rules[0]?.next_reviewed).format('DD/MM/YYYY, hh:mm:ss')
          :  ''}
          <br />
          STATUS: {props.rules !== undefined && props.rules[0]?.review_status}
          <br />
          FREQUENCY : {props.rules[0]?.review_frequency
              ? props.rules[0]?.review_frequency
          :  null}
        </span>
      )}
    </div>
  );
};

StatusCard.defaultProps = {};

export default StatusCard;
